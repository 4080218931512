<template>
    <div>
        <div class="head-part">
            <div style="display: flex; justify-content: center;">
                <div class="head-top">
                    <div class="title font_bold" style="align-items: flex-start;"> {{i18n('rengouliebiao')}} </div>
                    <img style="margin-top: 40px; margin-right: 32px;" :src="require('@/assets/images/shengou.png')" alt="">
                </div>
            </div>
        </div>
        <div style="width: 75%;margin:0 auto;padding:20px 0">
            <el-tabs 
        v-model="activeName" >
        <el-tab-pane :label="i18n('rengouliebiao')" name="first">
            <div style="margin-top: 20px;">	
                <div style="overflow: hidden;">
                    
                    <div class="right_sw" style="">
                        <div class="right_sw_li" @click="selectType('')" :class="!selType?'right_hover':''" style="">{{i18n('quanbu')}}</div>
                        <div class="right_sw_li" @click="selectType(1)" :class="selType==1?'right_hover':''" >{{i18n('jinxingzhong')}}</div>
                        <div class="right_sw_li" @click="selectType(3)" :class="selType==3?'right_hover':''"  >{{i18n('yjs')}}</div>
                    </div>
                </div>
                <div class="allOuter"> 
                    <div class="iconOuter" v-for="item in subList" @click="item.status==1?goDetails(item):''">
                       
                        <div class="iconOuter_bottom">
                            <div class="iconOuter_bottom_div" style="margin-bottom:20px">
                                <div style="display:flex">
                                    <img :src="apiAd+'/codelogo/'+contractCodeUsdt(item.coinCodeNo)+'-USDT.png'" style="width:30px" alt="">
                                    <div style="line-height:40px">{{item.fucProductName}}</div>
                                </div>
                                <div class="top_right_but stype1" v-if="item.status==1">{{i18n('jinxingzhong')}}</div>
                                <div class="top_right_but" v-if="item.status==2">{{i18n('jijiangdaolai')}}</div>
                                <div class="top_right_but" v-if="item.status==0">{{i18n('ywc')}}</div>
                              
                            </div>
                            <div class="iconOuter_bottom_div">
                                <div>{{i18n('sgjg')}}</div>
                                <div style="color: #2794f8">{{item.Price}} USDT </div>
                            </div>
                            <div class="iconOuter_bottom_div">
                                <div>{{i18n('zongshuliang')}}</div>
                                <div>{{parseFloat(item.fucProductQuota)?item.fucProductQuota:i18n('meiyouxianzhi')}} </div>
                            </div>
                            <div class="iconOuter_bottom_div">
                                <div>{{i18n('shengyushuliang')}}</div>
                                <div>{{item.fucProductSurplus}} </div>
                            </div>
                            <div class="iconOuter_bottom_div">
                                <div>{{i18n('kaishishijian')}}</div>
                                <div style="color:#888888">{{item.startTime | formatDate}}</div>
                            </div>
                            <div class="iconOuter_bottom_div">
                                <div>{{i18n('jssj')}}</div>
                                <div style="color:#888888">{{item.endTime | formatDate}}</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <!-- <a href="images/buy_agrees-new1.pdf"><div class="jihuaBut">SGCB商業計畫書</div></a> -->
                
            </div>
        </el-tab-pane>

        <el-tab-pane :label="i18n('jilu')" name="second">
            <div class="tablepart" style="width:100%">
                <div class="tabhead">{{i18n('jilu')}}</div>
                <div class="nolist" v-if="recordList.length<=0">
                    <img class="nolistimg" src="@/assets/images/wudingdan.png" alt="">
                    <div class="fontSize14 color_666 text_center" style="margin-bottom: 150px;text-align:center">{{i18n('zanwujilu')}}</div>
                </div>
                <div class="hilists" v-if="recordList.length>0" >
                    <table class="retab ">
                        <tr>
                            <th >{{i18n('sgjg')}}</th>
                            <th>{{i18n('shuliang')}}</th>
                            <th>{{i18n('goumaijine')}}</th>
                            <th>{{i18n('zqsl')}}</th>
                            <th>{{i18n('zqje')}}</th>
                            <th>{{i18n('shijian')}}</th>
                        </tr>
                        <tr v-for="item in recordList">
                            <td>{{item.tradePrice}}</td>
                            <td>{{item.tradeNum}}({{item.coinCodeNo}})</td>
                            <td>{{item.tradePrice}}(USDT)</td>
                            <td>{{item.realNum}}({{item.coinCodeNo}})</td>
                            <td>{{item.realAmount}}(USDT)</td>
                            <td>{{item.createTime | formatDate}}</td>
                        </tr>
                    </table>
                    
                    <div class="text_right" id="orderpaging"></div>
                </div>
            </div>
        </el-tab-pane>
    </el-tabs>
        </div>
        
        
        
    </div>
</template>
<script>
  
  import config from '@/utils/configs'
export default {
    data() {
        return {
           selType:'',
           subList:[],
           recordList:[],
           activeName: 'first',
           apiAd: config.apiAddress,
        };
    },
    filters:{
        formatDate(now) {
            var needdate = new Date(now * 1000);
            var Y = needdate.getFullYear() + '-';
            var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '-';
            var D = needdate.getDate()<10? '0'+needdate.getDate():needdate.getDate()+ ' ';
            //      年 月 日 时 分 秒
            return Y + M + D;
        }
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getProductList();
                this.getRecordList()
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getProductList();
            this.getRecordList()
        };
      
    },
    beforeDestroy() {
        
    },
    
    methods: {
        contractCodeUsdt (contract) {
            if(contract.indexOf('-')){
                var attr=contract.split("-");
                return  attr[0];
            }else{
                return contract
            }
           
        },
        selectType(val){
            this.selType = val;
            this.getProductList()
            // this.getProductOrderList()
        },
        goDetails(item){
            this.$router.push({
                name:'subDetails',
                query:{cid:item.id}
            });
        },
        goBack(){
            this.$router.go(-1);
        },
        getProductList(){
            let data = {
				status:this.selType
            }
            this.$api.fucproductList(data).then((res)=>{
                this.subList = res.data.data.list
            })
        },
        getRecordList(){
            let data = {
					page:1,
					page_size:100
            }
            this.$api.getUserFucCoinAccountRecord(data).then((res)=>{
                this.recordList = res.data.data
            })
        },
        goBuy(){
            let data = {
                product_id: this.proDetails.id,
                product_number: this.type==3?this.buyNum*parseFloat(this.proDetails.productUserMin):this.buyNum,
                is_auto_transfer_agility: 1
            }
            this.$api.doProduct(data).then((res)=>{
                if(res.data.code==200){
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.getAllAccountInfo();
                    this.getLcAccountInfo();
                    this.step1Show = false;
                }else{
                    this.$message.error(res.data.msg);
                }
            })
            
        }
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("subscription")[name];
            }
		},
    },
}
</script>

<style scoped>
@import '@/css/recharge.css';
	body{
		color: white;
		padding-bottom: 100px;
	}
	.right_sw{
		display: flex;
	}
	.right_sw div{
		width: 100px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}
	.left_hea{
		font-size: 30px;
		font-weight: bold;
		line-height: 40px;
		float: left;
	}
	.right_hover{
		background-color: #F3F3F3;
	}
	.allOuter{
		width: 100%;
		margin-top: 30px;
		overflow: hidden;
	}
	.iconOuter{
		width: 32%;
		float: left;
		margin-bottom: 10px;
		margin-right: 1.3%;
		margin-top: 20px;
		position: relative;
        font-size: 14px;
        background: #F3F3F3;
        border-radius: 5px;
	}
	.bottom_box1{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 30px;
		height: 30px;
		background-color: #08BD98;
	}
	.bottom_box2{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 30px;
		height: 30px;
		background-color: #E12343;
	}
	.bottom_box3{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 30px;
		height: 30px;
		background-color: #BBBBBB;
	}
	.iconOuter_bottom{
		padding: 20px 20px 20px 20px;
        
	}
	.iconOuter_bottom .iconOuter_bottom_div{
		line-height: 30px;
		color: #333333;
        display: flex;
        justify-content: space-between;
	}
	a{
		color: white;
	}
	.iconOuter_header{
		display: flex;
		position:relative
	}
	.iconOuter_header img{
		width:100%;
		height:150px
	}
	.iconOuter_header_box{
		position: absolute;
		top: 0;
		left: 0;
		line-height: 150px;
		text-align: center;
		width:100%;
		font-weight: 600;
        color: white;
	}
	.jihuaBut{
		margin: 30px 10px;
		padding: 10px 10px;
		width: 150px;
		background-color: #1763B6;
		text-align: center;
	}
	.tablepart{
	    clear: both;
	}
	.retab th,.retab td{
	}
    .head-part {
        height: 240px;
        width: 100%;
        min-width: 1200px;
        background-color: #f3f3f3;
    }
    
    .head-top {
        width: 1200px;
        height: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center; 
    }
    
    .title {
        font-size: 36px;
        color: rgb(0, 0, 0);
        line-height: 100px;
        font-weight: 500;
    }
    
    .flex_row_between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .flex_col_center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .flex_row_left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .top_right_but{
        padding:5px 20px;
        background: rgb(199, 199, 199);
        border-radius: 5px;
    }
    .stype1{
        background: #0E68FB;
        color: white;
        cursor: pointer;
    }
    </style>
