<template>
    <div class="verification">
        <div>
            <div class="flex_row_left" style="height: 50px; background-color: #eee;">
                <el-page-header 
                    @back="goBack" 
                    :content="i18n('shimingrenzheng')"
                    :title="i18n('fanhui')"
                    style="margin-left: 10px;">
                </el-page-header>   
            </div>
        </div>

        <div class="title"> {{ i18n('kaiqishenfenrenzheng') }} </div>
        <div class="flex_col_center turndown" v-show="trueNameAuthentication === '3'">
            <div class="fontSize20"> {{ i18n('bohuiyuanyin') }} </div>
            <div class="reson">
                {{ reson }}
                <!-- 1.身份证信息与身份证照片不符 -->
            </div>
            <!-- <div>2.人像照片模糊</div> -->
        </div>
        <div class="uppart">
            <div class="subtitle"> {{ i18n('qingtianxiejiashizhizhaogongminkahuzhao') }} </div>
            <div class="flex_row_left">
                <div class="lable"> {{ i18n('xingming') }} </div>
                <!-- <input class="inputarea name" type="text" placeholder="请输入姓名"> -->
                <el-input 
                    v-model="username" 
                    style="width: 300px;"
                    :placeholder="i18n('qingshuruxingming')"></el-input>
                <div class="lable"> {{ i18n('zhengjianhaoma') }} </div>
                <el-input 
                    v-model="idcard" 
                    style="width: 300px;"
                    :placeholder="i18n('qingshuruzhengjianhaoma')"></el-input>
                <!-- <input class="inputarea idcard" type="text" placeholder="请输入证件号码"> -->
            </div>
            <div class="uptip"> {{ i18n('qingshangchuanjiashizhizhaogongminkahuzhao') }} </div>
            <div class="flex_row_left">
                <div :class="{'upimg': true, 'flex_col_center': true, 'greenborder': greenborder0, 'solid': solid0}" 
                @dragenter="fileDragenter0" 
                @dragover="fileDragover0" 
                @drop="fileDrop0">
                    <img class="shangchuan" src="@/assets/images/sm1.png" alt="">
                    <div class="upstip"> <span> {{ i18n('shangchuanzhengjianzhengmian') }} </span> {{ i18n('huotuozhuafangru') }} </div>
                    <label class="abinput">
                        <input class="zm" accept="image/*" name="inputFile" type="file" style="visibility: hidden;" @change="uploadimg0">
                    </label>
                    <div class="upedimg" v-show="showzmimg">
                        <img class="serveimg img1" :src="imgzm" alt="">
                        <div class="blackover flex_row_center color_white">
                            <i @click="showimg(0)" class="iconfont fontSize20 el-icon-zoom-in" style="cursor: pointer;"></i>
                            <i @click="deleteimg(0)" class="iconfont fontSize20 el-icon-delete" style="margin-left: 30px; cursor: pointer;"></i>
                        </div>
                    </div>
                </div>
                <div :class="{'upimg': true, 'flex_col_center': true, 'greenborder': greenborder1, 'solid': solid1}"  
                @dragenter="fileDragenter1" 
                @dragover="fileDragover1" 
                @drop="fileDrop1">
                    <img class="shangchuan" src="@/assets/images/sm2.png" alt="">
                    <div class="upstip"><span> {{ i18n('shangchuanzhengjianfanmian') }} </span> {{ i18n('huotuozhuafangru') }} </div>
                    <label class="abinput">
                        <input class="fm" accept="image/*" name="inputFile" type="file" style="visibility: hidden;" @change="uploadimg1">
                    </label>
                    <div class="upedimg" v-show="showfmimg">
                        <img class="serveimg img2" :src="imgfm" alt="">
                        <div class="blackover flex_row_center color_white">
                            <i @click="showimg(1)" class="iconfont fontSize20 el-icon-zoom-in" style="cursor: pointer;"></i>
                            <i @click="deleteimg(1)" class="iconfont fontSize20 el-icon-delete" style="margin-left: 30px; cursor: pointer;"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn" @click="submitClick"> {{ i18n('tijiao') }} </div>
        </div>

        <div class="flex_row_center showbigimg" v-show="showbigimg" @click="showbigimg = false">
            <img class="bigimgsrc" :src="imgbig" alt="">
        </div>
    </div>
</template>

<script>
import config from '@/utils/configs'

export default {
    data() {
        return {
            username: undefined,
            idcard: undefined,
            trueNameAuthentication: undefined,
            reson: undefined,
            showbigimg: false,
            showzmimg: false,
            showfmimg: false,
            greenborder0: false,
            greenborder1: false,
            solid0: false,
            solid1: false,
            imgzm: require('@/assets/images/sm1.png'),
            imgfm: require('@/assets/images/sm2.png'),
            imgbig: require('@/assets/images/banner_defult.png'),
            imgfront: undefined,
            imgback: undefined, 
        };
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getAuthenticationInfo();
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getAuthenticationInfo();
        };
        
    },
    computed: {
        i18n() {
            return function(name) {
                return this.$t("verification")[name];
            }
		},
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getAuthenticationInfo() {
            let data = {}
            this.$api.getAuthenticationInfo(data).then((res)=>{
                if(res.data.code == 200){
                    this.trueNameAuthentication = res.data.data.trueNameAuthentication;
                    this.reson = res.data.data.notes;
                } else {
                    this.$message({
                        message: this.$t('verification')['huoquyonghuxinxishibai'],
                        type: 'warning'
                    });
                }
            });
        },
        showimg(i) {
            if (i === 0) {
                this.imgbig = this.imgzm;
            }
            
            if (i === 1) {
                this.imgbig = this.imgfm;
            }
            this.showbigimg = true;
        },
        deleteimg(i) {
            if (i === 0) {
                this.imgzm = require('@/assets/images/sm1.png');
                this.solid0 = false;
                this.greenborder0 = false;
                this.showzmimg = false;
                this.imgfront = undefined;

            }
            if (i === 1) {
                this.imgfm = require('@/assets/images/sm2.png');
                this.solid1 = false;
                this.greenborder1 = false;
                this.showfmimg = false;
                this.imgback = undefined;
            }
        },
        // 拖拽上传
        fileDragenter0(event) {
            event.preventDefault();
            this.greenborder0 = true;
            // $(".upimg").eq(i).addClass("greenborder");
        },

        fileDragover0(event) {
            event.preventDefault();
        },

        fileDrop0(event) {

            event.preventDefault();
            var _file = event.dataTransfer.files[0] // 获取到第一个上传的文件对象
           
            if (!_file) return
            if (_file.type.split("/")[0] != "image") {
                // layer.msg("文件格式错误！");
                this.$message({
                    message: this.$t('verification')['wenjiangeshicuowu'],
                    type: 'warning'
                });
                event.target.value = '';
                return;
            }
            if (_file.size > 5 * 1024 * 1024) {
                // layer.msg('文件不能大于5M');
                this.$message({
                    message: this.$t('verification')['wenjianbunengdayu5M'],
                    type: 'warning'
                });
                event.target.value = '';
                return;
            }
            // 获取图片路径
            var formData = new FormData();
            formData.append('file', _file);
            // var selectimgurl = URL.createObjectURL(_file);
            let data = formData;
            this.$api.loadPic(data).then((res)=>{
                if(res.data.file.code == 200){
                    this.imgzm = config.baseApiAddress + res.data.file.imgPath;
                    this.showzmimg = true;
                    this.greenborder0 = false;
                    this.solid0 = true;
                    this.imgfront = res.data.file.imgPath;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            });

            event.target.value = ''; //虽然file的value不能设为有字符的值，但是可以设置为空值
        },
        // 拖拽上传
        fileDragenter1(event) {
            event.preventDefault();
            this.greenborder1 = true;
            // $(".upimg").eq(i).addClass("greenborder");
        },

        fileDragover1(event) {
            event.preventDefault();
        },

        fileDrop1(event) {
            
            event.preventDefault();
            var _file = event.dataTransfer.files[0] // 获取到第一个上传的文件对象
            if (!_file) return
            if (_file.type.split("/")[0] != "image") {
                // layer.msg("文件格式错误！");
                this.$message({
                    message: this.$t('verification')['wenjiangeshicuowu'],
                    type: 'warning'
                });
                event.target.value = '';
                return;
            }
            if (_file.size > 5 * 1024 * 1024) {
                // layer.msg('文件不能大于5M');
                this.$message({
                    message: this.$t('verification')['wenjianbunengdayu5M'],
                    type: 'warning'
                });
                event.target.value = '';
                return;
            }
            // 获取图片路径
            var formData = new FormData();
            formData.append('file', _file);
            // var selectimgurl = URL.createObjectURL(_file);
            let data = formData;
            this.$api.loadPic(data).then((res)=>{
                if(res.data.file.code == 200){
                    this.imgfm = config.baseApiAddress + res.data.file.imgPath;
                    this.showfmimg = true;
                    this.greenborder1 = false;
                    this.solid1 = true;
                    this.imgback = res.data.file.imgPath;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            });
       
            event.target.value = ''; //虽然file的value不能设为有字符的值，但是可以设置为空值
        },
        uploadimg0(event) {
            var formFiles = {};
            var selectimgurl = '';
            var fileSize = 0;
            let _file = event.target.files[0];
            let _name = event.target.name;
            fileSize = _file.size;
            if (!_file) return
            if (_file.type.split("/")[0] != "image") {
                // layer.msg("文件格式错误！");
                this.$message({
                    message: this.$t('verification')['wenjiangeshicuowu'],
                    type: 'warning'
                });
                event.target.value = '';
                return;
            }
            if (fileSize > 5 * 1024 * 1024) {
                // layer.msg('文件不能大于5M');
                this.$message({
                    message: this.$t('verification')['wenjianbunengdayu5M'],
                    type: 'warning'
                });
                event.target.value = '';
                return
            }
            formFiles[_name] = _file;
            // 获取图片路径
            let formData = new FormData();
            formData.append('file', formFiles[_name]);

            let data = formData;
            this.$api.loadPic(data).then((res)=>{
                if(res.data.file.code == 200){
                    this.imgzm = config.baseApiAddress + res.data.file.imgPath;
                    this.showzmimg = true;
                    this.greenborder0 = false;
                    this.solid0 = true;
                    this.imgfront = res.data.file.imgPath;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            });

            event.target.value = ''; //虽然file的value不能设为有字符的值，但是可以设置为空值
        },
        uploadimg1(event) {
            var formFiles = {};
            var selectimgurl = '';
            var fileSize = 0;
            let _file = event.target.files[0];
            let _name = event.target.name;
            fileSize = _file.size;
            if (!_file) return
            if (_file.type.split("/")[0] != "image") {
                // layer.msg("文件格式错误！");
                this.$message({
                    message: this.$t('verification')['wenjiangeshicuowu'],
                    type: 'warning'
                });
                event.target.value = '';
                return;
            }
            if (fileSize > 5 * 1024 * 1024) {
                // layer.msg('文件不能大于5M');
                this.$message({
                    message: this.$t('verification')['wenjianbunengdayu5M'],
                    type: 'warning'
                });
                event.target.value = '';
                return
            }
            formFiles[_name] = _file;
            // 获取图片路径
            let formData = new FormData();
            formData.append('file', formFiles[_name]);
            let data = formData;
            this.$api.loadPic(data).then((res)=>{
                if(res.data.file.code == 200){
                    this.imgfm = config.baseApiAddress + res.data.file.imgPath;
                    this.showfmimg = true;
                    this.greenborder1 = false;
                    this.solid1 = true;
                    this.imgback = res.data.file.imgPath;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    });
                }
            });

            event.target.value = ''; //虽然file的value不能设为有字符的值，但是可以设置为空值
        },
        submitClick() {
            if (this.username !== undefined && this.username !== '') {
                if (this.idcard !== undefined && this.idcard !== '') {
                    if (this.imgfront !== undefined && this.imgfront !== '') {
                        if (this.imgback !== undefined && this.imgback !== '') {
                            let data = {
                                realname: this.username,
                                idcardno: this.idcard,
                                imgfront: this.imgfront,
                                imgback: this.imgback,
                            };
                            this.$api.realNameCertification(data).then((res)=>{
                                if(res.data.code == 200){
                                    this.$message({
                                        message: this.$t('verification')['tijiaochenggong'],
                                        type: 'success'
                                    });

                                    setTimeout(() => {
                                        this.goBack();
                                    }, 3000);
                                } else {
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    });
                                }
                            });
                        } else {
                            this.$message({
                                message: this.$t('verification')['qingshangchuanzhengjianfanmian'],
                                type: 'warning'
                            });
                        }
                    } else {
                        this.$message({
                            message: this.$t('verification')['qingshangchuanzhengjianzhengmian'],
                            type: 'warning'
                        });
                    }
                } else {
                    this.$message({
                        message: this.$t('verification')['qingtianxiezhengjianhaoma'],
                        type: 'warning'
                    });
                }
            } else {
                this.$message({
                    message: this.$t('verification')['qingtianxiexingming'],
                    type: 'warning'
                });
            }
        },
    },
}
</script>

<style scoped>

.verification {
    background: #F9F9FA;
    height: auto;
    min-width: 1200px;
    margin: 0 auto;
}

.title {
    font-size: 26px;
    font-weight: bold;
    color: #333333;
    line-height: 105px;
}

.turndown {
    /* width: 1200px; */
    height: 144px;
    border: 1px solid #dca19d;
    line-height: 36px;
    color: #333333;
    text-indent: 24px;
    align-items: flex-start;
    margin-bottom: 40px;
}

.uppart {
    padding: 0 30px 50px 30px;
    width: 1200px;
    height: 592px;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    box-sizing: border-box;
}

.subtitle {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 80px;
}

.lable {
    margin-right: 16px;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
}

.lable:nth-child(3) {
    margin-left: 100px;
}

.uptip {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 77px;
    margin-top: 30px;
    margin-right: 30px;
}

.upimg {
    width: 348px;
    height: 196px;
    border: 2px dashed #E5E5E5;
    border-radius: 2px;
    position: relative;
}

.btn {
    width: 280px;
    height: 48px;
    background: #08BD98;
    border-radius: 2px;
    margin: 65px 0 0;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
}

.abinput {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.shangchuan {
    margin: 0!important;
    padding: 0;
    width: 48px;
    height: 48px;
}

.upstip {
    font-size: 16px;
    font-weight: 500;
    color: #999999;
    line-height: 46px;
}

.upstip span {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    color: #2E7D6D;
}

.upedimg {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    display: flex;
}

.blackover {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
}

.serveimg {
    width: 100%;
    height: 100%;
}

.greenborder {
    border: 2px dashed #c7e6e1;
}

.solid {
    border: 2px solid #E5E5E5;
}

.iconshanchu {
    margin-left: 70px;
}

.showbigimg {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    background: rgba(0, 0, 0, 0.5);
}

.bigimgsrc {
    width: 60vw;
    height: auto;
    max-height: 100vh;
}

.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex_row_around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_row_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

</style>