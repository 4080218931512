<template>
    <div>
        <!-- 列表部分 -->
        <div class="treasure">
            <div class="head-part">
                <div style="display: flex; justify-content: center;">
                    <div class="head-top">
                        <div class="title font_bold" style="align-items: flex-start;"> {{i18n('yuanyuzhoukuangchi')}} </div>
                        <img style="margin-top: 40px; margin-right: 32px;" :src="require('@/assets/images/Mine.png')" alt="">
                    </div>
                </div>
            </div> 

            <div style="display: flex; justify-content: center;">
                <div style="width: 75%;margin:0 auto">
                    <div class="outer_tabs" v-show="!showditails">
                        <el-tabs 
                            v-model="activeName" >
                            <el-tab-pane :label="i18n('yuanyuzhoukuangchi')" name="first">
                                <div class="mining_box">
                                    <div class="card_box" v-for="(item, index) in productList" :key="index">
                                        <img :src="require('@/assets/images/Maskgroup.png')" alt="" class="mining_img"> 
                                        <div class="card_content">
                                            <div class="fz-28 fw-600"> {{ item.productName }} </div> 
                                            <div class="fz-14"> USDT </div> 
                                            <div class="fz-16 fw-600 gre"> {{ item.profitAmount }} {{ item.profitCoin }} {{ i18n('meiyitian') }} </div>
                                        </div> 
                                        <div class="but_box">
                                            <span @click="Opendetails(item.id)"> {{ i18n('dakai') }} </span>
                                        </div>
                                    </div>
                                </div> 
                            </el-tab-pane>

                            <el-tab-pane :label="i18n('jilu')" name="second">
                                <div class="orderlist" style="width: 100%; border: 1px solid #ddd;">
                                    <el-table
                                        :data="recordListData"
                                        style="width: 100%;"
                                        :empty-text="i18n('zanwushuju')"
                                        :header-cell-style="{background:'#F9F9F8', padding: '0', height:'45px'}"
                                        :row-style="{background:'#F9F9F8'}">
                                        <el-table-column
                                            :label="i18n('jichubizhong')"
                                            min-width="100">
                                            <template slot-scope="scope">
                                                <span style="color: #000;"> 
                                                    {{scope.row.BaseCoin}} </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="i18n('shouyibizhong')"
                                            min-width="100">
                                            <template slot-scope="scope">
                                                <span style="color: #000;"> 
                                                    {{scope.row.ProfitCoin }} </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="i18n('wakuangtianshu')"
                                            min-width="100">
                                            <template slot-scope="scope">
                                                <span> {{ scope.row.productPeriod }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="i18n('shouyitianshu') + ' / ' + i18n('suodingtianshu')"
                                            min-width="100">
                                            <template slot-scope="scope">
                                                <span style="color: #000;"> 
                                                    {{scope.row.RebateDay + ' / ' + scope.row.productPeriod }} </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="i18n('yichanshengshouyi') + ' / ' + i18n('yuqishouyi')"
                                            min-width="100">
                                            <template slot-scope="scope">
                                                <span style="color: #000;"> 
                                                    {{scope.row.RebateAmount + ' / ' + scope.row.estimatedRevenue}} </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="i18n('kaishishijian')"
                                            min-width="100">
                                            <template slot-scope="scope">
                                                <span style="color: #000;"> 
                                                    {{scope.row.createTime | timeFormat('ymdhms')}} </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            :label="i18n('jieshushijian')"
                                            min-width="100">
                                            <template slot-scope="scope">
                                                <span style="color: #000;"> 
                                                    {{scope.row.endTime | timeFormat('ymdhms')}} </span>
                                            </template>
                                        </el-table-column>
                                    </el-table>

                                    <div class="block">
                                        <el-pagination
                                            background
                                            hide-on-single-page
                                            :page-size=page_size
                                            :pager-count="11"
                                            layout="prev, pager, next"
                                            :current-page.sync=page
                                            @current-change="handleCurrentChange"
                                            class="flex_row_right"
                                            :total=totalCount>
                                        </el-pagination>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>

                    <div v-show="showditails">
                        <div class="crumbs_box">
                            <div class="light_gray" @click="closeDetails">
                                {{ i18n('yuanyuzhoukuangchi') }}
                                <i class="el-icon-arrow-right"></i>
                            </div> 
                            <div> {{ i18n('kuangchixiangqing') }} </div>
                        </div> 
                        <div class="form_card">
                            <div class="form_it">
                                <div> {{ i18n('suo') }} <br> 
                                    <span class="gre">USDT {{productUserMin}}</span>
                                </div> 
                                <div> {{ i18n('yitianchanchu') }} <br> 
                                    <span class="gre"> {{profitAmount}} {{profitCoin}} </span>
                                </div> 
                                <div> {{ i18n('zuixiaosuodingshijian') }} <br> 
                                    <span class="gre"> {{productPeriod}} {{ i18n('tian') }}</span>
                                </div> 
                                <div> {{ i18n('nindeUSDT') }} <br> 
                                    <span class="gre"> {{ userWallet.LegalCurrency | filter_moneyFormat(2) }} </span>
                                </div>
                            </div> 
                            <div class="input_box">
                                <el-input-number v-model="lockAmount" size="large"
                                :precision="2" :step="1" :min="0" :max="userWallet.LegalCurrency" 
                                :controls="false"
                                style="background-color: #fff; width: 100%; margin-top: 6px; border-radius: 8px;" 
                                class="inputnum"
                                :placeholder="i18n('qingshuruninyaosuodingdebizhongshuliang')"></el-input-number>
                                <span class="all" @click="allClick"> {{ i18n('quanbu') }} </span> 
                                <!-- <input type="text" id="lockAmountInp" placeholder="請輸入您要鎖定的币种 USDT 數量">  -->
                                
                                <!-- <input type="hidden" placeholder="請輸入您的邀請碼" name="title" autocomplete="off" id="inviteCodeInp" value="JyVacQ" class="layui-input"> -->
                            </div>
                        </div> 
                        <div class="wbut fz-16 fw-600" @click="lockClick"> {{ i18n('suoUSDTbingkaishishengchan' )}} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { moneyFormat } from '@/utils/filters.js'

export default {
    data() {
        return {
            tabType: 4,
            productList: [],
            activeName: 'first',
            recordListData: [],
            page: 1,
            page_size: 10,
            totalCount: 0,
            showditails: false,
            lockAmount: undefined,
            productid: undefined,
            productUserMin: undefined,
            profitAmount: undefined,
            profitCoin: undefined,
            productPeriod: undefined,
            userWallet: this.$store.state.userWallet,
        };
    },
    mounted() {
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getProductList();
                this.getUserProductCoinList();
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getProductList();
            this.getUserProductCoinList();
        };
        
    },
    beforeDestroy() {
        this.$root.$off('tradelogin');
    },
    filters:{
        timeFormat: function (time, type) {
            if (time && time > 0) {
                if (type === 'ymdhms') {
                    var needdate = new Date(time * 1000);
                } else {
                    var needdate = new Date(time);
                }
                var Y = needdate.getFullYear() + '.';
                var M = (needdate.getMonth() + 1 < 10 ? '0' + (needdate.getMonth() + 1) : needdate.getMonth() + 1) + '.';
                var D = needdate.getDate() + ' ';
                var h = (needdate.getHours() < 10 ? '0' + needdate.getHours() : needdate.getHours()) + ':';
                var m = (needdate.getMinutes() < 10 ? '0' + needdate.getMinutes() : needdate.getMinutes()) + ':';
                var s = (needdate.getSeconds() < 10 ? '0' + needdate.getSeconds() : needdate.getSeconds());
                //      年 月 日 时 分 秒
                if (type === 'ymdhms') {
                    return Y + M + D + h + m + s;
                } else {
                    return h + m + s;
                }
            } else {
                return '--';
            }
        },
        filter_moneyFormat: function (x, n) {
            return moneyFormat(x, n);
        },
    },
    methods: {
        handleCurrentChange() {
            this.getmoreList();
        },
        Opendetails(productid) {
            let data = {
                product_id: productid,
                product_number: '',
            }
            this.$api.getProductInfo(data).then((res)=>{
                if (res.data.code == 200) {
                    this.productid = res.data.data.id;
                    this.productUserMin = moneyFormat(res.data.data.productUserMin, 2);
                    this.profitAmount = res.data.data.profitAmount;
                    this.profitCoin = res.data.data.profitCoin;
                    this.productPeriod = res.data.data.productPeriod;
                    this.showditails = true;
                }
            })
        },
        closeDetails() {
            this.showditails = false;
        },
        allClick() {
            this.lockAmount = this.userWallet.LegalCurrency;
        },
        getProductList(){
            let data = {
                product_type: this.tabType,
            }
            this.$api.productList(data).then((res)=>{
                if (res.data.code == 200) {
                    this.productList = res.data.data.list;
                } else {
                    this.$message({
                        message: this.$t('treasure')['huoqushibai'],
                        type: 'warning'
                    });
                }
            })
        },
        lockClick() {
            this.$confirm(this.$t('treasure')['ninquedingyaosuoUSDTkaishishengchanma'], this.$t('treasure')['tishi'], {
                confirmButtonText: this.$t('treasure')['queding'],
                cancelButtonText: this.$t('treasure')['quxiao'],
                type: 'warning'
            }).then(() => {
                this.goBuy();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$t('treasure')['yiquxiao'],
                });          
            });
        },
        goBuy () {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = {
                product_id: this.productid,
                product_number: this.lockAmount,
            }

            this.$api.doProduct(data).then((res)=>{
                if (res.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: this.$t('treasure')['kaishishengchan'],
                    });
                    this.$store.dispatch('getMyLegalCurrencyAccountInfo');
                    this.getUserProductCoinList();
                    this.lockAmount = undefined;
                    setTimeout(()=>{
                        this.showditails = false;
                    }, 1500);
                } else {
                    this.$message.error(res.data.msg);
                }
                loading.close();
            })
        },
        getUserProductCoinList(){
            let data = {
                product_type: this.tabType,
                page: this.page,
                page_size: this.page_size,
            }
            this.$api.userProductCoin(data).then((res)=>{
                console.log(res);
                if (res.data.code == 200) {
                    this.recordListData = res.data.data.list;
                    this.totalCount = res.data.data.count;
                } else {
                    this.$message({
                        message: this.$t('treasure')['huoqushibai'],
                        type: 'warning'
                    });
                }
            })
        },
        async getmoreList() {
            let data = {
                product_type: this.tabType,
                page: this.page,
                page_size: this.page_size,
            }

            this.$api.userProductCoin(data).then((res)=>{
                if(res.data.code == 200){
                    this.recordListData = res.data.data.list;
                } else {
                    this.$message({
                        message: this.$t('treasure')['huoqushibai'],
                        type: 'warning'
                    });
                }
            });
        },
    },
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("treasure")[name];
            }
		},
    },
}
</script>

<style scoped>

.outer_tabs .el-tabs__nav-wrap::after {
	display: none !important;
}

.outer_tabs .el-tabs__item {
	font-size: 14px !important;
	font-weight: 400 !important;
	color: #929292 !important;
}

.outer_tabs .el-tabs__item.is-active {
	color: #000 !important;
}

.outer_tabs .el-tabs__active-bar {
	background: none !important;
	height: 2px !important;
}

.outer_tabs .el-tabs__nav-scroll {
	margin-bottom: 0;
}

.outer_tabs {
  padding-top: 20px;
}

.outer_tabs .el-tabs__item {
  padding: 0 10px !important;
  margin-right: 16px;
  color: #000 !important;
}

.outer_tabs .el-tabs__item.is-active {
  background: #F9F9F9;
}

</style>

<style scoped src="@/css/treasure.css">

</style> 