import http from '@/utils/request'


const api = {};
//首页轮播图
api.getBanner =(data)=>{
    return http({
        url: '/Home/banner/bannerList',
        method: 'post',
        data: data,
    })
}  

api.getNewsList =(data)=>{
    return http({
        url: '/Home/NewsApi/getNewsList',
        method: 'post',
        data: data,
    })
}  

api.getMyLegalCurrencyAccountInfo =(data) => {
    return http({
        url: '/Home/UserLegalCurrencyAccount/getMyLegalCurrencyAccountInfo',
        method: 'post',
        data: data,
    })
}

api.getOTCTransactionFlowList =(data) => {
    return http({
        url: '/Home/transaction/OTCTransactionFlowList',
        method: 'post',
        data: data,
    })
}

api.getcontractTransactionFlowList =(data) => {
    return http({
        url: '/Home/transaction/contractTransactionFlowList',
        method: 'post',
        data: data,
    })
}

api.getaddressInfo =(data) => {
    return http({
        url: '/home/User/addressInfo',
        method: 'post',
        data: data,
    })
}

api.getrechargeorderList =(data) => {
    return http({
        url: '/Home/userrecharge/orderList',
        method: 'post',
        data: data,
    })
}

api.getwithdrawaddOrderInfo =(data) => {
    return http({
        url: '/Home/userwithdraw/addOrderInfo',
        method: 'post',
        data: data,
    })
}

api.getwithdraworderList =(data) => {
    return http({
        url: '/home/userwithdraw/orderList',
        method: 'post',
        data: data,
    })
}

api.getwithdraworderInfo =(data) => {
    return http({
        url: '/home/userwithdraw/orderInfo',
        method: 'post',
        data: data,
    })
}

api.getmakeVerifyBase64 =(data) => {
    return http({
        url: '/Home/Register/makeVerifyBase64',
        method: 'post',
        data: data,
    })
}

api.getEmailTextCode =(data) => {
    return http({
        url: '/Home/UserSecurity/getEmailTextCode',
        method: 'post',
        data: data,
    })
}

api.getUserAuthPhoneAndEmail =(data) => {
    return http({
        url: '/Home/UserSecurity/getUserAuthPhoneAndEmail',
        method: 'post',
        data: data,
    })
}

api.textCodeCheck =(data) => {
    return http({
        url: '/Home/UserSecurity/textCodeCheck',
        method: 'post',
        data: data,
    })
}

api.withdrawaddOrder =(data) => {
    return http({
        url: '/home/userwithdraw/addOrder',
        method: 'post',
        data: data,
    })
}

api.contractTransactionRecordList =(data) => {
    return http({
        url: '/Home/transaction/contractTransactionRecordList',
        method: 'post',
        data: data,
    })
}

api.nickNameEdit =(data) => {
    return http({
        url: '/Home/UserSecurity/nickNameEdit',
        method: 'post',
        data: data,
    })
}

api.getUserPublicInfo =(data) => {
    return http({
        url: '/Home/UserSecurity/getUserPublicInfo',
        method: 'post',
        data: data,
    })
}

api.getAuthenticationInfo =(data) => {
    return http({
        url: '/Home/UserAuthenticationStatus/getAuthenticationInfo',
        method: 'post',
        data: data,
    })
}

api.getPhoneTextCode =(data) => {
    return http({
        url: '/Home/UserSecurity/getPhoneTextCode',
        method: 'post',
        data: data,
    })
}

api.addFundPwd =(data) => {
    return http({
        url: '/Home/UserSecurity/addFundPwd',
        method: 'post',
        data: data,
    })
}

api.editFundPwd =(data) => {
    return http({
        url: '/Home/UserSecurity/editFundPwd',
        method: 'post',
        data: data,
    })
}

api.getEmailTextCode =(data) => {
    return http({
        url: '/Home/UserSecurity/getEmailTextCode',
        method: 'post',
        data: data,
    })
}

api.loadPic =(data) => {
    return http({
        url: '/Home/Register/loadPics',
        method: 'post',
        data: data,
    })
}

api.realNameCertification =(data) => {
    return http({
        url: '/Home/UserSecurity/realNameCertification',
        method: 'post',
        data: data,
    })
}

api.myInvitCode =(data) => {
    return http({
        url: '/Home/UserSecurity/myInvitCode',
        method: 'post',
        data: data,
    })
}

api.myInvitee =(data) => {
    return http({
        url: '/Home/UserSecurity/myInvitee',
        method: 'post',
        data: data,
    })
}


api.productList =(data) => {
    return http({
        url: '/Home/Coincunbi/productList',
        method: 'post',
        data: data,
    })
}

api.getEmailTextCodeNewPic =(data) => {
    return http({
        url: '/Home/Register/getEmailTextCodeNewPic',
        method: 'post',
        data: data,
    })
}

api.getUserCunbiAccount =() => {
    return http({
        url: '/Home/Coincunbi/userCunbiAccount',
        method: 'post',
        data: {},
    })
}

api.getProductInfo =(data) => {
    return http({
        url: '/Home/Coincunbi/productInfo',
        method: 'post',
        data: data,
    })
}

api.doProduct =(data) => {
    return http({
        url: '/Home/Coincunbi/doProduct',
        method: 'post',
        data: data,
    })
}

api.userProductOrderList =(data) => {
    return http({
        url: '/Home/Coincunbi/userProductOrderList',
        method: 'post',
        data: data,
    })
}

api.userProductCoin =(data) => {
    return http({
        url: '/Home/Coincunbi/userProductCoin',
        method: 'post',
        data: data,
    })
}

api.regist =(data) => {
    return http({
        url: '/home/register/regist',
        method: 'post',
        data: data,
    })
}

api.doRegisterNew =(data) => {
    return http({
        url: '/Home/Register/doRegisterNew',
        method: 'post',
        data: data,
    })
}

api.getEmailTextCode_Register =(data) => {
    return http({
        url: '/Home/Register/getEmailTextCode',
        method: 'post',
        data: data,
    })
}

api.textCodeCheck_Register =(data) => {
    return http({
        url: '/Home/Register/textCodeCheck',
        method: 'post',
        data: data,
    })
}

api.resetPwd =(data) => {
    return http({
        url: '/home/index/resetPwd',
        method: 'post',
        data: data,
    })
}   

api.ransomCoin =(data) => {
    return http({
        url: '/Home/Coincunbi/ransomCoin',
        method: 'post',
        data: data,
    })
}  

api.fucproductList =(data) => {
    return http({
        url: '/home/Index/fucproductList',
        method: 'post',
        data: data,
    })
} 

api.getFucHistoryInfo =(data) => {
    return http({
        url: '/Home/Index/getFucHistoryInfo',
        method: 'post',
        data: data,
    })
} 

api.getUserFucCoinAccounts =(data) => {
    return http({
        url: '/Home/FucCoin/getUserFucCoinAccounts',
        method: 'post',
        data: data,
    })
} 

api.buyFucCoin =(data) => {
    return http({
        url: '/Home/FucCoin/preBuyFucCoin',
        method: 'post',
        data: data,
    })
} 

api.getUserFucCoinAccountRecord =(data) => {
    return http({
        url: '/Home/FucCoin/getUserFucCoinOrders',
        method: 'post',
        data: data,
    })
} 

api.contractspotTransactionFlowList =(data) => {
    return http({
        url: '/Home/Transaction/contractspotTransactionFlowList',
        method: 'post',
        data: data,
    })
}

api.contractspotTransactionRecordList =(data) => {
    return http({
        url: '/Home/Transaction/contractspotTransactionRecordList',
        method: 'post',
        data: data,
    })
}

api.userPictureEdit =(data) => {
    return http({
        url: '/Home/UserSecurity/userPictureEdit',
        method: 'post',
        data: data,
    })
}

api.contractoptionOrderEntrustList =(data) => {
    return http({
        url: '/Home/Transaction/contractoptionOrderEntrustList',
        method: 'post',
        data: data,
    })
}

api.getAllMoney =(data) => {
    return http({
        url: '/Home/UserLegalCurrencyAccount/getMyLegalCurrencyAccountInfo',
        method: 'post',
        data:data
    })
}

api.invitesData =(data) => {
    return http({
        url: '/Home/UserSecurity/invitesData',
        method: 'post',
        data:data
    })
}

api.totalCommission =(data) => {
    return http({
        url: '/Home/UserSecurity/totalCommission',
        method: 'post',
        data:data
    })
}

api.bonusRecords =(data) => {
    return http({
        url: '/Home/UserSecurity/bonusRecords',
        method: 'post',
        data:data
    })
}

api.getKlineList =(data) => {
    return http({
        url: '/Home/Kline/getKline',
        method: 'post',
        data:data
    })
}

api.getRegistGoogle =(data) => {
    return http({
        url: '/home/Google/googleCallback',
        method: 'post',
        data:data
    })
}

api.doRegistGoogle =(data) => {
    return http({
        url: '/Home/register/registGoogle',
        method: 'post',
        data:data
    })
}

api.bindGoogle =(data) => {
    return http({
        url: '/Home/register/bindGoogle',
        method: 'post',
        data:data
    })
}
export default api;