export default {
    header: {
    hello: '你好，世界！',
    changeLanguage: '切換語言',
    Login: '登入',
    Register: '註 冊',
    Logout: '登出登入',
    shouye: '首頁',
    bibijiaoyisuo: '幣幣交易',
    heyuejiaoyisuo: '合約交易',
    licaichanpin: '礦池',
    rengou: '申購',
    ruanjianxiazai: '軟體下載',
    zhongwen: '中文',
    yingwen: '在',
    zichan: '資產',
    dingdan: '訂單',
    heyuedingdan: '合約訂單',
    bibidingdan: '幣幣訂單',
    anquanzhongxin: '安全中心',
    fanyongyaoqing: '返傭邀請',
    tuichudenglu: '登出登入',
    gupiaojiaoyi: '股票交易',
    waihuijiaoyi: '外匯交易',
    qiquanjiaoyi:'選擇權交易',
    gupiaodingdan: '股票訂單',
    waihuidingdan: '外匯訂單',
    qiquandingdan: '選擇權訂單',
    jiaoyidingdan:'交易訂單',
    daolizhongxin:'代理中心',
    huodong:'活動'
    },
    home: {
    huanyinglaidao: '歡迎來到',
    pingtaimingcheng: '這裡',
    mengxiangqihang: '夢想起航',
    yijiankaiqishuzihualvcheng: '一鍵開啟數位化旅程',
    liaojiegengduo: '了解更多',
    chongzhi: '儲值',
    jiaoyi: '交易',
    remenbang: '熱門榜',
    zhangfubang: '漲幅榜',
    diefubang: '跌幅榜',
    chengjiaoliang: '成交量',
    zanwushuju: '暫無數據',
    jiayidui: '交易對',
    zuixinjiage: '最新價格',
    zhangdie24h: '24小時漲跌',
    caozuo: '操作',
    jiaoyi: '交易',
    gengduo: '更多',
    suishisuidikaiqijiaoyi: '隨時隨地 開啟交易',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: '無論是App還是Web,都可以快速開啟您的交易',
    saomaxiazaiApp: '掃碼下載APP',
    iosheAndroid: 'ios和Android',
    ninkexinrenqieanquandejiamibijiaoyisuo: '您可信任且安全的加密貨幣交易所',
    tishi1: '儲備金證明',
    xiangqing1: '我們承諾,至少以1:1的比例持有所有用戶資產',
    tishi2: '最優的交易費率',
    xiangqing2: '優惠的交易費率,富有競爭力的VIP權益,享有最優品質的服務',
    tishi3: '可信賴的安全保障',
    xiangqing3: '我們複雜的安全措施和SAFU基金保護您的數位資產免受所有風險',
    tishi4: '24*7小時客服支持',
    xiangqing4: '24*7小時全天候、全日制營運模式,第一時間為您解答相關諮詢',
    tishi5: '穩定安全',
    xiangqing5: '全球超前交易所，為您的資產提供銀行級安全保護',
    tishi6: '常見問題',
    xiangqing6: '查看常見問題，來了解特定功能的詳細說明',
    },
    footer: {
    bangzhuzhongxin: '幫助中心',
    dianhuazixun: '電話諮詢',
    dianhuahegongzuoshijian: '1670-9756(平日10:00~19:00/週末及假日休息)',
    liaotianzixun: '1:1 聊天諮詢',
    keketuozixun: '可可托克諮詢',
    zaixianbangzhu: '線上幫助',
    h24kaifang: '(24小時開放)',
    lixianbangzhu: '離線協助',
    gongsidizhi: '首爾特別市龍山區漢江大道69號龍山Purgio峰會1樓',
    gongzuoshijian: '(平日10:00~18:00 / 週末及假日休息)',
    shangshizixun: '上市諮詢',
    hezuozixun: '合作諮詢',
    gongsi: '公司',
    lianxiwomwn: '聯絡我們',
    zhidao: '指導',
    yonghuzhichi: '用戶支援',
    zhanghuzhinan: '帳號指南-充/提幣',
    ruanjianxiazai: '軟體下載',
    zhengce: '政策',
    fuwuyuyinsitiaokuan: '服務與隱私權條款',
    },
    login: {
    yonghudenglu: '使用者登入',
    huanyingdenglu: '歡迎登入',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: '官方網站',
    youxiang: '信箱',
    qingshuruyouxiang: '請輸入您的信箱',
    mima: '密碼',
    qingshurumima: '請輸入密碼',
    wangjimima: '忘記密碼?',
    denglu: '登入',
    haimeiyouzhanghao: '還沒有帳號？去',
    zhuce: '註冊',
    dengluchenggong: '登入成功',
    denglushibai: '登入失敗',
    qingshuruyonghuminghemima: '請輸入使用者名稱和密碼',
    mimachangdubunengxiaoyuliuwei: '密碼長度不能小於6位',
    youxiangrenzheng: '信箱驗證',
    youxiangdizhi: '郵件地址',
    qingshuruyouxiangdizhi: '請輸入郵件地址',
    tuxingyanzhengma: '圖形驗證碼',
    qingshurutuxingyanzhengma: '請輸入圖形驗證碼',
    yanzhengma: '驗證碼',
    qingshuruyouxiangyanzhengma: '請輸入信​​箱驗證碼',
    huoquyouxiangyanzhengma: '取得郵箱驗證碼',
    queding: '確定',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '取得圖形驗證碼失敗，請重新要求',
    qingxianwanchengyouxiangrenzheng: '請先完成郵箱認證',
    huoquyouxiangyanzhengmashibai: '取得郵箱驗證碼失敗,',
    houchongxinfasong: 'S後重新發送',
    youxiangyanzhengchenggong: '郵箱驗證成功',
    yanzhengmacuowu: '驗證碼錯誤',
    xiugaidenglumima: '修改登入密碼',
    xinmima: '新密碼',
    quedingxinmima: '確定新密碼',
    qingshuruxinmima: '請輸入新密碼',
    qingzaicishuruxinmima: '請再次輸入新密碼',
    liangcimimashurubuyizhi: '兩次密碼輸入不一致',
    mimaxiugaichenggong: '密碼修改成功',
    xiugaimimashibai: '修改密碼失敗',
    },
    trade: {
    hangqingheyue: '合約',
    hangqingzuixinjia: '最新價',
    hangqingzhangdiefu: '漲跌幅',
    hangqingzuigao24h: '24H最高',
    hangqingzuidi24h: '24H最低',
    hangqingchengjiaoliang24h: '24H成交量',
    hangqingchengjiabishu: '成交筆數',
    zhanghuquanyi: '帳戶權益',
    zhanghukeyongshouyi: '可用權益',
    hangqingweituoliebiao: '委託列表',
    hangqingjiage: '價格',
    hangqingshuliang: '數量',
    hangqingzuixinchengjiao: '最新成交',
    hangqingjiage1: '價格',
    hangqingshuliang1: '數量',
    hangqingshijian: '時間',
    dingdanchiyoucangwei: '持有部位',
    dingdandangqianweituo: '當前委託',
    dingdanchengjiaojilu: '成交記錄',
    dingdantiaojiandan: '條件單',
    chicangheyue: '合約',
    chicangkaicangjunjia: '開倉均價',
    chicangfudongyingkui: '浮動損虧(USDT)',
    chicanggangganbeishu: '槓桿倍數',
    chicangchicangliang: '持倉量(張)',
    chicangduokong: '多/空',
    chicangduo: '多',
    chicangkong: '空',
    chicangzhiyingjiage: '止盈價格',
    chicangzhisunjiage: '停損價格',
    chicangqiangpingyugujiage: '強平預估價格',
    chicangcaozuo: '操作',
    chicangzhiyingzhisun: '停損',
    chicangpingcang: '平倉',
    chicangshouxufei: '手續費',
    weituoheyue: '合約',
    weituoshuliang: '委託數量',
    weituojiage: '委託價格',
    weituofangxiang: '方向',
    weituogangganbeishu: '槓桿倍數',
    weituochengjiaoshuliang: '成交數量',
    weituoshouxufei: '手續費',
    weituochengjiaojunjia: '成交均價',
    weituoshouyi: '效益',
    weituozhuangtai: '狀態',
    weituochengjiaoshijian: '成交時間',
    weituocaozuo: '操作',
    weituochedan: '撤銷單',
    weituotijiaoshibai: '提交失敗',
    weituozhilingtijiao: '指令提交',
    weituoyitijiao: '已提交',
    weituobufenchengjiao: '部分成交',
    weituobufenchengjiaoyichedan: '部分成交已撤單',
    weituowanquanchengjiao: '完全成交',
    weituowanquanchedan: '完全撤單',
    weituochedanzhong: '撤銷單中',
    weituokaiduo: '開多',
    weituopingkong: '平坦的天空',
    weituokaikong: '開空',
    weituopingduo: '平多',
    chengjiaoheyue: '合約',
    chengjiaofangxiang: '方向',
    chengjiaoshuliang: '成交數量',
    chengjiaojiage: '成交價格',
    chengjiaoshijian: '成交時間',
    chengjiaopingcangyingkui: '平倉盈虧',
    chengjiaoshouxufei: '成交手續費',
    chengjiaokaiduo: '開多',
    chengjiaopingkong: '平坦的天空',
    chengjiaokaikong: '開空',
    chengjiaopingduo: '平多',
    tiaojianzengjiatiaojian: '增加條件',
    tiaojianzantingquanbu: '暫停全部',
    tiaojianqidongquanbu: '啟動全部',
    tiaojianshanchuquanbu: '刪除全部',
    tiaojianheyue: '合約',
    tiaojianweituoshuliang: '委託數量',
    tiaojianfangxiang: '方向',
    tiaojiantiaojianleixing: '條件類型',
    tiaojianjiageleixing: '價格類型',
    tiaojianbijiaofuhao: '比較符號',
    tiaojiantiaojian: '條件',
    tiaojianzhuangtai: '狀態',
    tiaojianchuangjianshijian: '創建時間',
    tiaojiancaozuo: '操作',
    tiaojianxiugai: '修改',
    tiaojianqidong: '啟動',
    tiaojianzanting: '暫停',
    tiaojianshanchu: '刪除',
    tiaojianlijixiadan: '立即下單',
    tiaojiankaiduo: '開多',
    tiaojianpingkong: '平坦的天空',
    tiaojiankaikong: '開空',
    tiaojianpingduo: '平多',
    tiaojianjiagetiaojian: '價格條件',
    tiaojianshijiantiaojian: '時間條件',
    tiaojianzuixinjia: '最新價',
    tiaojianByijia: '買一價',
    tiaojianSyijia: '賣一價',
    tiaojianzanting: '暫停',
    tiaojianyunxing: '運行',
    tiaojianyiwancheng: '已完成',
    tiaojianshibai: '失敗',
    tiaojianyishanchu: '已刪除',
    tiaojianshoudongchufawancheng: '手動觸發完成',
    tiaojianshoudongchufashibai: '手動觸發失敗',
    xiadankaicang: '開倉',
    xiadanpingcang: '平倉',
    xiadanweituoleixing: '委託類型',
    xiadanqingxuanze: '請選擇',
    xiadanshijia: '市價',
    xiadanxianjia: '限價',
    xiadanjiage: '價格',
    xiadanqingshurujiage: '請輸入價格',
    xiadanshuliang: '數量',
    xiadanqingshurushuliang: '請輸入數量',
    xiadanzhang: '張',
    xiadankekaishuliang: '可開數量',
    xiadanqingxianxuanzechicangdan: '請先選擇持倉單',
    xiadankepingshuliang: '可平數量',
    xiadansuoxubaozhengjin: '所需保證金:',
    xiadanshijiazhiyingzhisunyushe: '市價止盈停損預設',
    xiadanyujiqiangpingjiage: '預計強平價：',
    xiadanqiangpingjiagetishi: '此預計強平價根據總資產動態計算的。此價格僅供參考，不作為最終強平使用。',
    xiadanzuoduo: '做多',
    xiadanzuokong: '做空',
    xiadanpingcang: '平倉',
    zhanghuheyuezichan: '合約資產',
    zhanghuchongbi: '充幣',
    zhanghutibi: '提幣',
    zhanghuhuazhuan: '劃轉',
    zhanghuzhanghuquanyi: '帳戶權益',
    zhanghufudongyingkui: '浮動損虧',
    zhanghucangweibaozhengjin: '部位保證金',
    zhanghudongjiebaozhengjin: '凍結保證金',
    zhanghukeyongquanyi: '可用權益',
    zhanghubaozhengjinshiyonglv: '保證金使用率',
    hangqingheyuexinxi: '合約訊息',
    hangqingheyuemingcheng: '合約名稱',
    hangqingjijiahuobi: '計價貨幣',
    hangqingheyuedaxiao: '合約大小',
    hangqingzuixiaojiagebodong: '最小價格波動',
    dialogleverRateganggantiaozheng: '槓桿調整',
    dialogleverRatequeding: '確定',
    dialogtransferzichanhuazhuan: '資產劃轉',
    dialogtransferbizhong: '幣種',
    dialogtransfercong: '從',
    dialogtransferdao: '到',
    dialogtransfershuliang: '數量',
    dialogtransferqingshuruhuazhuanshuliang: '請輸入劃轉數量',
    dialogtransferquanbu: '全部',
    dialogtransferkeyong: '可用',
    dialogtransferhuazhuan: '劃轉',
    dialogtransferjichuzhanghu: '基礎帳戶',
    dialogtransferheyuezhanghu: '合約帳戶',
    dialogfitlosszhiyingzhisun: '停損',
    dialogfitlossheyu: '合約',
    dialogfitlossduokong: '多/空',
    dialogfitlosschichangjunjia: '持倉均價',
    dialogfitlossshuliang: '數量',
    dialogfitlosszuixinjia: '最新價',
    dialogfitlosszhiyingdianshu: '止盈點數',
    dialogfitlosszhisundianshu: '停損點數',
    dialogfitlossdianshu: '分數',
    dialogfitlosszhiyingjiage: '止盈價格',
    dialogfitlosszhisunjiage: '停損價格',
    dialogfitlossjiage: '價格',
    dialogfitlossyuqishouyi: '預期收益',
    dialogfitlossyuqikuisun: '預期虧損',
    dialogfitlosstishi1: '提示：當前合約最小波動為',
    dialogfitlosstishi2: ',取消停盈停損請將價格設定為0',
    dialogfitlossqueding: '確定',
    dialogfitlossduo: '多',
    dialogfitlosskong: '空',
    dialogconditionzengjiayuntiaojiandan: '增加雲條件單',
    dialogconditiontiaojianleixing: '條件類型:',
    dialogconditionjiagetiaojiandan: '價格條件單',
    dialogconditionshijiantiaojiandan: '時間條件單',
    dialogconditionjiageleixing: '價格類型:',
    dialogconditionzuixinjia: '最新價',
    dialogconditionByijia: '買一價',
    dialogconditionSyijia: '賣一價',
    dialogconditiontiaojianshezhi: '條件設定:',
    dialogconditionjiageshezhi: '價格設定:',
    dialogconditionqingshurujiage: '請輸入價格',
    dialogconditionshijianshezhi: '時間設定:',
    dialogconditionjiaoyileixing: '交易類型:',
    dialogconditionkaiduo: '開多',
    dialogconditionpingkong: '平坦的天空',
    dialogconditionkaikong: '開空',
    dialogconditionpingduo: '平多',
    dialogconditiondingdanshuliang: '訂單數量:',
    dialogconditionqingshurudingdanshuliang: '請輸入訂單數量',
    dialogconditiondingdanshixiao: '訂單時效:',
    dialogconditionyongjiuyouxia: '永久有效',
    dialogconditiontijiao: '提交',
    dialogAutoProfitLosszhiyingzhisun: '停損',
    dialogAutoProfitLossmingcheng: '名稱',
    dialogAutoProfitLosszhiyingdian: '止盈點',
    dialogAutoProfitLosszhisundian: '停損點',
    dialogAutoProfitLosschongzhi: '重置',
    dialogAutoProfitLoss: '提交',
    dialogCloseOrderpingcang: '平倉',
    dialogCloseOrderweituoleixing: '委託類型',
    dialogCloseOrderqingxuanze: '請選擇',
    dialogCloseOrdershijia: '市價',
    dialogCloseOrderxianjia: '限價',
    dialogCloseOrderjiage: '價格',
    dialogCloseOrderqingshurujiage: '請輸入價格',
    dialogCloseOrdershuliang: '數量',
    dialogCloseOrderqingshurushuliang: '請輸入數量',
    dialogCloseOrderzhang: '張',
    dialogCloseOrderkepingshuliang: '可平數量',
    dialogCloseOrderqueding: '確定',
    jsMessageResgaiheyuegangganxiugaichenggong: '該合約槓桿修改成功',
    jsMessageResgaiheuyegangganxiugaishibai: '該合約槓桿修改失敗',
    jsMessageReskaicangchenggong: '開倉成功',
    jsMessageReskaicangshibai: '開倉失敗：',
    jsMessageReskaicang1: '禁止開倉',
    jsMessageReskaicang2: '合約不存在',
    jsMessageReskaicang3: '開倉數量超過上限',
    jsMessageReskaicang4: '價格為0',
    jsMessageReskaicang5: '開倉保證金不足',
    jsMessageRespingcangchenggong: '平倉成功',
    jsMessageRespingcangshibai: '平倉失敗：',
    jsMessageRespingcang1: '合約不存在',
    jsMessageRespingcang2: '持倉單不存在',
    jsMessageRespingcang3: '可平量不足',
    jsMessageReszijinhuazhuanchenggong: '資金劃轉成功',
    jsMessageReszijinhuazhuanshibai: '資金劃轉失敗',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: '持倉單止盈停損設定成功',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: '持倉單止盈停損設定失敗：',
    jsMessageResweituodanchedanchenggong: '委託單撤單成功',
    jsMessageResweituodanchedanshibai: '委託單撤單失敗',
    jsMessageRestiaojiandantianjiachenggong: '條件單添加成功',
    jsMessageResheyuebucunzai: '合約不存在',
    jsMessageResjiageshezhicuowu: '價格設定錯誤',
    jsMessageResshijianshezhicuowu: '時間設定錯誤',
    jsMessageResneibucuowu: '內部錯誤',
    jsMessageRestiaojiandantianjiashibai: '條件單新增失敗：',
    jsMessageReszhiyingzhisunshezhichenggong: '止盈停損設定成功',
    jsconfirmquedingchedanma: '確定撤單嗎？',
    jsconfirmtishi: '提示',
    jsconfirmqueding: '確定',
    jsconfirmquxiao: '取消',
    jsMessageqingxiandenglu: '請先登入！',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: '該合約有持倉，不可修改槓桿',
    jsMessagegaiheyueyouweituobukexiugaiganggan: '該合約有委託，不可修改槓桿',
    jsMessageqingshuruzhengquedeshuliang: '請輸入正確的數量！',
    jsMessageqingshuruzhengquedejiage: '請輸入正確的價格！',
    jsMessageqingxianxuanzechicang: '請先選擇持倉！',
    jsMessageqingshuruhuazhuanshuliang: '請輸入劃轉數量！',
    jsMessageqingxianxuanzeheyue: '請先選擇合約！',
    jsMessageqingshurutiaojiandanshuliang: '請輸入條件單數量！',
    jsMessageqingshurutiaojiandandejiage: '請輸入條件單的價格！',
    jsMessageqingshurutiaojiandanzhixingshijian: '請輸入條件單執行時間！',
    emptytext: '暫無數據',
    jsMessageReszhiyingzhisundianshushezhicuowu: '止盈止損點數設定錯誤',
    jsMessageReschicangdanbucunzai: '持倉單不存在',
    zxkcje:'最小開倉金額'
    },
    commisson:{
    dlzx:'代理中心',
    sjzl:'數據總覽',
    quanbu:"全部",
    jinri:'今天',
    zuori:'昨天',
    ri:'天',
    wdfy:'我的返傭',
    wdsjfy:'我的三級返傭',
    dengji:'等級',
    yqrs:'邀請人數',
    tdrs:'團隊人數',
    yqxq:'邀請詳情',
    yhm:"使用者名稱",
    cjsj:'創建時間',
    fyxq:'返傭詳情',
    zyj:'總佣金',
    dryj:'單日佣金',
    fyje:'返佣金額',
    
    },
    assets: {
    zongzichan: '總資產',
    chongbi: '充幣',
    tibi: '提幣',
    huazhuan: '劃轉',
    fabizhanghu: '基礎帳戶',
    heyuezhanghu: '合約帳戶',
    jiayiliushui: '交易流水',
    dialogzichanhuazhuan: '資產劃轉',
    cong: '從',
    dao: '到',
    shuliang: '數量',
    qingshuruhuazhuanshuliang: '請輸入劃轉數量',
    quanbu: '全部',
    keyong: '可用',
    huanzhuan: '劃轉',
    zijinhuazhuanchenggong: '資金劃轉成功',
    zijinhuazhuanshibai: '資金劃轉失敗',
    bibizhanghu: '幣幣帳戶',
    qiquanzhanghu:'期權帳戶',
    zczl:'資產總覽',
    zzczh:'總資產折和',
    ztzc:"在途資產",
    djzc:'凍結資產',
    zyzc:'質押資產',
    cwzc:'部位資產',
    jyzh:'交易帳戶',
    bizhong:'幣種',
    dongjie:'凍結',
    lirun:"利潤",
    hyzh:"合約帳戶",
    },
    basicsAccountRecord: {
    fanhui: '返回',
    fabizhanghuliushui: '交易帳戶流水',
    fabijiaoyiliushuileixing: '交易流程類型',
    qingxuanze: '請選擇',
    chaxunriqi: '查詢日期',
    liushuileixing: '流水類型',
    shijian: '時間',
    biandongjine: '變動金額',
    yue: '餘額',
    quanbu: '全部',
    Sellbi: '賣幣',
    Buybi: '買幣',
    tibi: '提幣',
    chongbi: '充幣',
    fabizhuanheyue: '基礎轉合約',
    heyuezhuanfabi: '合約轉基礎',
    zhuanzhang: '轉帳',
    fabihuazhuandaoheyue: '基礎劃轉到合約',
    heyuehuazhuandaofabi: '合約劃轉到基礎',
    goumaijijin: '購買礦池',
    jijinshuhui: '礦池贖回',
    jijinshouyi: '礦池贖回收益',
    fabizhuanqihuo: '基礎轉期貨',
    qihuozhuanfabi: '期貨轉基礎',
    fabizhuanganggu: '基礎轉港股',
    gangguzhuanfabi: '港股轉基礎',
    fabizhuanbibi: '基礎轉幣幣',
    bibizhuanfabi: '幣幣轉基礎',
    goumaiSGCB: '購買ICO',
    chushouSGCB: '出售ICO',
    huoqushibai: '獲取失敗',
    zanwushuju: '暫無數據',
    },
    contractAccountRecord: {
    fanhui: '返回',
    heyuezhanghuliushui: '合約帳戶流水',
    heyuejiaoyiliushuileixing: '合約交易流水類型',
    qingxuanze: '請選擇',
    chaxunriqi: '查詢日期',
    liushuileixing: '流水類型',
    shijian: '時間',
    biandongjine: '變動金額',
    yue: '餘額',
    quanbu: '全部',
    kaicangshouxufei: '開倉手續費',
    pingcangshouxufei: '平倉手續費',
    pingcangyingkui: '平倉盈虧',
    fabizhuanheyue: '基礎轉合約',
    heyuezhuanfabi: '合約轉基礎',
    zhuanzhang: '轉帳',
    kaicangyongjin: '開倉佣金',
    pingcangyongjin: '平倉佣金',
    huoqushibai: '獲取失敗',
    zanwushuju: '暫無數據',
    },
    recharge: {
    zanwushuju: '暫無數據',
    shijian: '時間',
    leixing: '類型',
    shuliang: '數量',
    zhuangtai: '狀態',
    caozuo: '操作',
    chakanxiangqing: '看詳情',
    huoqushibai: '獲取失敗',
    dialogtitle: '詳情',
    chongbi: '充幣',
    fanhui: '返回',
    lianmingcheng: '鏈名稱',
    fuzhilianjie: '複製地址',
    tishixinxi1: '• 請勿向上述地址充值任何非 TRC_USDT或ERC_USDT 資產,否則資產將不可找回',
    tishixinxi2: '• 您儲值至上述地址後,需要整個網路節點的確認,1次網路確認後到帳,2次網路確認後可提幣',
    tishixinxi3: '• 最小儲值金額:50USDT,小於最小金額的儲值將不會上帳且無法退回',
    tishixinxi4: '• 您的儲值地址不會經常改變,可以重複充值;如有更改,我們會盡量透過網站公告或郵件通知您',
    tishixinxi5: '• 請務必確認電腦及瀏覽器安全,防止資訊被竄改或洩露',
    putongchongbi: '普通充幣',
    jiaoyichenggong: '交易成功',
    jiaoyishibai: '交易失敗',
    OMNIweihuzhong: 'OMNI鏈維護中,請選擇TRC20',
    },
    withdraw: {
    fanhui: '返回',
    zanwushuju: '暫無數據',
    tibi: '提幣',
    huoqushibai: '獲取失敗',
    OMNIweihuzhong: 'OMNI鏈維護中,請選擇TRC20',
    shijian: '時間',
    leixing: '類型',
    shuliang: '數量',
    zhuangtai: '狀態',
    caozuo: '操作',
    chakanxiangqing: '看詳情',
    putongtibi: '普通提幣',
    dialogtitle: '詳情',
    dialogtitlewithdraw: '提幣',
    houchongxinfasong: 'S後重新發送',
    huoquyouxiangyanzhengma: '取得郵箱驗證碼',
    lianmingcheng: '鏈名稱',
    tibidizhi: '提幣地址',
    qingshurutibidizhi: '請輸入提幣地址',
    tibishuliang: '提幣數量',
    shouxufei: '手續費',
    qingshurutibishuliang: '請輸入提幣數量',
    quanbu: '全部',
    keyong: '可用',
    daozhangshuliang: '到帳數量',
    zuixiaotibishuliang: '最小提幣數量為:',
    tishixinxi: '為保障資金安全,當您帳戶安全性原則變更,密碼修改,我們會對提幣進行人工審核,請耐心等待工作人員電話或電郵聯繫。請務必確認電腦及瀏覽器安全,防止資料被竄改或外洩。',
    tuxingyanzhengma: '圖形驗證碼',
    qingshurutuxingyanzhengma: '請輸入圖形驗證碼',
    youxiangyanzhengma: '信箱驗證碼',
    queding: '確定',
    tijiaozhong: '提交中',
    yijujue: '已拒絕',
    yitongguo: '已通過',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '取得圖形驗證碼失敗，請重新要求',
    huoquyouxiangyanzhengmashibai: '取得郵箱驗證碼失敗,',
    qingxianwanchengyouxiangrenzheng: '請先完成郵箱認證',
    huoquyouxiangshibaiqingchongxinqingqiu: '取得郵箱失敗,請重新要求',
    qingshuruyouxiangyanzhengma: '請輸入信​​箱驗證碼',
    youxiangyanzhengmacuowu: '郵箱驗證碼錯誤,',
    qxsmrz:'請先實名認證',
    tishi:'提示',
    queding:'確定'
    },
    contractTransactionRecord: {
    fanhui: '返回',
    zanwushuju: '暫無數據',
    heyuedingdan: '合約訂單',
    qingxuanze: '請選擇',
    quanbu: '全部',
    kaiduo: '開多',
    pingkong: '平坦的天空',
    kaikong: '開空',
    pingduo: '平多',
    xianjiaweituo: '限價委託',
    shijiaweituo: '市價委託',
    suoyouzhuangtai: '所有狀態',
    tijiaoshibai: '提交失敗',
    zhunbeitijiao: '準備提交',
    yitijiao: '已提交',
    bufentijiao: '部分成交',
    bufentijiaoyichedan: '部分成交已撤單',
    quanbuchengjiao: '全部成交',
    yichedan: '已撤銷單',
    huoqushibai: '獲取失敗',
    heyuedaima: '合約代碼',
    ganggan: '槓桿',
    xiadanshijian: '下單時間',
    chengjiaoshijian: '成交時間',
    fangxiang: '方向',
    baojialeixing: '報價類型',
    weituoliang: '委託量(張)',
    weituojia: '委託價(USDT)',
    chengjiaoliang: '成交量(張)',
    chengjiaojunjia: '成交均價(USDT)',
    yingkui: '盈虧(USDT)',
    shouxufei: '手續費(USDT)',
    zhuangtai: '狀態',
    gupiaodingdan: '股票訂單',
    waihuidingdan: '外匯訂單',
    gupiaodaima: '股票代碼',
    waihuidaima: '外匯代碼',
    },
    kLine: {
    zhibiao: '指標',
    shezhi: '設定',
    quanping: '全螢幕',
    tuichuquanping: '退出全螢幕',
    jishuzhibiao: '技術指標',
    futuzhibiao: '副圖指標',
    zhutuzhibiao: '主圖指標',
    huifumoren: '恢復預設',
    lazhutuleixing: '蠟燭圖類型',
    qingxuanze: '請選擇',
    quanshixin: '全實心',
    quankongxin: '全空心',
    zhangkongxin: '漲空心',
    diekongxin: '跌空心',
    mianjitu: '面積圖',
    jiagezhouleixing: '價格軸類型',
    xianxingzhou: '線行軸',
    baifenbizhou: '百分比軸',
    duishuzhou: '對數軸',
    zuigaojiaxianshi: '最高價顯示',
    zuidijiaxianshi: '最低價顯示',
    zuixinjiaxianshi: '最新價顯示',
    zhibiaozuixinzhixianshi: '指標最新值顯示',
    daozhizuobiao: "倒置座標",
    wanggexianxianshi: '網格線顯示',
    hfcg:'恢復成功'
    },
    SecurityCenter: {
    fanhui: '返回',
    anquanzhongxin: '安全中心',
    qingshurunicheng: '請輸入暱稱',
    nichengxiugaishibai: '暱稱修改失敗',
    huoquyonghuxinxishibai: '取得用戶資訊失敗',
    xiugaidenglumima: '修改登入密碼',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '取得圖形驗證碼失敗，請重新要求',
    qingshurujiumima: '請輸入舊密碼',
    qingshuruxinmima: '請輸入新密碼',
    qingzaicishuruxinmima: '請再次輸入新密碼',
    qingshurutuxingyanzhengma: '請輸入圖形驗證碼',
    qingshuruyanzhengma: '請輸入驗證碼',
    huoquzhuceshoujihaoshibai: '取得註冊手機號碼失敗',
    qingxianwanchengshoujirenzheng: '請先完成手機認證',
    huoqushoujiyanzhengmashibai: '取得手機驗證碼失敗',
    huoqushoujiyanzhengma: '取得手機驗證碼',
    houchongxinfasong: 'S後重新發送',
    mimacuowu: '密碼錯誤',
    liangcixinmimashurubuyizhiqingjiancha: '兩次新密碼輸入不一致,請檢查',
    xinmimaheyuanmimabunengyiyang: '新密碼和原密碼不能一樣',
    qingshurushoujiyanzhengma: '請輸入手機驗證碼',
    yanzhengmacuowu: '驗證碼錯誤',
    denglumimaxiugaichenggong: '登入密碼修改成功',
    denglumimaxiugaishibai: '登入密碼修改失敗',
    xiugaizijinmima: '修改資金密碼',
    qingshuruzijinmima: '請輸入資金密碼',
    qingzaicishuruzijinmima: '請再次輸入資金密碼',
    liangcizijinmimabuyizhiqingjiancha: '兩次資金密碼不一致,請檢查',
    shezhizijinmimachenggong: '設定資金密碼成功',
    shezhizijinmimashibai: '設定資金密碼失敗',
    xiugaizijinmimachenggong: '修改資金密碼成功',
    xiugaizijinmimashibai: '修改資金密碼失敗',
    youxiangrenzheng: '信箱認證',
    huoquyouxiangyanzhengma: '取得郵箱驗證碼',
    qingshuruyouxiangdizhi: '請輸入郵件地址',
    huoquyouxiangyanzhengmashibai: '取得郵箱驗證碼失敗',
    qingshuruyouxiangyanzhengma: '請輸入信​​箱驗證碼',
    youxiangyanzhengchenggong: '郵箱驗證成功',
    weirenzheng: '未認證',
    queding: '確定',
    anquanshezhiguanli: '安全設定管理',
    denglumima: '登陸密碼',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: '透過設定登入密碼，您將可以使用帳號密碼直接登入',
    xiugai: '修改',
    zijinmima: '資金密碼',
    yongyubaohuzijinanquan: '用於保護資金安全',
    shezhi: '設定',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: '用於登入、提幣、找回密碼、修改安全設定時進行安全驗證',
    yirenzheng: '已認證',
    renzheng: '認證',
    shimingrenzheng: '實名認證',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: '用於買幣、賣幣、提幣、充幣、劃轉時進行安全認證',
    daishenhe: '待審核',
    yibohui: '已駁回',
    yuandenglumima: '原登入密碼',
    xinmima: '新密碼',
    quedingxinmima: '確定新密碼',
    tuxingyanzhengma: '圖形驗證碼',
    yanzhengma: '驗證碼',
    zijinmima: '資金密碼',
    querenzijinmima: '確認資金密碼',
    youxiangdizhi: '郵件地址',
    qingxianwanchengyouxiangrenzheng: '請先完成郵箱認證',
    xiugaitouxiangchenggong: '修改頭像成功',
    xiugaitouxiangshibai: '修改頭像失敗',
    shangchuantouxiangzhinengshijpggeshi: '上傳頭像圖片只能是 JPG 格式!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: '上傳頭像圖片大小不能超過 2MB!',
    },
    verification: {
    fanhui: '返回',
    shimingrenzheng: '實名認證',
    qingshuruxingming: '請輸入姓名',
    qingshuruzhengjianhaoma: '請輸入證件號碼',
    huoquyonghuxinxishibai: '取得用戶資訊失敗',
    wenjiangeshicuowu: '文件格式錯誤',
    wenjianbunengdayu5M: '文件不能大於5M',
    qingtianxiexingming: '請填寫姓名',
    qingtianxiezhengjianhaoma: '請填寫證件號碼',
    qingshangchuanzhengjianzhengmian: '請上傳證件正面',
    qingshangchuanzhengjianfanmian: '請上傳證件反面',
    tijiaochenggong: '提交成功',
    kaiqishenfenrenzheng: '開啟身份認證',
    bohuiyuanyin: '駁回原因',
    qingtianxiejiashizhizhaogongminkahuzhao: '請填寫 駕駛執照/公民卡/護照 信息',
    xingming: '姓名',
    zhengjianhaoma: '證件號碼',
    qingshangchuanjiashizhizhaogongminkahuzhao: '請上傳 駕駛執照/公民卡/護照 信息',
    shangchuanzhengjianzhengmian: '上傳證件正面',
    huotuozhuafangru: '或拖曳放入',
    shangchuanzhengjianfanmian: '上傳證件反面',
    tijiao: '提交',
    },
    invite: {
    yaoqinghaoyoudejiangli: '邀請好友得獎勵',
    yongjiukede: '永久可得',
    jiaoyifanyong: '交易返傭',
    fasongyaoqinggeihaoyou: '發送邀請給好友',
    tongguofenxianglianjieyaoqinghaoyouzhuce: '透過分享連結邀請好友報名 BCFX',
    haoyouzhuce: '好友註冊',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: '好友接受邀請完成註冊並進行交易',
    huodexiangyingbilijiangli: '獲得相應比例獎勵',
    qingsonghuodejiaoyishouxufeijianglifuli: '輕鬆獲得交易手續費獎勵福利',
    wodezhuanshufenxiangfangshi: '我的專屬分享方式',
    yaoqinglianjie: '邀請連結',
    jiazaizhong: '載入中...',
    fuzhilianjie: '複製連結',
    yaoqingma: '邀請碼',
    fuzhiyaoqingma: '複製邀請碼',
    wodeyaoqing: '我的邀請',
    yaoqingrenshu: '邀請人數',
    huodejiangli: '獲得獎勵',
    huodongxize: '活動細則',
    tips1: '平台用户之间的关系是三个层次，如:ABCD用戶组成三个级别的用户，而A是最高级别！',
    tips2: '各級之間的利益關係',
    tips201: '。',
    tips3: '現金獎勵：客戶A可獲得客戶B合約交易手續費的40%+客戶C合約交易手續費的20%+客戶D合約交易手續費10%',
    tips4: '例如： 客戶A有10個一級客戶B，10個二級客戶C，10個三級客戶D，如果每個客戶合約交易100000 USDT',
    tips5: '那麼客戶A可以得到的回報',
    tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 USDT的現金獎勵，即時到帳可提現',
    tips7: '活動如有調整,以 BCFX平台更新為準,最終解釋權歸 BCFX所有。',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '取得圖形驗證碼失敗,請重新要求',
    qingshuruyouxiang: '請輸入信​​箱',
    qingshurutuxingyanzhengma: '請輸入圖形驗證碼',
    qingshuruyanzhengma: '請輸入驗證碼',
    qingshurunindemima: '請輸入您的密碼',
    qingzaicishurunindemima: '請再次輸入您的密碼',
    qingshuruzhifumima: '請輸入支付密碼',
    xuantian: '選填',
    bitian: '必填',
    yonghuzhuce: '用戶註冊',
    huanyingdenglu: '歡迎登入',
    guanfangwangzhan: '官方網站',
    xiangmumingcheng: 'BCFX',
    youxiang: '信箱',
    tuxingyanzhengma: '圖形驗證碼',
    yanzhengma: '驗證碼',
    huoquyanzhengma: '取得驗證碼',
    shezhimima: '設定密碼',
    quedingmima: '確定密碼',
    zhifumima: '支付密碼',
    yaoqingma: '邀請碼',
    zhuce: '註冊',
    yiyouzhanghao: '已有帳號?去',
    denglu: '登入',
    qingshuruyouxiangdizhi: '請輸入郵件地址',
    huoqushibai: '獲取失敗',
    houchongxinfasong: 'S後重新發送',
    liangcishurudemimabuyizhi: '兩次輸入的密碼不一致',
    zhucechenggong: '註冊成功',
    zhuceshibai: '註冊失敗',
    },
    treasure:{
    jichubizhong: '基礎幣種',
    shouyibizhong: '收益幣種',
    wakuangtianshu: '挖礦天數',
    shouyitianshu: '收益天數',
    suodingtianshu: '鎖定天數',
    yichanshengshouyi: '已產生收益',
    yuqishouyi: '預期收益',
    kaishishijian: '開始時間',
    jieshushijian: '結束時間',
    qingshuruninyaosuodingdebizhongshuliang: '請輸入您要鎖定的币种 USDT 數量',
    huoqushibai: '獲取失敗',
    meiyitian: '每 1 天',
    dakai: '打開',
    yuanyuzhoukuangchi: '元宇宙礦池',
    jilu: '記錄',
    kuangchixiangqing: '礦池詳情',
    suo: '鎖',
    yitianchanchu: '1 天產出',
    zuixiaosuodingshijian: '最小鎖定時間',
    nindeUSDT: '您的 USDT',
    tian: '天',
    quanbu: '全部',
    suoUSDTbingkaishishengchan: '鎖 USDT 並開始生產',
    ninquedingyaosuoUSDTkaishishengchanma: '您確定要鎖USDT開始生產嗎?',
    tishi: '提示',
    queding: '確定',
    quxiao: '取消',
    kaishishengchan: '開始生產!',
    yiquxiao: '已取消',
    },
    subscription:{
    rengouliebiao:'申購列表',
    jijiangdaolai:'即將到來',
    jinxingzhong:'進行中',
    quanbu:'全部',
    keshiyonghuobi:"可使用貨幣",
    zongshuliang:'總數量',
    meiyouxianzhi:'沒有限制',
    shengyushuliang:'剩餘數量',
    kaishishijian:'開始時間',
    zhuangtai:'狀態',
    jilu:'記錄',
    zanwujilu:"暫無紀錄",
    leixing:'類型',
    shuliang:'數量',
    goumaijine:'購買金額',
    shijian:'時間',
    goumai:'購買',
    duichu:'不知從何而來',
    jiangli:'獎勵',
    tibi:'提幣',
    huidaomulu:'回到目錄',
    dangqianchiyou:'目前持有',
    keyong:'可用',
    keduihuan:'可兌換',
    shishihangqing:'即時行情',
    yue:'餘額',
    biaoti:'標題',
    fashoushuliang:'發售數量',
    jindu:"進度",
    goumaishuliang:'購買數量',
    ge:'個',
    woyiyuedu:'我已閱讀',
    daibigoumaixieyi:'代幣購買協議',
    qingshurugoumaijine:'請輸入購買金額',
    shifoyaogoumai:'是否要買',
    tishi:'提示',
    queding:'確定',
    quxiao:'取消',
    yjs:'已結束',
    ywc:'已完成',
    sgjg:'申購價格',
    jssj:"結束時間",
    zqsl:"中簽數量",
    zqje:"中簽金額",
    tian:"天",
    shi:'時',
    fen:'分',
    miao:'秒',
    shengou:'申購',
    xmxq:'項目詳情',
    },
    }
    
    