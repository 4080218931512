const config = {
    apiAddress : "https://api.banyunzhe.com",
    tradeAddress : "wss://cdn1.banyunzhe.com",
    quotationAddress : "wss://cdn2.banyunzhe.com",
    tradeSpotAddress : "wss://cdn4.banyunzhe.com",
    quotationSpotAddress : "wss://cdn5.banyunzhe.com",
    tradeOptionsAddress:"wss://cdn8.banyunzhe.com",
    baseApiAddress : 'https://api.banyunzhe.com',
    helpCenter : 'http://03.com',
    klineAccount : 'test0',
    klineAddress : "wss://cdn3.banyunzhe.com",

    appDownloadAndroid : 'http://06.com',
    appDownloadIos : 'http://10.com',
};

export default config
 