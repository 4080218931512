export default {
    header: {
    hello: 'Selam Dünya!',
    changeLanguage: 'dili değiştir',
    Login: 'Giriş yapmak',
    Register: 'kayıt olmak',
    Logout: 'Oturumu kapat',
    shouye: 'ön sayfa',
    bibijiaoyisuo: 'Kripto para ticareti',
    heyuejiaoyisuo: 'sözleşme ticareti',
    licaichanpin: 'Madencilik havuzu',
    rengou: 'Abonelik',
    ruanjianxiazai: 'Yazılım indirme',
    zhongwen: 'Çince',
    yingwen: 'İçinde',
    zichan: 'varlıklar',
    dingdan: 'Emir',
    heyuedingdan: 'sözleşme emri',
    bibidingdan: 'Para siparişi',
    anquanzhongxin: 'Güvenlik Merkezi',
    fanyongyaoqing: 'Komisyon daveti',
    tuichudenglu: 'Oturumu kapat',
    gupiaojiaoyi: 'hisse senedi ticareti',
    waihuijiaoyi: 'Forex ticareti',
    qiquanjiaoyi:'opsiyon ticareti',
    gupiaodingdan: 'stok siparişi',
    waihuidingdan: 'Forex siparişleri',
    qiquandingdan: 'seçenekler sırası',
    jiaoyidingdan:'ticaret emri',
    daolizhongxin:'Ajans Merkezi',
    huodong:'Etkinlik',
    },
    home: {
    huanyinglaidao: 'hoş geldiniz',
    pingtaimingcheng: 'Burada',
    mengxiangqihang: 'Hayaller yelken açtı',
    yijiankaiqishuzihualvcheng: 'Tek tıklamayla dijital yolculuğunuza başlayın',
    liaojiegengduo: 'daha fazla bilgi edin',
    chongzhi: 'tamamlamak',
    jiaoyi: 'ticaret',
    remenbang: 'Popüler liste',
    zhangfubang: 'Kazanan listesi',
    diefubang: 'Kaybeden listesi',
    chengjiaoliang: 'Hacim',
    zanwushuju: 'Henüz veri yok',
    jiayidui: 'ticaret çifti',
    zuixinjiage: 'son fiyat',
    zhangdie24h: '24 saat yükseliş ve düşüş',
    caozuo: 'işletmek',
    jiaoyi: 'ticaret',
    gengduo: 'Daha',
    suishisuidikaiqijiaoyi: 'İstediğiniz zaman, istediğiniz yerde ticarete başlayın',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'İster Uygulama ister Web olsun, işleminize hızlı bir şekilde başlayabilirsiniz.',
    saomaxiazaiApp: 'APP\'yi indirmek için QR kodunu tarayın',
    iosheAndroid: 'iOS ve Android',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'Güvenilir ve emniyetli kripto para borsanız',
    tishi1: 'Rezerv Sertifikası',
    xiangqing1: 'Tüm kullanıcı varlıklarını en az 1:1 oranında tutacağımıza söz veriyoruz',
    tishi2: 'En iyi işlem oranları',
    xiangqing2: 'Tercihli işlem oranları, rekabetçi VIP hakları ve en kaliteli hizmetlerin keyfini çıkarın',
    tishi3: 'Güvenebileceğiniz güvenlik',
    xiangqing3: 'Gelişmiş güvenlik önlemlerimiz ve SAFU fonlarımız dijital varlıklarınızı tüm risklerden korur',
    tishi4: '24*7 saat müşteri hizmetleri desteği',
    xiangqing4: '24*7 saat, tam zamanlı çalışma modu, ilgili sorularınıza en kısa sürede cevap verin',
    tishi5: 'Sağlam ve güvenli',
    xiangqing5: 'Dünyanın önde gelen borsası, varlıklarınız için banka düzeyinde güvenlik koruması sağlıyor',
    tishi6: 'SSS',
    xiangqing6: 'Belirli özelliklerin ayrıntılı açıklamaları için SSS\'ye göz atın',
    },
    footer: {
    bangzhuzhongxin: 'Yardım Merkezi',
    dianhuazixun: 'Telefonla danışma',
    dianhuahegongzuoshijian: '1670-9756 (hafta içi 10:00~19:00/hafta sonları ve tatil günlerinde kapalı)',
    liaotianzixun: '1:1 sohbet danışmanlığı',
    keketuozixun: 'Cocotok Danışmanlık',
    zaixianbangzhu: 'Çevrimiçi yardım',
    h24kaifang: '(24 saat açık)',
    lixianbangzhu: 'Çevrimdışı yardım',
    gongsidizhi: '1F, Yongsan Purgio Zirvesi, 69 Hangang-gil, Yongsan-gu, Seul',
    gongzuoshijian: '(Hafta içi 10:00~18:00 / hafta sonu ve tatil günlerinde kapalıdır)',
    shangshizixun: 'Listeleme danışmanlığı',
    hezuozixun: 'İşbirliği danışmanlığı',
    gongsi: 'şirket',
    lianxiwomwn: 'Bize Ulaşın',
    zhidao: 'rehber',
    yonghuzhichi: 'Kullanıcı desteği',
    zhanghuzhinan: 'Hesap Rehberi-Para Yatırma/Çekme',
    ruanjianxiazai: 'Yazılım indirme',
    zhengce: 'politika',
    fuwuyuyinsitiaokuan: 'Hizmet Şartları ve Gizlilik',
    },
    login: {
    yonghudenglu: 'Kullanıcı girişi',
    huanyingdenglu: 'Giriş yapmaya hoş geldiniz',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: 'resmi web sitesi',
    youxiang: 'Posta',
    qingshuruyouxiang: 'Lütfen e-postanızı girin',
    mima: 'şifre',
    qingshurumima: 'Lütfen şifreyi girin',
    wangjimima: 'şifreyi mi unuttun?',
    denglu: 'Giriş yapmak',
    haimeiyouzhanghao: 'Henüz bir hesabınız yok mu? Gitmek',
    zhuce: 'kayıt olmak',
    dengluchenggong: 'Giriş başarılı',
    denglushibai: 'giriş başarısız oldu',
    qingshuruyonghuminghemima: 'Lütfen kullanıcı adınızı ve şifrenizi girin',
    mimachangdubunengxiaoyuliuwei: 'Şifre uzunluğu 6 karakterden az olamaz',
    youxiangrenzheng: 'E-posta doğrulaması',
    youxiangdizhi: 'E-posta adresi',
    qingshuruyouxiangdizhi: 'Lütfen e-posta adresini girin',
    tuxingyanzhengma: 'Grafik doğrulama kodu',
    qingshurutuxingyanzhengma: 'Lütfen grafik doğrulama kodunu girin',
    yanzhengma: 'Doğrulama kodu',
    qingshuruyouxiangyanzhengma: 'Lütfen e-posta doğrulama kodunu girin',
    huoquyouxiangyanzhengma: 'E-posta doğrulama kodunu alın',
    queding: 'Elbette',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Grafik doğrulama kodu alınamadı, lütfen tekrar talep edin',
    qingxianwanchengyouxiangrenzheng: 'Lütfen önce e-posta doğrulamasını tamamlayın',
    huoquyouxiangyanzhengmashibai: 'E-posta doğrulama kodu alınamadı,',
    houchongxinfasong: 'S\'den sonra yeniden gönder',
    youxiangyanzhengchenggong: 'E-posta doğrulaması başarılı',
    yanzhengmacuowu: 'Doğrulama kodu hatası',
    xiugaidenglumima: 'Giriş şifresini değiştir',
    xinmima: 'Yeni Şifre',
    quedingxinmima: 'Yeni şifreyi onayla',
    qingshuruxinmima: 'Lütfen yeni şifreyi girin',
    qingzaicishuruxinmima: 'Lütfen yeni şifreyi tekrar girin',
    liangcimimashurubuyizhi: 'İki şifre girişi tutarsız',
    mimaxiugaichenggong: 'Şifre başarıyla değiştirildi',
    xiugaimimashibai: 'Şifre değiştirilemedi',
    },
    trade: {
    hangqingheyue: 'sözleşme',
    hangqingzuixinjia: 'son fiyat',
    hangqingzhangdiefu: 'Arttırma veya azaltma',
    hangqingzuigao24h: '24 saatte en yüksek',
    hangqingzuidi24h: 'minimum 24 saat',
    hangqingchengjiaoliang24h: '24 saatlik işlem hacmi',
    hangqingchengjiabishu: 'İşlem sayısı',
    zhanghuquanyi: 'Hesap hakları',
    zhanghukeyongshouyi: 'Mevcut avantajlar',
    hangqingweituoliebiao: 'Sipariş listesi',
    hangqingjiage: 'fiyat',
    hangqingshuliang: 'miktar',
    hangqingzuixinchengjiao: 'son işlem',
    hangqingjiage1: 'fiyat',
    hangqingshuliang1: 'miktar',
    hangqingshijian: 'zaman',
    dingdanchiyoucangwei: 'Bir pozisyon tutun',
    dingdandangqianweituo: 'Mevcut sipariş',
    dingdanchengjiaojilu: 'İşlem kaydı',
    dingdantiaojiandan: 'koşullu emir',
    chicangheyue: 'sözleşme',
    chicangkaicangjunjia: 'ortalama açılış fiyatı',
    chicangfudongyingkui: 'Değişken kar ve zarar (USDT)',
    chicanggangganbeishu: 'Birden fazla yararlanın',
    chicangchicangliang: 'Pozisyon(lar)',
    chicangduokong: 'Uzun/Kısa',
    chicangduo: 'birçok',
    chicangkong: 'hükümsüz',
    chicangzhiyingjiage: 'Kâr fiyatını al',
    chicangzhisunjiage: 'Zararı durdurma fiyatı',
    chicangqiangpingyugujiage: 'Tahmini tasfiye fiyatı',
    chicangcaozuo: 'işletmek',
    chicangzhiyingzhisun: 'Kâr alın ve zararı durdurun',
    chicangpingcang: 'Konumu kapat',
    chicangshouxufei: 'işlem ücreti',
    weituoheyue: 'sözleşme',
    weituoshuliang: 'Sipariş miktarı',
    weituojiage: 'Komisyon fiyatı',
    weituofangxiang: 'yön',
    weituogangganbeishu: 'Birden fazla yararlanın',
    weituochengjiaoshuliang: 'İşlem miktarı',
    weituoshouxufei: 'işlem ücreti',
    weituochengjiaojunjia: 'ortalama işlem fiyatı',
    weituoshouyi: 'gelir',
    weituozhuangtai: 'durum',
    weituochengjiaoshijian: 'İşlem süresi',
    weituocaozuo: 'işletmek',
    weituochedan: 'Siparişi iptal et',
    weituotijiaoshibai: 'Gönderim başarısız oldu',
    weituozhilingtijiao: 'Talimat gönderimi',
    weituoyitijiao: 'Gönderildi',
    weituobufenchengjiao: 'Kısmi anlaşma',
    weituobufenchengjiaoyichedan: 'Bazı siparişler iptal edildi',
    weituowanquanchengjiao: 'Anlaşmayı tamamla',
    weituowanquanchedan: 'Siparişi tamamen iptal et',
    weituochedanzhong: 'Sipariş iptal ediliyor',
    weituokaiduo: 'Uzun süre aç',
    weituopingkong: 'düz gökyüzü',
    weituokaikong: 'Kısa aç',
    weituopingduo: 'Pinduo',
    chengjiaoheyue: 'sözleşme',
    chengjiaofangxiang: 'yön',
    chengjiaoshuliang: 'İşlem miktarı',
    chengjiaojiage: 'işlem fiyatı',
    chengjiaoshijian: 'İşlem süresi',
    chengjiaopingcangyingkui: 'Pozisyon kapanış karı ve zararı',
    chengjiaoshouxufei: 'İşlem ücreti',
    chengjiaokaiduo: 'Uzun süre aç',
    chengjiaopingkong: 'düz gökyüzü',
    chengjiaokaikong: 'Kısa aç',
    chengjiaopingduo: 'Pinduo',
    tiaojianzengjiatiaojian: 'Koşul ekle',
    tiaojianzantingquanbu: 'hepsini duraklat',
    tiaojianqidongquanbu: 'Tümünü başlat',
    tiaojianshanchuquanbu: 'Tümünü sil',
    tiaojianheyue: 'sözleşme',
    tiaojianweituoshuliang: 'Sipariş miktarı',
    tiaojianfangxiang: 'yön',
    tiaojiantiaojianleixing: 'koşul türü',
    tiaojianjiageleixing: 'fiyat türü',
    tiaojianbijiaofuhao: 'Karşılaştırma sembolü',
    tiaojiantiaojian: 'durum',
    tiaojianzhuangtai: 'durum',
    tiaojianchuangjianshijian: 'yaratılış zamanı',
    tiaojiancaozuo: 'işletmek',
    tiaojianxiugai: 'Tekrar düzeltme yapmak',
    tiaojianqidong: 'başlatmak',
    tiaojianzanting: 'duraklatma',
    tiaojianshanchu: 'silmek',
    tiaojianlijixiadan: 'Şimdi sipariş ver',
    tiaojiankaiduo: 'Uzun süre aç',
    tiaojianpingkong: 'düz gökyüzü',
    tiaojiankaikong: 'Kısa aç',
    tiaojianpingduo: 'Pinduo',
    tiaojianjiagetiaojian: 'fiyat koşulları',
    tiaojianshijiantiaojian: 'zaman durumu',
    tiaojianzuixinjia: 'son fiyat',
    tiaojianByijia: 'tek fiyat satın al',
    tiaojianSyijia: 'Tek fiyata sat',
    tiaojianzanting: 'duraklatma',
    tiaojianyunxing: 'koşmak',
    tiaojianyiwancheng: 'Tamamlanmış',
    tiaojianshibai: 'hata',
    tiaojianyishanchu: 'Silindi',
    tiaojianshoudongchufawancheng: 'Manuel tetikleme tamamlandı',
    tiaojianshoudongchufashibai: 'Manuel tetikleme başarısız oldu',
    xiadankaicang: 'Pozisyon aç',
    xiadanpingcang: 'Konumu kapat',
    xiadanweituoleixing: 'Temsilci türü',
    xiadanqingxuanze: 'Lütfen seçin',
    xiadanshijia: 'piyasa fiyatı',
    xiadanxianjia: 'fiyat sınırı',
    xiadanjiage: 'fiyat',
    xiadanqingshurujiage: 'Lütfen fiyatı girin',
    xiadanshuliang: 'miktar',
    xiadanqingshurushuliang: 'Lütfen miktarı girin',
    xiadanzhang: 'açık',
    xiadankekaishuliang: 'Mevcut miktar',
    xiadanqingxianxuanzechicangdan: 'Lütfen önce konum sırasını seçin',
    xiadankepingshuliang: 'Düzeltilebilecek miktar',
    xiadansuoxubaozhengjin: 'Gerekli marj:',
    xiadanshijiazhiyingzhisunyushe: 'Piyasa fiyatı kar alma ve zararı durdurma ön ayarı',
    xiadanyujiqiangpingjiage: 'Tahmini tasfiye fiyatı:',
    xiadanqiangpingjiagetishi: 'Bu beklenen tasfiye fiyatı, toplam varlıklara göre dinamik olarak hesaplanır. Bu fiyat yalnızca referans amaçlıdır ve nihai tasfiye olarak kullanılmayacaktır.',
    xiadanzuoduo: 'Uzun git',
    xiadanzuokong: 'kısa',
    xiadanpingcang: 'Konumu kapat',
    zhanghuheyuezichan: 'Sözleşme varlıkları',
    zhanghuchongbi: 'Para yatırma',
    zhanghutibi: 'Para çekme',
    zhanghuhuazhuan: 'aktarma',
    zhanghuzhanghuquanyi: 'Hesap hakları',
    zhanghufudongyingkui: 'değişken kar ve zarar',
    zhanghucangweibaozhengjin: 'Konum marjı',
    zhanghudongjiebaozhengjin: 'Marjı dondur',
    zhanghukeyongquanyi: 'Mevcut avantajlar',
    zhanghubaozhengjinshiyonglv: 'Marj kullanım oranı',
    hangqingheyuexinxi: 'Sözleşme bilgileri',
    hangqingheyuemingcheng: 'Sözleşme adı',
    hangqingjijiahuobi: 'Teklif para birimi',
    hangqingheyuedaxiao: 'Sözleşme boyutu',
    hangqingzuixiaojiagebodong: 'minimum fiyat dalgalanması',
    dialogleverRateganggantiaozheng: 'Kaldıraç ayarı',
    dialogleverRatequeding: 'Elbette',
    dialogtransferzichanhuazhuan: 'Varlık transferi',
    dialogtransferbizhong: 'Para birimi',
    dialogtransfercong: 'itibaren',
    dialogtransferdao: 'varmak',
    dialogtransfershuliang: 'miktar',
    dialogtransferqingshuruhuazhuanshuliang: 'Lütfen transfer tutarını girin',
    dialogtransferquanbu: 'Tümü',
    dialogtransferkeyong: 'Mevcut',
    dialogtransferhuazhuan: 'aktarma',
    dialogtransferjichuzhanghu: 'Temel hesap',
    dialogtransferheyuezhanghu: 'Sözleşme hesabı',
    dialogfitlosszhiyingzhisun: 'Kâr alın ve zararı durdurun',
    dialogfitlossheyu: 'sözleşme',
    dialogfitlossduokong: 'Uzun/Kısa',
    dialogfitlosschichangjunjia: 'ortalama pozisyon fiyatı',
    dialogfitlossshuliang: 'miktar',
    dialogfitlosszuixinjia: 'son fiyat',
    dialogfitlosszhiyingdianshu: 'Kar puanları alın',
    dialogfitlosszhisundianshu: 'Kayıp puanlarını durdur',
    dialogfitlossdianshu: 'Gol',
    dialogfitlosszhiyingjiage: 'Kâr fiyatını al',
    dialogfitlosszhisunjiage: 'Zararı durdurma fiyatı',
    dialogfitlossjiage: 'fiyat',
    dialogfitlossyuqishouyi: 'beklenen getiri',
    dialogfitlossyuqikuisun: 'beklenen kayıp',
    dialogfitlosstishi1: 'İpucu: Mevcut sözleşmedeki minimum dalgalanma',
    dialogfitlosstishi2: ', kar almayı iptal etmek ve zararı durdurmak için lütfen fiyatı 0 olarak ayarlayın.',
    dialogfitlossqueding: 'Elbette',
    dialogfitlossduo: 'birçok',
    dialogfitlosskong: 'hükümsüz',
    dialogconditionzengjiayuntiaojiandan: 'Bulut durum sayfası ekle',
    dialogconditiontiaojianleixing: 'Durum türü:',
    dialogconditionjiagetiaojiandan: 'Fiyat şartları sayfası',
    dialogconditionshijiantiaojiandan: 'Zaman koşulu sayfası',
    dialogconditionjiageleixing: 'Fiyat türü:',
    dialogconditionzuixinjia: 'son fiyat',
    dialogconditionByijia: 'tek fiyat satın al',
    dialogconditionSyijia: 'Tek fiyata sat',
    dialogconditiontiaojianshezhi: 'Durum ayarları:',
    dialogconditionjiageshezhi: 'Fiyat ayarları:',
    dialogconditionqingshurujiage: 'Lütfen fiyatı girin',
    dialogconditionshijianshezhi: 'Zaman ayarı:',
    dialogconditionjiaoyileixing: 'İşlem türü:',
    dialogconditionkaiduo: 'Uzun süre aç',
    dialogconditionpingkong: 'düz gökyüzü',
    dialogconditionkaikong: 'Kısa aç',
    dialogconditionpingduo: 'Pinduo',
    dialogconditiondingdanshuliang: 'Sipariş miktarı:',
    dialogconditionqingshurudingdanshuliang: 'Lütfen sipariş miktarını giriniz',
    dialogconditiondingdanshixiao: 'Sipariş süresi:',
    dialogconditionyongjiuyouxia: 'Kalıcı olarak geçerli',
    dialogconditiontijiao: 'göndermek',
    dialogAutoProfitLosszhiyingzhisun: 'Kâr alın ve zararı durdurun',
    dialogAutoProfitLossmingcheng: 'isim',
    dialogAutoProfitLosszhiyingdian: 'Kâr puanı al',
    dialogAutoProfitLosszhisundian: 'Kayıp noktasını durdur',
    dialogAutoProfitLosschongzhi: 'sıfırlama',
    dialogAutoProfitLoss: 'göndermek',
    dialogCloseOrderpingcang: 'Konumu kapat',
    dialogCloseOrderweituoleixing: 'Temsilci türü',
    dialogCloseOrderqingxuanze: 'Lütfen seçin',
    dialogCloseOrdershijia: 'piyasa fiyatı',
    dialogCloseOrderxianjia: 'fiyat sınırı',
    dialogCloseOrderjiage: 'fiyat',
    dialogCloseOrderqingshurujiage: 'Lütfen fiyatı girin',
    dialogCloseOrdershuliang: 'miktar',
    dialogCloseOrderqingshurushuliang: 'Lütfen miktarı girin',
    dialogCloseOrderzhang: 'açık',
    dialogCloseOrderkepingshuliang: 'Düzeltilebilecek miktar',
    dialogCloseOrderqueding: 'Elbette',
    jsMessageResgaiheyuegangganxiugaichenggong: 'Sözleşme kaldıracı başarıyla değiştirildi',
    jsMessageResgaiheuyegangganxiugaishibai: 'Sözleşme kaldıracı değişikliği başarısız oldu',
    jsMessageReskaicangchenggong: 'Pozisyon başarıyla açıldı',
    jsMessageReskaicangshibai: 'Pozisyon açılışı başarısız oldu:',
    jsMessageReskaicang1: 'Pozisyon açmak yasaktır',
    jsMessageReskaicang2: 'Sözleşme mevcut değil',
    jsMessageReskaicang3: 'Açık pozisyon sayısı üst sınırı aşıyor',
    jsMessageReskaicang4: 'Fiyat 0',
    jsMessageReskaicang5: 'Pozisyon açmak için yeterli marj yok',
    jsMessageRespingcangchenggong: 'Pozisyon başarıyla kapatıldı',
    jsMessageRespingcangshibai: 'Pozisyon kapatılamadı:',
    jsMessageRespingcang1: 'Sözleşme mevcut değil',
    jsMessageRespingcang2: 'Pozisyon makbuzu mevcut değil',
    jsMessageRespingcang3: 'Yetersiz miktar',
    jsMessageReszijinhuazhuanchenggong: 'Fon transferi başarılı',
    jsMessageReszijinhuazhuanshibai: 'Fon aktarımı başarısız oldu',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'Pozisyon emri kârı durdurma ve zararı durdurma başarıyla ayarlandı',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'Pozisyon emirleri için kârı durdurma ve zararı durdurma ayarları başarısız oldu:',
    jsMessageResweituodanchedanchenggong: 'Sipariş başarıyla iptal edildi',
    jsMessageResweituodanchedanshibai: 'Sipariş iptali başarısız oldu',
    jsMessageRestiaojiandantianjiachenggong: 'Koşullu sıralama başarıyla eklendi',
    jsMessageResheyuebucunzai: 'Sözleşme mevcut değil',
    jsMessageResjiageshezhicuowu: 'Fiyat belirleme hatası',
    jsMessageResshijianshezhicuowu: 'Zaman ayarlama hatası',
    jsMessageResneibucuowu: 'Dahili hata',
    jsMessageRestiaojiandantianjiashibai: 'Koşullu sıra eklenemedi:',
    jsMessageReszhiyingzhisunshezhichenggong: 'Kar alma ve zararı durdurma başarıyla ayarlandı',
    jsconfirmquedingchedanma: 'Siparişi iptal etmek istediğinizden emin misiniz?',
    jsconfirmtishi: 'ipucu',
    jsconfirmqueding: 'Elbette',
    jsconfirmquxiao: 'İptal etmek',
    jsMessageqingxiandenglu: 'Lütfen önce giriş yapın!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'Bu sözleşmede açık pozisyonlar bulunmaktadır ve kaldıraç oranı değiştirilemez.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'Bu sözleşmenin bir yetkisi vardır ve kaldıraç değiştirilemez.',
    jsMessageqingshuruzhengquedeshuliang: 'Lütfen doğru miktarı girin!',
    jsMessageqingshuruzhengquedejiage: 'Lütfen doğru fiyatı giriniz!',
    jsMessageqingxianxuanzechicang: 'Lütfen önce bir pozisyon seçin!',
    jsMessageqingshuruhuazhuanshuliang: 'Lütfen transfer tutarını giriniz!',
    jsMessageqingxianxuanzeheyue: 'Lütfen önce bir sözleşme seçin!',
    jsMessageqingshurutiaojiandanshuliang: 'Lütfen koşullu sipariş miktarını giriniz!',
    jsMessageqingshurutiaojiandandejiage: 'Lütfen koşullu siparişin fiyatını giriniz!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'Lütfen koşullu emir yürütme süresini girin!',
    emptytext: 'Henüz veri yok',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'Kâr alma ve zararı durdurma pipleri yanlış ayarlanmış',
    jsMessageReschicangdanbucunzai: 'Pozisyon makbuzu mevcut değil',
    zxkcje:'Minimum açılış tutarı',
    },
    commisson:{
    dlzx:'Ajans Merkezi',
    sjzl:'Verilere genel bakış',
    quanbu:"Tümü",
    jinri:'Bugün',
    zuori:'Dün',
    ri:'gün',
    wdfy:'Benim indirimim',
    wdsjfy:'Üçüncü seviye indirimim',
    dengji:'seviye',
    yqrs:'Davet edilen kişi sayısı',
    tdrs:'Takım büyüklüğü',
    yqxq:'Davetiye ayrıntıları',
    yhm:"kullanıcı adı",
    cjsj:'yaratılış zamanı',
    fyxq:'İndirim ayrıntıları',
    zyj:'toplam komisyon',
    dryj:'Tek günlük komisyon',
    fyje:'İndirim tutarı',
    
    },
    assets: {
    zongzichan: 'toplam varlıklar',
    chongbi: 'Para yatırma',
    tibi: 'Para çekme',
    huazhuan: 'aktarma',
    fabizhanghu: 'Temel hesap',
    heyuezhanghu: 'Sözleşme hesabı',
    jiayiliushui: 'işlem akışı',
    dialogzichanhuazhuan: 'Varlık transferi',
    cong: 'itibaren',
    dao: 'varmak',
    shuliang: 'miktar',
    qingshuruhuazhuanshuliang: 'Lütfen transfer tutarını girin',
    quanbu: 'Tümü',
    keyong: 'Mevcut',
    huanzhuan: 'aktarma',
    zijinhuazhuanchenggong: 'Fon transferi başarılı',
    zijinhuazhuanshibai: 'Fon aktarımı başarısız oldu',
    bibizhanghu: 'Para hesabı',
    qiquanzhanghu:'seçenekler hesabı',
    zczl:'Varlığa genel bakış',
    zzczh:'Toplam varlık indirimi',
    ztzc:"Geçiş halindeki varlıklar",
    djzc:'Varlıkları dondur',
    zyzc:'Rehin verilen varlıklar',
    cwzc:'Varlıkları konumlandırın',
    jyzh:'ticaret hesabı',
    bizhong:'Para birimi',
    dongjie:'dondurmak',
    lirun:"kâr",
    hyzh:"Sözleşme hesabı",
    },
    basicsAccountRecord: {
    fanhui: 'geri dönmek',
    fabizhanghuliushui: 'Ticaret hesabı akışı',
    fabijiaoyiliushuileixing: 'İşlem akışı türü',
    qingxuanze: 'Lütfen seçin',
    chaxunriqi: 'Sorgu tarihi',
    liushuileixing: 'Boru hattı türü',
    shijian: 'zaman',
    biandongjine: 'Tutarı değiştir',
    yue: 'Denge',
    quanbu: 'Tümü',
    Sellbi: 'para satmak',
    Buybi: 'para satın al',
    tibi: 'Para çekme',
    chongbi: 'Para yatırma',
    fabizhuanheyue: 'Temel transfer sözleşmesi',
    heyuezhuanfabi: 'Temel sözleşme',
    zhuanzhang: 'aktarma',
    fabihuazhuandaoheyue: 'Sözleşmeye temel transfer',
    heyuehuazhuandaofabi: 'Üsse sözleşme aktarımı',
    goumaijijin: 'Madencilik havuzu satın al',
    jijinshuhui: 'Madencilik havuzunun kullanımı',
    jijinshouyi: 'Madencilik havuzu itfa geliri',
    fabizhuanqihuo: 'Vadeli işlemlere temel dönüşüm',
    qihuozhuanfabi: 'Vadeli işlem transfer esası',
    fabizhuanganggu: 'Hong Kong hisse senetlerine temel transfer',
    gangguzhuanfabi: 'Hong Kong hisse transferinin temelleri',
    fabizhuanbibi: 'Temel transfer para birimi',
    bibizhuanfabi: 'Para biriminden para birimine transferin temelleri',
    goumaiSGCB: 'ICO satın al',
    chushouSGCB: 'ICO\'yu satmak',
    huoqushibai: 'Alınamadı',
    zanwushuju: 'Henüz veri yok',
    },
    contractAccountRecord: {
    fanhui: 'geri dönmek',
    heyuezhanghuliushui: 'Sözleşme hesap akışı',
    heyuejiaoyiliushuileixing: 'Sözleşme işlem akışı türü',
    qingxuanze: 'Lütfen seçin',
    chaxunriqi: 'Sorgu tarihi',
    liushuileixing: 'Boru hattı türü',
    shijian: 'zaman',
    biandongjine: 'Tutarı değiştir',
    yue: 'Denge',
    quanbu: 'Tümü',
    kaicangshouxufei: 'Açılış ücreti',
    pingcangshouxufei: 'Kapanış ücreti',
    pingcangyingkui: 'Pozisyon kapanış karı ve zararı',
    fabizhuanheyue: 'Temel transfer sözleşmesi',
    heyuezhuanfabi: 'Temel sözleşme',
    zhuanzhang: 'aktarma',
    kaicangyongjin: 'Açılış komisyonu',
    pingcangyongjin: 'Kapanış komisyonu',
    huoqushibai: 'Alınamadı',
    zanwushuju: 'Henüz veri yok',
    },
    recharge: {
    zanwushuju: 'Henüz veri yok',
    shijian: 'zaman',
    leixing: 'tip',
    shuliang: 'miktar',
    zhuangtai: 'durum',
    caozuo: 'işletmek',
    chakanxiangqing: 'ayrıntıları kontrol et',
    huoqushibai: 'Alınamadı',
    dialogtitle: 'Detaylar',
    chongbi: 'Para yatırma',
    fanhui: 'geri dönmek',
    lianmingcheng: 'Zincir adı',
    fuzhilianjie: 'Adresi kopyala',
    tishixinxi1: '• Lütfen TRC_USDT veya ERC_USDT olmayan varlıkları yukarıdaki adrese yatırmayın, aksi takdirde varlıklar geri alınmayacaktır.',
    tishixinxi2: '• Yukarıdaki adrese yeniden yükleme yaptıktan sonra, tüm ağ düğümünün onayı gereklidir. Hesap, 1 ağ onayından sonra alınır ve para birimi, 2 ağ onayından sonra çekilebilir.',
    tishixinxi3: '• Minimum para yatırma tutarı: 50USDT, minimum tutarın altındaki para yatırma işlemleri kredilendirilmeyecek ve iade edilemez',
    tishixinxi4: '• Yükleme adresiniz sık sık değişmeyecektir ve herhangi bir değişiklik olması durumunda tekrar tekrar yükleme yapılabilir; web sitesi duyuruları veya e-postalar aracılığıyla sizi bilgilendirmek için elimizden geleni yapacağız;',
    tishixinxi5: '• Bilgilerin tahrif edilmesini veya sızdırılmasını önlemek için lütfen bilgisayarınızın ve tarayıcınızın güvenli olduğundan emin olun.',
    putongchongbi: 'Olağan depozito',
    jiaoyichenggong: 'İşlem başarılı',
    jiaoyishibai: 'İşlem başarısız oldu',
    OMNIweihuzhong: 'OMNI zinciri bakımda, lütfen TRC20\'yi seçin',
    },
    withdraw: {
    fanhui: 'geri dönmek',
    zanwushuju: 'Henüz veri yok',
    tibi: 'Para çekme',
    huoqushibai: 'Alınamadı',
    OMNIweihuzhong: 'OMNI zinciri bakımda, lütfen TRC20\'yi seçin',
    shijian: 'zaman',
    leixing: 'tip',
    shuliang: 'miktar',
    zhuangtai: 'durum',
    caozuo: 'işletmek',
    chakanxiangqing: 'ayrıntıları kontrol et',
    putongtibi: 'Olağan para çekme',
    dialogtitle: 'Detaylar',
    dialogtitlewithdraw: 'Para çekme',
    houchongxinfasong: 'S\'den sonra yeniden gönder',
    huoquyouxiangyanzhengma: 'E-posta doğrulama kodunu alın',
    lianmingcheng: 'Zincir adı',
    tibidizhi: 'Para çekme adresi',
    qingshurutibidizhi: 'Lütfen para çekme adresini girin',
    tibishuliang: 'Çekilen coin miktarı',
    shouxufei: 'işlem ücreti',
    qingshurutibishuliang: 'Lütfen çekim tutarını girin',
    quanbu: 'Tümü',
    keyong: 'Mevcut',
    daozhangshuliang: 'Varış miktarı',
    zuixiaotibishuliang: 'Minimum para çekme tutarı:',
    tishixinxi: 'Paranın güvenliğini sağlamak için, hesap güvenlik politikanız değiştiğinde veya şifreniz değiştirildiğinde, para çekme işlemini manuel olarak inceleyeceğiz. Lütfen sabırlı olun ve personelin sizinle telefon veya e-posta yoluyla iletişime geçmesini bekleyin. Verilerin tahrif edilmesini veya sızdırılmasını önlemek için lütfen bilgisayarınızın ve tarayıcınızın güvenli olduğundan emin olun.',
    tuxingyanzhengma: 'Grafik doğrulama kodu',
    qingshurutuxingyanzhengma: 'Lütfen grafik doğrulama kodunu girin',
    youxiangyanzhengma: 'E-posta doğrulama kodu',
    queding: 'Elbette',
    tijiaozhong: 'Gönderiliyor',
    yijujue: 'Reddedilmiş',
    yitongguo: 'Geçti',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Grafik doğrulama kodu alınamadı, lütfen tekrar talep edin',
    huoquyouxiangyanzhengmashibai: 'E-posta doğrulama kodu alınamadı,',
    qingxianwanchengyouxiangrenzheng: 'Lütfen önce e-posta doğrulamasını tamamlayın',
    huoquyouxiangshibaiqingchongxinqingqiu: 'E-posta adresi alınamadı, lütfen tekrar istekte bulunun',
    qingshuruyouxiangyanzhengma: 'Lütfen e-posta doğrulama kodunu girin',
    youxiangyanzhengmacuowu: 'E-posta doğrulama kodu yanlış,',
    qxsmrz:'Lütfen önce gerçek adınızı doğrulayın',
    tishi:'ipucu',
    queding:'Elbette',
    },
    contractTransactionRecord: {
    fanhui: 'geri dönmek',
    zanwushuju: 'Henüz veri yok',
    heyuedingdan: 'sözleşme emri',
    qingxuanze: 'Lütfen seçin',
    quanbu: 'Tümü',
    kaiduo: 'Uzun süre aç',
    pingkong: 'düz gökyüzü',
    kaikong: 'Kısa aç',
    pingduo: 'Pinduo',
    xianjiaweituo: 'limit emri',
    shijiaweituo: 'piyasa emri',
    suoyouzhuangtai: 'Tüm durum',
    tijiaoshibai: 'Gönderim başarısız oldu',
    zhunbeitijiao: 'göndermeye hazır',
    yitijiao: 'Gönderildi',
    bufentijiao: 'Kısmi anlaşma',
    bufentijiaoyichedan: 'Bazı siparişler iptal edildi',
    quanbuchengjiao: 'Tüm İşlemler',
    yichedan: 'Sipariş iptal edildi',
    huoqushibai: 'Alınamadı',
    heyuedaima: 'Sözleşme kodu',
    ganggan: 'kaldıraç',
    xiadanshijian: 'Sipariş zamanı',
    chengjiaoshijian: 'İşlem süresi',
    fangxiang: 'yön',
    baojialeixing: 'Teklif türü',
    weituoliang: 'Komisyon miktarı (adet)',
    weituojia: 'Sipariş fiyatı (USDT)',
    chengjiaoliang: 'İşlem hacmi (biletler)',
    chengjiaojunjia: 'Ortalama işlem fiyatı (USDT)',
    yingkui: 'Kâr ve Zarar(USDT)',
    shouxufei: 'İşleme ücreti(USDT)',
    zhuangtai: 'durum',
    gupiaodingdan: 'stok siparişi',
    waihuidingdan: 'Forex siparişleri',
    gupiaodaima: 'Stok kodu',
    waihuidaima: 'Forex kodu',
    },
    kLine: {
    zhibiao: 'dizin',
    shezhi: 'kurmak',
    quanping: 'Tam ekran',
    tuichuquanping: 'Tam ekrandan çık',
    jishuzhibiao: 'Teknik göstergeler',
    futuzhibiao: 'Alt resim göstergesi',
    zhutuzhibiao: 'Ana grafik göstergesi',
    huifumoren: 'Varsayılanı geri yükle',
    lazhutuleixing: 'Şamdan Çeşitleri',
    qingxuanze: 'Lütfen seçin',
    quanshixin: 'Hepsi sağlam',
    quankongxin: 'Tamamen içi boş',
    zhangkongxin: 'içi boş yükselen',
    diekongxin: 'içi boş düşmek',
    mianjitu: 'alan grafiği',
    jiagezhouleixing: 'Fiyat ekseni türü',
    xianxingzhou: 'çizgi ekseni',
    baifenbizhou: 'Yüzde ekseni',
    duishuzhou: 'Logaritmik eksen',
    zuigaojiaxianshi: 'En yüksek fiyat gösterimi',
    zuidijiaxianshi: 'En düşük fiyat ekranı',
    zuixinjiaxianshi: 'Son fiyat ekranı',
    zhibiaozuixinzhixianshi: 'Göstergenin en son değeri görüntülenir',
    daozhizuobiao: "ters koordinatlar",
    wanggexianxianshi: 'Izgara çizgisi ekranı',
    hfcg:'Kurtarma başarılı',
    },
    SecurityCenter: {
    fanhui: 'geri dönmek',
    anquanzhongxin: 'Güvenlik Merkezi',
    qingshurunicheng: 'Lütfen bir takma ad girin',
    nichengxiugaishibai: 'Takma ad değişikliği başarısız oldu',
    huoquyonghuxinxishibai: 'Kullanıcı bilgileri alınamadı',
    xiugaidenglumima: 'Giriş şifresini değiştir',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Grafik doğrulama kodu alınamadı, lütfen tekrar talep edin',
    qingshurujiumima: 'Lütfen eski şifreyi girin',
    qingshuruxinmima: 'Lütfen yeni şifreyi girin',
    qingzaicishuruxinmima: 'Lütfen yeni şifreyi tekrar girin',
    qingshurutuxingyanzhengma: 'Lütfen grafik doğrulama kodunu girin',
    qingshuruyanzhengma: 'Lütfen doğrulama kodunu girin',
    huoquzhuceshoujihaoshibai: 'Kayıtlı cep telefonu numarası alınamadı',
    qingxianwanchengshoujirenzheng: 'Lütfen önce cep telefonu kimlik doğrulamasını tamamlayın',
    huoqushoujiyanzhengmashibai: 'Cep telefonu doğrulama kodu alınamadı',
    huoqushoujiyanzhengma: 'Cep telefonu doğrulama kodunu alın',
    houchongxinfasong: 'S\'den sonra yeniden gönder',
    mimacuowu: 'Yanlış şifre',
    liangcixinmimashurubuyizhiqingjiancha: 'Girilen iki yeni şifre tutarsız, lütfen kontrol edin',
    xinmimaheyuanmimabunengyiyang: 'Yeni şifre orijinal şifreyle aynı olamaz',
    qingshurushoujiyanzhengma: 'Lütfen cep telefonu doğrulama kodunu girin',
    yanzhengmacuowu: 'Doğrulama kodu hatası',
    denglumimaxiugaichenggong: 'Giriş şifresi başarıyla değiştirildi',
    denglumimaxiugaishibai: 'Oturum açma parolası değişikliği başarısız oldu',
    xiugaizijinmima: 'Fon şifresini değiştir',
    qingshuruzijinmima: 'Lütfen fon şifresini giriniz',
    qingzaicishuruzijinmima: 'Lütfen fon şifrenizi tekrar giriniz',
    liangcizijinmimabuyizhiqingjiancha: 'İki fon şifresi tutarsız, lütfen kontrol edin',
    shezhizijinmimachenggong: 'Fon şifresini başarıyla belirleyin',
    shezhizijinmimashibai: 'Fon şifresi ayarlanamadı',
    xiugaizijinmimachenggong: 'Fon şifresi başarıyla değiştirildi',
    xiugaizijinmimashibai: 'Fon şifresi değiştirilemedi',
    youxiangrenzheng: 'E-posta kimlik doğrulaması',
    huoquyouxiangyanzhengma: 'E-posta doğrulama kodunu alın',
    qingshuruyouxiangdizhi: 'Lütfen e-posta adresini girin',
    huoquyouxiangyanzhengmashibai: 'E-posta doğrulama kodu alınamadı',
    qingshuruyouxiangyanzhengma: 'Lütfen e-posta doğrulama kodunu girin',
    youxiangyanzhengchenggong: 'E-posta doğrulaması başarılı',
    weirenzheng: 'Sertifikalı değil',
    queding: 'Elbette',
    anquanshezhiguanli: 'Güvenlik ayarları yönetimi',
    denglumima: 'Giriş şifresi',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'Bir oturum açma parolası belirleyerek, hesap parolanızı kullanarak doğrudan oturum açabileceksiniz.',
    xiugai: 'Tekrar düzeltme yapmak',
    zijinmima: 'Fon şifresi',
    yongyubaohuzijinanquan: 'Fonların güvenliğini korumak için kullanılır',
    shezhi: 'kurmak',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'Giriş yaparken, para çekerken, şifre alırken ve güvenlik ayarlarını değiştirirken güvenlik doğrulaması için kullanılır.',
    yirenzheng: 'Sertifikalı',
    renzheng: 'Sertifikasyon',
    shimingrenzheng: 'Gerçek ad kimlik doğrulaması',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'Coin satın alırken, satarken, çekerken, yatırırken ve aktarırken güvenlik kimlik doğrulaması için kullanılır.',
    daishenhe: 'İnceleme bekleniyor',
    yibohui: 'Görevden alındı',
    yuandenglumima: 'Orijinal giriş şifresi',
    xinmima: 'Yeni Şifre',
    quedingxinmima: 'Yeni şifreyi onayla',
    tuxingyanzhengma: 'Grafik doğrulama kodu',
    yanzhengma: 'Doğrulama kodu',
    zijinmima: 'Fon şifresi',
    querenzijinmima: 'Fon şifresini doğrulayın',
    youxiangdizhi: 'E-posta adresi',
    qingxianwanchengyouxiangrenzheng: 'Lütfen önce e-posta doğrulamasını tamamlayın',
    xiugaitouxiangchenggong: 'Avatar başarıyla değiştirildi',
    xiugaitouxiangshibai: 'Avatar değiştirilemedi',
    shangchuantouxiangzhinengshijpggeshi: 'Avatar resimleri yükleme yalnızca JPG formatında olabilir!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'Yüklenen avatar görselinin boyutu 2 MB\'ı aşamaz!',
    },
    verification: {
    fanhui: 'geri dönmek',
    shimingrenzheng: 'Gerçek ad kimlik doğrulaması',
    qingshuruxingming: 'Lütfen adı girin',
    qingshuruzhengjianhaoma: 'Lütfen kimlik numaranızı giriniz',
    huoquyonghuxinxishibai: 'Kullanıcı bilgileri alınamadı',
    wenjiangeshicuowu: 'Dosya biçimi hatası',
    wenjianbunengdayu5M: 'Dosya 5M\'den büyük olamaz',
    qingtianxiexingming: 'Lütfen isminizi giriniz',
    qingtianxiezhengjianhaoma: 'Lütfen kimlik numarasını giriniz',
    qingshangchuanzhengjianzhengmian: 'Lütfen kimliğinizin ön yüzünü yükleyiniz',
    qingshangchuanzhengjianfanmian: 'Lütfen kimliğinizin arka yüzünü yükleyiniz',
    tijiaochenggong: 'Gönderim başarılı',
    kaiqishenfenrenzheng: 'Kimlik doğrulamayı aç',
    bohuiyuanyin: 'Reddetme nedeni',
    qingtianxiejiashizhizhaogongminkahuzhao: 'Lütfen ehliyet/vatandaş kartı/pasaport bilgilerinizi giriniz',
    xingming: 'İsim',
    zhengjianhaoma: 'kimlik numarası',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'Lütfen ehliyet/vatandaş kartı/pasaport bilgilerini yükleyin',
    shangchuanzhengjianzhengmian: 'Kimliğin ön yüzünü yükleyin',
    huotuozhuafangru: 'Veya sürükleyip bırakın',
    shangchuanzhengjianfanmian: 'Sertifikanın arka tarafını yükleyin',
    tijiao: 'göndermek',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'Arkadaşlarınızı davet edin ve ödüller kazanın',
    yongjiukede: 'kalıcı olarak mevcut',
    jiaoyifanyong: 'Ticaret indirimi',
    fasongyaoqinggeihaoyou: 'Arkadaşlarına davetiye gönder',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'Bağlantıyı paylaşarak arkadaşlarınızı BCFX\'e kaydolmaya davet edin',
    haoyouzhuce: 'Arkadaş kaydı',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'Arkadaş daveti kabul eder, kaydı tamamlar ve işlemleri gerçekleştirir',
    huodexiangyingbilijiangli: 'İlgili orantılı ödülleri alın',
    qingsonghuodejiaoyishouxufeijianglifuli: 'İşlem ücreti bonus avantajlarından kolayca yararlanın',
    wodezhuanshufenxiangfangshi: 'Paylaşmanın özel yolum',
    yaoqinglianjie: 'Davetiye bağlantısı',
    jiazaizhong: 'yükleniyor...',
    fuzhilianjie: 'Bağlantıyı kopyala',
    yaoqingma: 'Davetiye kodu',
    fuzhiyaoqingma: 'Davetiye kodunu kopyala',
    wodeyaoqing: 'benim davetiyem',
    yaoqingrenshu: 'Davet edilen kişi sayısı',
    huodejiangli: 'Ödül alın',
    huodongxize: 'Etkinlik ayrıntıları',
    tips1: 'Platform kullanıcıları arasındaki ilişki üç seviyelidir, örneğin: ABCD kullanıcıları üç seviye kullanıcı oluşturur ve A en yüksek seviyedir!',
    tips2: 'Seviyeler arasındaki çıkar ilişkileri',
    tips201: '.',
    tips3: 'Nakit ödül: Müşteri A, Müşteri B\'nin sözleşme işlem ücretinin %40\'ını + Müşteri C\'nin sözleşme işlem ücretinin %20\'sini + Müşteri D\'nin sözleşme işlem ücretinin %10\'unu alabilir',
    tips4: 'Örneğin: Müşteri A\'nın 10 birinci düzey müşterisi B, 10 ikinci düzey müşterisi C ve 10 üçüncü düzey müşterisi D vardır. Her müşteri sözleşmesi 100.000 USDT işlem yapıyorsa',
    tips5: 'O zaman A müşterisinin alabileceği getiri',
    tips6: '100000*%1,2*%40*10+100000*%1,2*%20*10+100000*%1,2*%10*10=8400 USDT nakit ödülü, gerçek zamanlı olarak çekilebilir',
    tips7: 'Faaliyette ayarlama yapılması durumunda BCFX platformu güncellemelerine tabi olacaktır ve nihai yorumlama hakkı BCFX\'e aittir.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'Grafik doğrulama kodu alınamadı, lütfen tekrar talep edin',
    qingshuruyouxiang: 'Lütfen e-postanızı girin',
    qingshurutuxingyanzhengma: 'Lütfen grafik doğrulama kodunu girin',
    qingshuruyanzhengma: 'Lütfen doğrulama kodunu girin',
    qingshurunindemima: 'Lütfen şifrenizi girin',
    qingzaicishurunindemima: 'Lütfen şifrenizi tekrar girin',
    qingshuruzhifumima: 'Lütfen ödeme şifresini girin',
    xuantian: 'İsteğe bağlı',
    bitian: 'Gerekli',
    yonghuzhuce: 'Kullanıcı kaydı',
    huanyingdenglu: 'Giriş yapmaya hoş geldiniz',
    guanfangwangzhan: 'resmi web sitesi',
    xiangmumingcheng: 'BCFX',
    youxiang: 'Posta',
    tuxingyanzhengma: 'Grafik doğrulama kodu',
    yanzhengma: 'Doğrulama kodu',
    huoquyanzhengma: 'Doğrulama kodu',
    shezhimima: 'Şifreyi ayarla',
    quedingmima: 'Şifreyi onayla',
    zhifumima: 'Ödeme şifresi',
    yaoqingma: 'Davetiye kodu',
    zhuce: 'kayıt olmak',
    yiyouzhanghao: 'Zaten bir hesabınız var mı?',
    denglu: 'Giriş yapmak',
    qingshuruyouxiangdizhi: 'Lütfen e-posta adresini girin',
    huoqushibai: 'Alınamadı',
    houchongxinfasong: 'S\'den sonra yeniden gönder',
    liangcishurudemimabuyizhi: 'İki kez girilen şifreler tutarsız',
    zhucechenggong: 'Kayıt başarılı',
    zhuceshibai: 'Kayıt başarısız oldu',
    },
    treasure:{
    jichubizhong: 'temel para birimi',
    shouyibizhong: 'Gelir para birimi',
    wakuangtianshu: 'Madencilik günleri',
    shouyitianshu: 'Kazanç günleri',
    suodingtianshu: 'Günleri kilitle',
    yichanshengshouyi: 'Elde edilen gelir',
    yuqishouyi: 'beklenen getiri',
    kaishishijian: 'başlangıç ​​zamanı',
    jieshushijian: 'bitiş zamanı',
    qingshuruninyaosuodingdebizhongshuliang: 'Lütfen kilitlemek istediğiniz para birimindeki USDT miktarını girin',
    huoqushibai: 'Alınamadı',
    meiyitian: 'her 1 günde bir',
    dakai: 'Açık',
    yuanyuzhoukuangchi: 'Metaverse Madencilik Havuzu',
    jilu: 'Kayıt',
    kuangchixiangqing: 'Madencilik havuzu ayrıntıları',
    suo: 'Kilit',
    yitianchanchu: '1 günlük çıktı',
    zuixiaosuodingshijian: 'Minimum kilitlenme süresi',
    nindeUSDT: 'USDT\'niz',
    tian: 'gökyüzü',
    quanbu: 'Tümü',
    suoUSDTbingkaishishengchan: 'USDT\'yi kilitleyin ve üretime başlayın',
    ninquedingyaosuoUSDTkaishishengchanma: 'USDT\'yi kilitleyip üretime başlamak istediğinizden emin misiniz?',
    tishi: 'ipucu',
    queding: 'Elbette',
    quxiao: 'İptal etmek',
    kaishishengchan: 'Üretime başlayın!',
    yiquxiao: 'İptal edildi',
    },
    subscription:{
    rengouliebiao:'Abonelik listesi',
    jijiangdaolai:'Yakında gelecek',
    jinxingzhong:'devam etmekte',
    quanbu:'Tümü',
    keshiyonghuobi:"Mevcut para birimleri",
    zongshuliang:'toplam miktar',
    meiyouxianzhi:'sınır yok',
    shengyushuliang:'Kalan miktar',
    kaishishijian:'başlangıç ​​zamanı',
    zhuangtai:'durum',
    jilu:'Kayıt',
    zanwujilu:"Henüz kayıt yok",
    leixing:'tip',
    shuliang:'miktar',
    goumaijine:'Satın alma tutarı',
    shijian:'zaman',
    goumai:'Satın almak',
    duichu:'Aniden',
    jiangli:'ödül',
    tibi:'Para çekme',
    huidaomulu:'İçindekiler tablosuna dön',
    dangqianchiyou:'şu anda tutuluyor',
    keyong:'Mevcut',
    keduihuan:'Kullanılabilir',
    shishihangqing:'Gerçek zamanlı alıntılar',
    yue:'Denge',
    biaoti:'başlık',
    fashoushuliang:'Satılık miktar',
    jindu:"takvim",
    goumaishuliang:'Satın alma miktarı',
    ge:'bireysel',
    woyiyuedu:'okudum',
    daibigoumaixieyi:'Jeton Satın Alma Sözleşmesi',
    qingshurugoumaijine:'Lütfen satın alma tutarını girin',
    shifoyaogoumai:'Satın almak istiyor musun?',
    tishi:'ipucu',
    queding:'Elbette',
    quxiao:'İptal etmek',
    yjs:'sona erdi',
    ywc:'Tamamlanmış',
    sgjg:'Abonelik fiyatı',
    jssj:"bitiş zamanı",
    zqsl:"Kazanan miktar",
    zqje:"Kazanma miktarı",
    tian:"gökyüzü",
    shi:'saat',
    fen:'nokta',
    miao:'Saniye',
    shengou:'Abonelik',
    xmxq:'Proje ayrıntıları',
    },
    }
    
    