export default {
    header: {
    hello: '안녕하세요 월드!',
    changeLanguage: '언어 전환',
    Login: '로그인',
    Register: '등록하다',
    Logout: '로그아웃',
    shouye: '첫 페이지',
    bibijiaoyisuo: '암호화폐 거래',
    heyuejiaoyisuo: '계약 거래',
    licaichanpin: '마이닝 풀',
    rengou: '신청',
    ruanjianxiazai: '소프트웨어 다운로드',
    zhongwen: '중국인',
    yingwen: '~ 안에',
    zichan: '자산',
    dingdan: '주문하다',
    heyuedingdan: '계약 주문',
    bibidingdan: '코인주문',
    anquanzhongxin: '보안센터',
    fanyongyaoqing: '커미션 초대',
    tuichudenglu: '로그아웃',
    gupiaojiaoyi: '주식 거래',
    waihuijiaoyi: '외환 거래',
    qiquanjiaoyi:'옵션 거래',
    gupiaodingdan: '재고 주문',
    waihuidingdan: '외환 주문',
    qiquandingdan: '옵션 주문',
    jiaoyidingdan:'거래 주문',
    daolizhongxin:'대리점 센터',
    huodong:'활동',
    },
    home: {
    huanyinglaidao: '에 오신 것을 환영합니다',
    pingtaimingcheng: '여기',
    mengxiangqihang: '꿈이 항해를 시작하다',
    yijiankaiqishuzihualvcheng: '클릭 한 번으로 디지털 여정을 시작하세요',
    liaojiegengduo: '자세히 알아보기',
    chongzhi: '충전하다',
    jiaoyi: '거래',
    remenbang: '인기 목록',
    zhangfubang: '게이너 목록',
    diefubang: '패자 목록',
    chengjiaoliang: '용량',
    zanwushuju: '아직 데이터가 없습니다',
    jiayidui: '거래쌍',
    zuixinjiage: '최신 가격',
    zhangdie24h: '24시간 상승 및 하강',
    caozuo: '작동하다',
    jiaoyi: '거래',
    gengduo: '더',
    suishisuidikaiqijiaoyi: '언제 어디서나 거래를 시작하세요',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: '앱이든 웹이든 빠르게 거래를 시작할 수 있습니다.',
    saomaxiazaiApp: 'QR 코드를 스캔하여 앱을 다운로드하세요.',
    iosheAndroid: 'iOS와 안드로이드',
    ninkexinrenqieanquandejiamibijiaoyisuo: '신뢰할 수 있고 안전한 암호화폐 거래소',
    tishi1: '예비 증명서',
    xiangqing1: '모든 사용자 자산을 최소한 1:1 비율로 보유할 것을 약속드립니다.',
    tishi2: '최고의 거래율',
    xiangqing2: '우대 거래율, 경쟁력 있는 VIP 권리, 최고 품질의 서비스를 누려보세요.',
    tishi3: '믿을 수 있는 보안',
    xiangqing3: '우리의 정교한 보안 조치와 SAFU 자금은 모든 위험으로부터 디지털 자산을 보호합니다.',
    tishi4: '24*7시간 고객 서비스 지원',
    xiangqing4: '24*7시간, 풀타임 운영 모드, 관련 문의에 최대한 빨리 답변해 드립니다.',
    tishi5: '안정적이고 안전함',
    xiangqing5: '세계 최고의 거래소가 귀하의 자산에 대해 은행 수준의 보안 보호를 제공합니다.',
    tishi6: 'FAQ',
    xiangqing6: '특정 기능에 대한 자세한 설명은 FAQ를 확인하세요.',
    },
    footer: {
    bangzhuzhongxin: '도움말 센터',
    dianhuazixun: '전화상담',
    dianhuahegongzuoshijian: '1670-9756 (평일 10:00~19:00 / 주말, 공휴일 휴무)',
    liaotianzixun: '1:1채팅 상담',
    keketuozixun: '코코톡 컨설팅',
    zaixianbangzhu: '온라인 도움말',
    h24kaifang: '(24시간 영업)',
    lixianbangzhu: '오프라인 도움말',
    gongsidizhi: '서울시 용산구 한강길 69 용산푸르지오써밋 1층',
    gongzuoshijian: '(평일 10:00~18:00 / 주말 및 공휴일 휴무)',
    shangshizixun: '상장상담',
    hezuozixun: '협력상담',
    gongsi: '회사',
    lianxiwomwn: '문의하기',
    zhidao: '가이드',
    yonghuzhichi: '사용자 지원',
    zhanghuzhinan: '계좌안내-입출금',
    ruanjianxiazai: '소프트웨어 다운로드',
    zhengce: '정책',
    fuwuyuyinsitiaokuan: '서비스 약관 및 개인정보 보호',
    },
    login: {
    yonghudenglu: '사용자 로그인',
    huanyingdenglu: '로그인을 환영합니다',
    xiangmumingcheng: 'BCFX',
    guanfangwangzhan: '공식 웹사이트',
    youxiang: '우편',
    qingshuruyouxiang: '이메일을 입력해주세요',
    mima: '비밀번호',
    qingshurumima: '비밀번호를 입력해주세요',
    wangjimima: '비밀번호를 잊으셨나요?',
    denglu: '로그인',
    haimeiyouzhanghao: '아직 계정이 없나요? 가다',
    zhuce: '등록하다',
    dengluchenggong: '로그인 성공',
    denglushibai: '로그인 실패',
    qingshuruyonghuminghemima: '사용자 이름과 비밀번호를 입력하세요',
    mimachangdubunengxiaoyuliuwei: '비밀번호 길이는 6자 미만일 수 없습니다.',
    youxiangrenzheng: '이메일 인증',
    youxiangdizhi: '이메일 주소',
    qingshuruyouxiangdizhi: '이메일 주소를 입력해주세요',
    tuxingyanzhengma: '그래픽 인증 코드',
    qingshurutuxingyanzhengma: '그래픽 인증 코드를 입력하세요.',
    yanzhengma: '인증코드',
    qingshuruyouxiangyanzhengma: '이메일 인증코드를 입력해주세요',
    huoquyouxiangyanzhengma: '이메일 인증 코드 받기',
    queding: '확신하는',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '그래픽 인증 코드를 받지 못했습니다. 다시 요청하세요.',
    qingxianwanchengyouxiangrenzheng: '먼저 이메일 확인을 완료해주세요.',
    huoquyouxiangyanzhengmashibai: '이메일 인증 코드를 받지 못했습니다.',
    houchongxinfasong: 'S 이후 재전송',
    youxiangyanzhengchenggong: '이메일 확인 성공',
    yanzhengmacuowu: '인증코드 오류',
    xiugaidenglumima: '로그인 비밀번호 변경',
    xinmima: '새 비밀번호',
    quedingxinmima: '새 비밀번호 확인',
    qingshuruxinmima: '새로운 비밀번호를 입력해주세요',
    qingzaicishuruxinmima: '새 비밀번호를 다시 입력해주세요',
    liangcimimashurubuyizhi: '두 개의 비밀번호 입력이 일치하지 않습니다',
    mimaxiugaichenggong: '비밀번호가 성공적으로 변경되었습니다.',
    xiugaimimashibai: '비밀번호를 변경하지 못했습니다.',
    },
    trade: {
    hangqingheyue: '계약',
    hangqingzuixinjia: '최신 가격',
    hangqingzhangdiefu: '증가 또는 감소',
    hangqingzuigao24h: '24시간 최고',
    hangqingzuidi24h: '최소 24시간',
    hangqingchengjiaoliang24h: '24시간 거래량',
    hangqingchengjiabishu: '거래수',
    zhanghuquanyi: '계정 권한',
    zhanghukeyongshouyi: '이용 가능한 혜택',
    hangqingweituoliebiao: '주문 목록',
    hangqingjiage: '가격',
    hangqingshuliang: '수량',
    hangqingzuixinchengjiao: '최신 거래',
    hangqingjiage1: '가격',
    hangqingshuliang1: '수량',
    hangqingshijian: '시간',
    dingdanchiyoucangwei: '위치를 유지',
    dingdandangqianweituo: '현재 주문',
    dingdanchengjiaojilu: '거래기록',
    dingdantiaojiandan: '조건부 순서',
    chicangheyue: '계약',
    chicangkaicangjunjia: '평균 개장가',
    chicangfudongyingkui: '변동 손익(USDT)',
    chicanggangganbeishu: '여러 가지 활용',
    chicangchicangliang: '직위',
    chicangduokong: '롱/숏',
    chicangduo: '많은',
    chicangkong: 'null',
    chicangzhiyingjiage: '이익실현 가격',
    chicangzhisunjiage: '손실 중지 가격',
    chicangqiangpingyugujiage: '예상 청산 가격',
    chicangcaozuo: '작동하다',
    chicangzhiyingzhisun: '이익을 얻고 손실을 막으세요',
    chicangpingcang: '포지션 마감',
    chicangshouxufei: '취급 수수료',
    weituoheyue: '계약',
    weituoshuliang: '주문 수량',
    weituojiage: '수수료 가격',
    weituofangxiang: '방향',
    weituogangganbeishu: '여러 가지 활용',
    weituochengjiaoshuliang: '거래수량',
    weituoshouxufei: '취급 수수료',
    weituochengjiaojunjia: '평균 거래 가격',
    weituoshouyi: '소득',
    weituozhuangtai: '상태',
    weituochengjiaoshijian: '거래시간',
    weituocaozuo: '작동하다',
    weituochedan: '주문 취소',
    weituotijiaoshibai: '제출 실패',
    weituozhilingtijiao: '지시사항 제출',
    weituoyitijiao: '제출된',
    weituobufenchengjiao: '부분 거래',
    weituobufenchengjiaoyichedan: '일부 주문이 취소되었습니다',
    weituowanquanchengjiao: '거래 완료',
    weituowanquanchedan: '주문을 완전히 취소',
    weituochedanzhong: '주문 취소 중',
    weituokaiduo: '길게 열다',
    weituopingkong: '평공',
    weituokaikong: '오픈 쇼트',
    weituopingduo: '핀듀오',
    chengjiaoheyue: '계약',
    chengjiaofangxiang: '방향',
    chengjiaoshuliang: '거래수량',
    chengjiaojiage: '거래 가격',
    chengjiaoshijian: '거래시간',
    chengjiaopingcangyingkui: '포지션 마감 손익',
    chengjiaoshouxufei: '거래 수수료',
    chengjiaokaiduo: '길게 열다',
    chengjiaopingkong: '평공',
    chengjiaokaikong: '오픈 쇼트',
    chengjiaopingduo: '핀듀오',
    tiaojianzengjiatiaojian: '조건 추가',
    tiaojianzantingquanbu: '모두 일시중지',
    tiaojianqidongquanbu: '모두 시작',
    tiaojianshanchuquanbu: '모두 삭제',
    tiaojianheyue: '계약',
    tiaojianweituoshuliang: '주문 수량',
    tiaojianfangxiang: '방향',
    tiaojiantiaojianleixing: '조건 유형',
    tiaojianjiageleixing: '가격 유형',
    tiaojianbijiaofuhao: '비교 기호',
    tiaojiantiaojian: '상태',
    tiaojianzhuangtai: '상태',
    tiaojianchuangjianshijian: '생성 시간',
    tiaojiancaozuo: '작동하다',
    tiaojianxiugai: '개정하다',
    tiaojianqidong: '시작하다',
    tiaojianzanting: '정지시키다',
    tiaojianshanchu: '삭제',
    tiaojianlijixiadan: '지금 주문하세요',
    tiaojiankaiduo: '길게 열다',
    tiaojianpingkong: '평공',
    tiaojiankaikong: '오픈 쇼트',
    tiaojianpingduo: '핀듀오',
    tiaojianjiagetiaojian: '价格条件',
    tiaojianshijiantiaojian: '시간 조건',
    tiaojianzuixinjia: '최신 가격',
    tiaojianByijia: '하나의 가격으로 구매',
    tiaojianSyijia: '하나의 가격으로 판매',
    tiaojianzanting: '정지시키다',
    tiaojianyunxing: '달리다',
    tiaojianyiwancheng: '완전한',
    tiaojianshibai: '실패하다',
    tiaojianyishanchu: '삭제됨',
    tiaojianshoudongchufawancheng: '수동 트리거 완료',
    tiaojianshoudongchufashibai: '수동 트리거 실패',
    xiadankaicang: '포지션을 개설하세요',
    xiadanpingcang: '포지션 마감',
    xiadanweituoleixing: '대리인 유형',
    xiadanqingxuanze: '선택하세요',
    xiadanshijia: '시장 가격',
    xiadanxianjia: '가격 제한',
    xiadanjiage: '가격',
    xiadanqingshurujiage: '가격을 입력해주세요',
    xiadanshuliang: '수량',
    xiadanqingshurushuliang: '수량을 입력해주세요',
    xiadanzhang: '열려 있는',
    xiadankekaishuliang: '사용 가능 수량',
    xiadanqingxianxuanzechicangdan: '먼저 위치 순서를 선택하세요.',
    xiadankepingshuliang: '평준화할 수 있는 수량',
    xiadansuoxubaozhengjin: '필요한 마진:',
    xiadanshijiazhiyingzhisunyushe: '시장 가격 이익실현 및 손절매 사전 설정',
    xiadanyujiqiangpingjiage: '예상 청산 가격:',
    xiadanqiangpingjiagetishi: '이 예상 청산 가격은 총 자산을 기준으로 동적으로 계산됩니다. 해당 가격은 참고용이며 최종 청산용으로 사용되지 않습니다.',
    xiadanzuoduo: '오래 가세요',
    xiadanzuokong: '짧은',
    xiadanpingcang: '포지션 마감',
    zhanghuheyuezichan: '계약 자산',
    zhanghuchongbi: '코인 입금',
    zhanghutibi: '코인 출금',
    zhanghuhuazhuan: '옮기다',
    zhanghuzhanghuquanyi: '계정 권한',
    zhanghufudongyingkui: '유동손익',
    zhanghucangweibaozhengjin: '포지션 마진',
    zhanghudongjiebaozhengjin: '마진 동결',
    zhanghukeyongquanyi: '이용 가능한 혜택',
    zhanghubaozhengjinshiyonglv: '마진 활용률',
    hangqingheyuexinxi: '계약정보',
    hangqingheyuemingcheng: '계약명',
    hangqingjijiahuobi: '호가통화',
    hangqingheyuedaxiao: '계약 규모',
    hangqingzuixiaojiagebodong: '최소 가격 변동',
    dialogleverRateganggantiaozheng: '레버리지 조정',
    dialogleverRatequeding: '확신하는',
    dialogtransferzichanhuazhuan: '자산 양도',
    dialogtransferbizhong: '통화',
    dialogtransfercong: '~에서',
    dialogtransferdao: '도착하다',
    dialogtransfershuliang: '수량',
    dialogtransferqingshuruhuazhuanshuliang: '이체금액을 입력해주세요',
    dialogtransferquanbu: '모두',
    dialogtransferkeyong: '사용 가능',
    dialogtransferhuazhuan: '옮기다',
    dialogtransferjichuzhanghu: '기본 계정',
    dialogtransferheyuezhanghu: '계약계좌',
    dialogfitlosszhiyingzhisun: '이익을 얻고 손실을 막으세요',
    dialogfitlossheyu: '계약',
    dialogfitlossduokong: '롱/숏',
    dialogfitlosschichangjunjia: '평균 포지션 가격',
    dialogfitlossshuliang: '수량',
    dialogfitlosszuixinjia: '최신 가격',
    dialogfitlosszhiyingdianshu: '이익 포인트 가져가기',
    dialogfitlosszhisundianshu: '손실 지점 중지',
    dialogfitlossdianshu: '점수',
    dialogfitlosszhiyingjiage: '이익실현 가격',
    dialogfitlosszhisunjiage: '손실 중지 가격',
    dialogfitlossjiage: '가격',
    dialogfitlossyuqishouyi: '예상 수익',
    dialogfitlossyuqikuisun: '예상 손실',
    dialogfitlosstishi1: '팁: 현재 계약의 최소 변동폭은 다음과 같습니다.',
    dialogfitlosstishi2: ', 이익 실현을 취소하고 손실을 중지하려면 가격을 0으로 설정하세요.',
    dialogfitlossqueding: '확신하는',
    dialogfitlossduo: '많은',
    dialogfitlosskong: 'null',
    dialogconditionzengjiayuntiaojiandan: '클라우드 조건 시트 추가',
    dialogconditiontiaojianleixing: '조건 유형:',
    dialogconditionjiagetiaojiandan: '가격 조건 시트',
    dialogconditionshijiantiaojiandan: '시간 조건 시트',
    dialogconditionjiageleixing: '가격 유형:',
    dialogconditionzuixinjia: '최신 가격',
    dialogconditionByijia: '하나의 가격으로 구매',
    dialogconditionSyijia: '하나의 가격으로 판매',
    dialogconditiontiaojianshezhi: '조건 설정:',
    dialogconditionjiageshezhi: '가격 설정:',
    dialogconditionqingshurujiage: '가격을 입력해주세요',
    dialogconditionshijianshezhi: '시간 설정:',
    dialogconditionjiaoyileixing: '거래 유형:',
    dialogconditionkaiduo: '길게 열다',
    dialogconditionpingkong: '평공',
    dialogconditionkaikong: '오픈 쇼트',
    dialogconditionpingduo: '핀듀오',
    dialogconditiondingdanshuliang: '주문 수량:',
    dialogconditionqingshurudingdanshuliang: '주문수량을 입력해주세요',
    dialogconditiondingdanshixiao: '주문 시간:',
    dialogconditionyongjiuyouxia: '영구적으로 유효함',
    dialogconditiontijiao: '제출하다',
    dialogAutoProfitLosszhiyingzhisun: '이익을 얻고 손실을 막으세요',
    dialogAutoProfitLossmingcheng: '이름',
    dialogAutoProfitLosszhiyingdian: '이익실현 포인트',
    dialogAutoProfitLosszhisundian: '손실 지점 중지',
    dialogAutoProfitLosschongzhi: '다시 놓기',
    dialogAutoProfitLoss: '제출하다',
    dialogCloseOrderpingcang: '포지션 마감',
    dialogCloseOrderweituoleixing: '대리인 유형',
    dialogCloseOrderqingxuanze: '선택하세요',
    dialogCloseOrdershijia: '시장 가격',
    dialogCloseOrderxianjia: '가격 제한',
    dialogCloseOrderjiage: '가격',
    dialogCloseOrderqingshurujiage: '가격을 입력해주세요',
    dialogCloseOrdershuliang: '수량',
    dialogCloseOrderqingshurushuliang: '수량을 입력해주세요',
    dialogCloseOrderzhang: '열려 있는',
    dialogCloseOrderkepingshuliang: '평준화할 수 있는 수량',
    dialogCloseOrderqueding: '확신하는',
    jsMessageResgaiheyuegangganxiugaichenggong: '계약 레버리지가 성공적으로 수정되었습니다.',
    jsMessageResgaiheuyegangganxiugaishibai: '계약 레버리지 수정에 실패했습니다.',
    jsMessageReskaicangchenggong: '포지션이 성공적으로 열렸습니다',
    jsMessageReskaicangshibai: '포지션 개시 실패:',
    jsMessageReskaicang1: '포지션 오픈은 금지되어 있습니다',
    jsMessageReskaicang2: '계약이 존재하지 않습니다',
    jsMessageReskaicang3: '오픈 포지션 수가 상한을 초과했습니다.',
    jsMessageReskaicang4: '가격은 0입니다',
    jsMessageReskaicang5: '포지션을 개설하기에는 증거금이 부족합니다.',
    jsMessageRespingcangchenggong: '포지션이 성공적으로 마감되었습니다',
    jsMessageRespingcangshibai: '포지션 청산 실패:',
    jsMessageRespingcang1: '계약이 존재하지 않습니다',
    jsMessageRespingcang2: '포지션 영수증이 존재하지 않습니다.',
    jsMessageRespingcang3: '금액이 부족합니다.',
    jsMessageReszijinhuazhuanchenggong: '자금 이체 성공',
    jsMessageReszijinhuazhuanshibai: '자금 이체 실패',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: '포지션 주문 손절매 및 손절매가 성공적으로 설정되었습니다.',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: '포지션 주문에 대한 이익 정지 및 손실 정지 설정이 실패했습니다.',
    jsMessageResweituodanchedanchenggong: '주문이 취소되었습니다.',
    jsMessageResweituodanchedanshibai: '주문 취소 실패',
    jsMessageRestiaojiandantianjiachenggong: '조건부 주문이 성공적으로 추가되었습니다.',
    jsMessageResheyuebucunzai: '계약이 존재하지 않습니다',
    jsMessageResjiageshezhicuowu: '가격 설정 오류',
    jsMessageResshijianshezhicuowu: '시간 설정 오류',
    jsMessageResneibucuowu: '내부 오류',
    jsMessageRestiaojiandantianjiashibai: '조건부 주문을 추가하지 못했습니다.',
    jsMessageReszhiyingzhisunshezhichenggong: '이익실현 및 손절매가 성공적으로 설정되었습니다.',
    jsconfirmquedingchedanma: '주문을 취소하시겠습니까?',
    jsconfirmtishi: '힌트',
    jsconfirmqueding: '확신하는',
    jsconfirmquxiao: '취소',
    jsMessageqingxiandenglu: '먼저 로그인해주세요!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: '이 계약에는 공개 포지션이 있으며 레버리지를 수정할 수 없습니다.',
    jsMessagegaiheyueyouweituobukexiugaiganggan: '본 계약에는 위임사항이 있으며 레버리지를 수정할 수 없습니다.',
    jsMessageqingshuruzhengquedeshuliang: '정확한 수량을 입력해주세요!',
    jsMessageqingshuruzhengquedejiage: '정확한 가격을 입력해주세요!',
    jsMessageqingxianxuanzechicang: '먼저 포지션을 선택해주세요!',
    jsMessageqingshuruhuazhuanshuliang: '이체금액을 입력해주세요!',
    jsMessageqingxianxuanzeheyue: '먼저 계약을 선택해주세요!',
    jsMessageqingshurutiaojiandanshuliang: '조건부 주문수량을 입력해주세요!',
    jsMessageqingshurutiaojiandandejiage: '조건부 주문 가격을 입력해주세요!',
    jsMessageqingshurutiaojiandanzhixingshijian: '조건부 주문 실행 시간을 입력해주세요!',
    emptytext: '아직 데이터가 없습니다',
    jsMessageReszhiyingzhisundianshushezhicuowu: '이익 실현 및 손실 중지 핍이 잘못 설정되었습니다.',
    jsMessageReschicangdanbucunzai: '포지션 영수증이 존재하지 않습니다.',
    zxkcje:'최소 개설 금액',
    },
    commisson:{
    dlzx:'대리점 센터',
    sjzl:'데이터 개요',
    quanbu:"모두",
    jinri:'오늘',
    zuori:'어제',
    ri:'일',
    wdfy:'내 리베이트',
    wdsjfy:'내 세 번째 수준 리베이트',
    dengji:'등급',
    yqrs:'초대된 사람의 수',
    tdrs:'팀 규모',
    yqxq:'초대 세부정보',
    yhm:"사용자 이름",
    cjsj:'생성 시간',
    fyxq:'리베이트 세부정보',
    zyj:'총 커미션',
    dryj:'일일 커미션',
    fyje:'리베이트 금액',
    
    },
    assets: {
    zongzichan: '총자산',
    chongbi: '코인 입금',
    tibi: '코인 출금',
    huazhuan: '옮기다',
    fabizhanghu: '기본 계정',
    heyuezhanghu: '계약계좌',
    jiayiliushui: '거래 흐름',
    dialogzichanhuazhuan: '자산 양도',
    cong: '~에서',
    dao: '도착하다',
    shuliang: '수량',
    qingshuruhuazhuanshuliang: '이체금액을 입력해주세요',
    quanbu: '모두',
    keyong: '사용 가능',
    huanzhuan: '옮기다',
    zijinhuazhuanchenggong: '자금 이체 성공',
    zijinhuazhuanshibai: '자금 이체 실패',
    bibizhanghu: '코인 계정',
    qiquanzhanghu:'옵션 계정',
    zczl:'자산 개요',
    zzczh:'총자산할인',
    ztzc:"운송 중인 자산",
    djzc:'자산 동결',
    zyzc:'약속된 자산',
    cwzc:'포지션 자산',
    jyzh:'거래계좌',
    bizhong:'통화',
    dongjie:'꼭 매달리게 하다',
    lirun:"이익",
    hyzh:"계약계좌",
    },
    basicsAccountRecord: {
    fanhui: '반품',
    fabizhanghuliushui: '거래 계좌 흐름',
    fabijiaoyiliushuileixing: '거래 흐름 유형',
    qingxuanze: '선택하세요',
    chaxunriqi: '쿼리 날짜',
    liushuileixing: '파이프라인 유형',
    shijian: '시간',
    biandongjine: '금액 변경',
    yue: '균형',
    quanbu: '모두',
    Sellbi: '동전을 팔다',
    Buybi: '동전을 사다',
    tibi: '코인 출금',
    chongbi: '코인 입금',
    fabizhuanheyue: '기본양도계약',
    heyuezhuanfabi: '기본 계약',
    zhuanzhang: '옮기다',
    fabihuazhuandaoheyue: '계약으로 기본 이전',
    heyuehuazhuandaofabi: '기지로 계약 이적',
    goumaijijin: '마이닝 풀 구매',
    jijinshuhui: '채굴 풀 상환',
    jijinshouyi: '마이닝풀 환매수입',
    fabizhuanqihuo: '선물로의 기본 변환',
    qihuozhuanfabi: '선물 양도 기준',
    fabizhuanganggu: '홍콩 주식으로 기본 이전',
    gangguzhuanfabi: '홍콩 주식 이전의 기본',
    fabizhuanbibi: '기본이체통화',
    bibizhuanfabi: '통화 대 통화 이체의 기본',
    goumaiSGCB: 'ICO 구매',
    chushouSGCB: 'ICO 판매',
    huoqushibai: '획득하지 못했습니다.',
    zanwushuju: '아직 데이터가 없습니다',
    },
    contractAccountRecord: {
    fanhui: '반품',
    heyuezhanghuliushui: '계약 계정 흐름',
    heyuejiaoyiliushuileixing: '계약 거래 흐름 유형',
    qingxuanze: '선택하세요',
    chaxunriqi: '쿼리 날짜',
    liushuileixing: '파이프라인 유형',
    shijian: '시간',
    biandongjine: '금액 변경',
    yue: '균형',
    quanbu: '모두',
    kaicangshouxufei: '개설비',
    pingcangshouxufei: '마감 수수료',
    pingcangyingkui: '포지션 마감 손익',
    fabizhuanheyue: '기본양도계약',
    heyuezhuanfabi: '기본 계약',
    zhuanzhang: '옮기다',
    kaicangyongjin: '오프닝 커미션',
    pingcangyongjin: '마감 커미션',
    huoqushibai: '획득하지 못했습니다.',
    zanwushuju: '아직 데이터가 없습니다',
    },
    recharge: {
    zanwushuju: '아직 데이터가 없습니다',
    shijian: '시간',
    leixing: '유형',
    shuliang: '수량',
    zhuangtai: '상태',
    caozuo: '작동하다',
    chakanxiangqing: '세부 사항을 확인하세요',
    huoqushibai: '획득하지 못했습니다.',
    dialogtitle: '세부',
    chongbi: '코인 입금',
    fanhui: '반품',
    lianmingcheng: '체인명',
    fuzhilianjie: '주소 복사',
    tishixinxi1: '• TRC_USDT 또는 ERC_USDT가 아닌 자산을 위 주소에 입금하지 마십시오. 그렇지 않으면 자산이 회수되지 않습니다.',
    tishixinxi2: '• 위 주소로 충전한 후 전체 네트워크 노드의 확인이 필요합니다. 1회 네트워크 확인 후 계정이 수신되며, 2회 네트워크 확인 후 통화가 출금될 수 있습니다.',
    tishixinxi3: '• 최소 입금액: 50USDT, 최소 입금액 미만 입금액은 적립되지 않으며 환불되지 않습니다.',
    tishixinxi4: '• 충전주소는 자주 변경되지 않으며, 반복적으로 충전될 수 있으며, 변경사항이 있을 경우 홈페이지 공지사항이나 이메일을 통해 알려드리도록 최선을 다하겠습니다.',
    tishixinxi5: '• 정보가 변조되거나 유출되는 것을 방지하기 위해 컴퓨터와 브라우저가 안전한지 확인하십시오.',
    putongchongbi: '보통예금',
    jiaoyichenggong: '거래 성공',
    jiaoyishibai: '거래 실패',
    OMNIweihuzhong: 'OMNI 체인이 점검 중입니다. TRC20을 선택하세요.',
    },
    withdraw: {
    fanhui: '반품',
    zanwushuju: '아직 데이터가 없습니다',
    tibi: '코인 출금',
    huoqushibai: '획득하지 못했습니다.',
    OMNIweihuzhong: 'OMNI 체인이 점검 중입니다. TRC20을 선택하세요.',
    shijian: '시간',
    leixing: '유형',
    shuliang: '수량',
    zhuangtai: '상태',
    caozuo: '작동하다',
    chakanxiangqing: '세부 사항을 확인하세요',
    putongtibi: '일반철회',
    dialogtitle: '세부',
    dialogtitlewithdraw: '코인 출금',
    houchongxinfasong: 'S 이후 재전송',
    huoquyouxiangyanzhengma: '이메일 인증 코드 받기',
    lianmingcheng: '체인명',
    tibidizhi: '출금주소',
    qingshurutibidizhi: '출금주소를 입력해주세요',
    tibishuliang: '인출된 코인의 양',
    shouxufei: '취급 수수료',
    qingshurutibishuliang: '출금금액을 입력해주세요',
    quanbu: '모두',
    keyong: '사용 가능',
    daozhangshuliang: '도착 수량',
    zuixiaotibishuliang: '최소 인출 금액은 다음과 같습니다.',
    tishixinxi: '자금 보안을 보장하기 위해 귀하의 계정 보안 정책이 변경되거나 비밀번호가 수정되면 당사는 인출에 대한 수동 검토를 실시할 것입니다. 직원이 전화 또는 이메일로 연락할 때까지 기다려 주십시오. 데이터가 변조되거나 유출되지 않도록 컴퓨터와 브라우저가 안전한지 확인하십시오.',
    tuxingyanzhengma: '그래픽 인증 코드',
    qingshurutuxingyanzhengma: '그래픽 인증 코드를 입력하세요.',
    youxiangyanzhengma: '이메일 인증 코드',
    queding: '확신하는',
    tijiaozhong: '제출 중',
    yijujue: '거부됨',
    yitongguo: '합격',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '그래픽 인증 코드를 받지 못했습니다. 다시 요청하세요.',
    huoquyouxiangyanzhengmashibai: '이메일 인증 코드를 받지 못했습니다.',
    qingxianwanchengyouxiangrenzheng: '먼저 이메일 확인을 완료해주세요.',
    huoquyouxiangshibaiqingchongxinqingqiu: '이메일 주소를 가져오지 못했습니다. 다시 요청하세요.',
    qingshuruyouxiangyanzhengma: '이메일 인증코드를 입력해주세요',
    youxiangyanzhengmacuowu: '이메일 인증번호가 잘못되었습니다.',
    qxsmrz:'먼저 실명을 확인해주세요',
    tishi:'힌트',
    queding:'확신하는',
    },
    contractTransactionRecord: {
    fanhui: '반품',
    zanwushuju: '아직 데이터가 없습니다',
    heyuedingdan: '계약 주문',
    qingxuanze: '선택하세요',
    quanbu: '모두',
    kaiduo: '길게 열다',
    pingkong: '평공',
    kaikong: '오픈 쇼트',
    pingduo: '핀듀오',
    xianjiaweituo: '제한 주문',
    shijiaweituo: '시장가 주문',
    suoyouzhuangtai: '모든 상태',
    tijiaoshibai: '제출 실패',
    zhunbeitijiao: '제출 준비 완료',
    yitijiao: '제출된',
    bufentijiao: '부분 거래',
    bufentijiaoyichedan: '일부 주문이 취소되었습니다',
    quanbuchengjiao: '모든 거래 창구',
    yichedan: '주문이 취소됨',
    huoqushibai: '획득하지 못했습니다.',
    heyuedaima: '계약 코드',
    ganggan: '지렛대',
    xiadanshijian: '주문시간',
    chengjiaoshijian: '거래시간',
    fangxiang: '방향',
    baojialeixing: '견적 유형',
    weituoliang: '커미션 금액(개)',
    weituojia: '주문 가격(USDT)',
    chengjiaoliang: '거래량(티켓)',
    chengjiaojunjia: '평균 거래 가격(USDT)',
    yingkui: '손익(USDT)',
    shouxufei: '수수료(USDT)',
    zhuangtai: '상태',
    gupiaodingdan: '재고 주문',
    waihuidingdan: '외환 주문',
    gupiaodaima: '주식 코드',
    waihuidaima: '외환 코드',
    },
    kLine: {
    zhibiao: '색인',
    shezhi: '설정',
    quanping: '전체 화면',
    tuichuquanping: '전체 화면 종료',
    jishuzhibiao: '기술 지표',
    futuzhibiao: '하위 그림 표시',
    zhutuzhibiao: '메인 차트 지표',
    huifumoren: '기본값 복원',
    lazhutuleixing: '촛대 유형',
    qingxuanze: '선택하세요',
    quanshixin: '모든 고체',
    quankongxin: '완전 중공',
    zhangkongxin: '속이 빈 상승',
    diekongxin: '속이 빈다',
    mianjitu: '영역 차트',
    jiagezhouleixing: '가격축 종류',
    xianxingzhou: '선 축',
    baifenbizhou: '백분율 축',
    duishuzhou: '로그 축',
    zuigaojiaxianshi: '최고가 표시',
    zuidijiaxianshi: '최저가 표시',
    zuixinjiaxianshi: '최신 가격 표시',
    zhibiaozuixinzhixianshi: '표시기의 최신 값이 표시됩니다.',
    daozhizuobiao: "역좌표",
    wanggexianxianshi: '그리드 라인 표시',
    hfcg:'복구 성공',
    },
    SecurityCenter: {
    fanhui: '반품',
    anquanzhongxin: '보안센터',
    qingshurunicheng: '닉네임을 입력해주세요',
    nichengxiugaishibai: '닉네임 수정 실패',
    huoquyonghuxinxishibai: '사용자 정보를 가져오지 못했습니다.',
    xiugaidenglumima: '로그인 비밀번호 변경',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '그래픽 인증 코드를 받지 못했습니다. 다시 요청하세요.',
    qingshurujiumima: '이전 비밀번호를 입력해주세요',
    qingshuruxinmima: '새로운 비밀번호를 입력해주세요',
    qingzaicishuruxinmima: '새 비밀번호를 다시 입력해주세요',
    qingshurutuxingyanzhengma: '그래픽 인증 코드를 입력하세요.',
    qingshuruyanzhengma: '인증번호를 입력해주세요',
    huoquzhuceshoujihaoshibai: '등록된 휴대폰 번호를 가져오지 못했습니다.',
    qingxianwanchengshoujirenzheng: '먼저 휴대폰 인증을 완료해주세요.',
    huoqushoujiyanzhengmashibai: '휴대폰 인증코드 획득에 실패했습니다.',
    huoqushoujiyanzhengma: '휴대폰 인증코드 받기',
    houchongxinfasong: 'S 이후 재전송',
    mimacuowu: '잘못된 비밀번호',
    liangcixinmimashurubuyizhiqingjiancha: '입력한 두 개의 새 비밀번호가 일치하지 않습니다. 확인하세요.',
    xinmimaheyuanmimabunengyiyang: '새 비밀번호는 원래 비밀번호와 같을 수 없습니다.',
    qingshurushoujiyanzhengma: '휴대폰 인증번호를 입력해주세요',
    yanzhengmacuowu: '인증코드 오류',
    denglumimaxiugaichenggong: '로그인 비밀번호가 성공적으로 변경되었습니다.',
    denglumimaxiugaishibai: '로그인 비밀번호 수정 실패',
    xiugaizijinmima: '펀드 비밀번호 변경',
    qingshuruzijinmima: '펀드 비밀번호를 입력해주세요',
    qingzaicishuruzijinmima: '펀드 비밀번호를 다시 입력해주세요',
    liangcizijinmimabuyizhiqingjiancha: '두 펀드 비밀번호가 일치하지 않습니다. 확인해주세요.',
    shezhizijinmimachenggong: '펀드 비밀번호 설정 성공',
    shezhizijinmimashibai: '펀드 비밀번호 설정 실패',
    xiugaizijinmimachenggong: '펀드 비밀번호가 성공적으로 수정되었습니다',
    xiugaizijinmimashibai: '펀드 비밀번호 변경 실패',
    youxiangrenzheng: '이메일 인증',
    huoquyouxiangyanzhengma: '이메일 인증 코드 받기',
    qingshuruyouxiangdizhi: '이메일 주소를 입력해주세요',
    huoquyouxiangyanzhengmashibai: '이메일 인증 코드를 받지 못했습니다.',
    qingshuruyouxiangyanzhengma: '이메일 인증코드를 입력해주세요',
    youxiangyanzhengchenggong: '이메일 확인 성공',
    weirenzheng: '인증되지 않음',
    queding: '확신하는',
    anquanshezhiguanli: '보안 설정 관리',
    denglumima: '로그인 비밀번호',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: '로그인 비밀번호를 설정하시면, 귀하의 계정 비밀번호를 사용하여 직접 로그인하실 수 있습니다.',
    xiugai: '개정하다',
    zijinmima: '펀드 비밀번호',
    yongyubaohuzijinanquan: '자금의 안전을 보호하기 위해 사용됩니다.',
    shezhi: '설정',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: '로그인, 코인 출금, 비밀번호 조회, 보안 설정 수정 시 보안 확인을 위해 사용됩니다.',
    yirenzheng: '인증됨',
    renzheng: '인증',
    shimingrenzheng: '실명인증',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: '코인 구매, 판매, 출금, 입금, 송금 시 보안 인증을 위해 사용됩니다.',
    daishenhe: '검토 대기 중',
    yibohui: '해고됨',
    yuandenglumima: '원래 로그인 비밀번호',
    xinmima: '새 비밀번호',
    quedingxinmima: '새 비밀번호 확인',
    tuxingyanzhengma: '그래픽 인증 코드',
    yanzhengma: '인증코드',
    zijinmima: '펀드 비밀번호',
    querenzijinmima: '펀드 비밀번호 확인',
    youxiangdizhi: '이메일 주소',
    qingxianwanchengyouxiangrenzheng: '먼저 이메일 확인을 완료해주세요.',
    xiugaitouxiangchenggong: '아바타가 성공적으로 수정되었습니다.',
    xiugaitouxiangshibai: '아바타를 수정하지 못했습니다.',
    shangchuantouxiangzhinengshijpggeshi: '아바타 사진은 JPG 형식으로만 업로드할 수 있습니다!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: '업로드되는 아바타 이미지의 크기는 2MB를 초과할 수 없습니다!',
    },
    verification: {
    fanhui: '반품',
    shimingrenzheng: '실명인증',
    qingshuruxingming: '이름을 입력하세요',
    qingshuruzhengjianhaoma: '귀하의 ID 번호를 입력해주세요',
    huoquyonghuxinxishibai: '사용자 정보를 가져오지 못했습니다.',
    wenjiangeshicuowu: '파일 형식 오류',
    wenjianbunengdayu5M: '파일은 5M보다 클 수 없습니다.',
    qingtianxiexingming: '이름을 입력해주세요',
    qingtianxiezhengjianhaoma: 'ID번호를 입력해주세요.',
    qingshangchuanzhengjianzhengmian: '신분증 앞면을 업로드해주세요.',
    qingshangchuanzhengjianfanmian: '신분증 뒷면을 업로드해주세요.',
    tijiaochenggong: '제출 성공',
    kaiqishenfenrenzheng: '신원 인증 켜기',
    bohuiyuanyin: '거부 이유',
    qingtianxiejiashizhizhaogongminkahuzhao: '운전면허증/시민권/여권 정보를 입력해주세요.',
    xingming: '이름',
    zhengjianhaoma: 'ID 번호',
    qingshangchuanjiashizhizhaogongminkahuzhao: '운전면허증/시민권/여권 정보를 업로드해주세요.',
    shangchuanzhengjianzhengmian: '신분증 앞면 업로드',
    huotuozhuafangru: '아니면 드래그 앤 드롭',
    shangchuanzhengjianfanmian: '증명서 뒷면을 업로드하세요.',
    tijiao: '제출하다',
    },
    invite: {
    yaoqinghaoyoudejiangli: '친구를 초대하고 보상을 받으세요',
    yongjiukede: '영구적으로 사용 가능',
    jiaoyifanyong: '리베이트 거래',
    fasongyaoqinggeihaoyou: '친구에게 초대장 보내기',
    tongguofenxianglianjieyaoqinghaoyouzhuce: '링크를 공유하여 BCFX에 등록하도록 친구를 초대하세요',
    haoyouzhuce: '친구등록',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: '친구가 초대를 수락하고 등록을 완료하며 거래를 진행합니다.',
    huodexiangyingbilijiangli: '해당 비례 보상 받기',
    qingsonghuodejiaoyishouxufeijianglifuli: '거래수수료 보너스 혜택을 간편하게 받으세요',
    wodezhuanshufenxiangfangshi: '나만의 공유 방법',
    yaoqinglianjie: '초대링크',
    jiazaizhong: '로드 중...',
    fuzhilianjie: '링크 복사',
    yaoqingma: '초대코드',
    fuzhiyaoqingma: '초대 코드 복사',
    wodeyaoqing: '내 초대장',
    yaoqingrenshu: '초대된 사람의 수',
    huodejiangli: '보상 받기',
    huodongxize: '이벤트 세부정보',
    tips1: '플랫폼 사용자 간의 관계는 다음과 같이 세 가지 수준으로 구성됩니다. ABCD 사용자는 세 가지 수준의 사용자를 구성하며 A가 가장 높은 수준입니다!',
    tips2: '계층간 이해관계',
    tips201: '。',
    tips3: '현금보상 : A고객은 B고객 계약거래수수료 40% + C고객 계약거래수수료 20% + D고객 계약거래수수료 10%를 받을 수 있다.',
    tips4: '예: 고객 A에는 1차 고객 B 10명, 2차 고객 C 10명, 3차 고객 D 10명이 있습니다. 각 고객 계약이 100,000 USDT를 거래하는 경우',
    tips5: '그러면 고객 A가 받을 수 있는 반품은',
    tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 USDT 현금 보상, 실시간 출금 가능',
    tips7: '활동이 조정되면 BCFX 플랫폼 업데이트가 적용되며 최종 해석권은 BCFX에 있습니다.',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '그래픽 인증 코드를 받지 못했습니다. 다시 요청하세요.',
    qingshuruyouxiang: '이메일을 입력해주세요',
    qingshurutuxingyanzhengma: '그래픽 인증 코드를 입력하세요.',
    qingshuruyanzhengma: '인증번호를 입력해주세요',
    qingshurunindemima: '비밀번호를 입력해주세요',
    qingzaicishurunindemima: '비밀번호를 다시 입력해주세요',
    qingshuruzhifumima: '결제 비밀번호를 입력해주세요',
    xuantian: '선택 과목',
    bitian: '필수의',
    yonghuzhuce: '사용자 등록',
    huanyingdenglu: '로그인을 환영합니다',
    guanfangwangzhan: '공식 웹사이트',
    xiangmumingcheng: 'BCFX',
    youxiang: '우편',
    tuxingyanzhengma: '그래픽 인증 코드',
    yanzhengma: '인증코드',
    huoquyanzhengma: '인증코드',
    shezhimima: '비밀번호 설정',
    quedingmima: '비밀번호 확인',
    zhifumima: '결제 비밀번호',
    yaoqingma: '초대코드',
    zhuce: '등록하다',
    yiyouzhanghao: '이미 계정이 있나요?',
    denglu: '로그인',
    qingshuruyouxiangdizhi: '이메일 주소를 입력해주세요',
    huoqushibai: '획득하지 못했습니다.',
    houchongxinfasong: 'S 이후 재전송',
    liangcishurudemimabuyizhi: '두 번 입력한 비밀번호가 일치하지 않습니다.',
    zhucechenggong: '등록 성공',
    zhuceshibai: '등록 실패',
    },
    treasure:{
    jichubizhong: '기본 통화',
    shouyibizhong: '수익 통화',
    wakuangtianshu: '채굴일',
    shouyitianshu: '적립 일수',
    suodingtianshu: '잠금일',
    yichanshengshouyi: '수익 창출',
    yuqishouyi: '예상 수익',
    kaishishijian: '시작 시간',
    jieshushijian: '종료 시간',
    qingshuruninyaosuodingdebizhongshuliang: '잠그려는 통화의 USDT 금액을 입력하세요.',
    huoqushibai: '획득하지 못했습니다.',
    meiyitian: '1일마다',
    dakai: '열려 있는',
    yuanyuzhoukuangchi: '메타버스 마이닝 풀',
    jilu: '기록',
    kuangchixiangqing: '마이닝 풀 세부 정보',
    suo: '잠그다',
    yitianchanchu: '1일 출력',
    zuixiaosuodingshijian: '최소 잠금 시간',
    nindeUSDT: '귀하의 USDT',
    tian: '하늘',
    quanbu: '모두',
    suoUSDTbingkaishishengchan: 'USDT를 잠그고 생산을 시작하세요',
    ninquedingyaosuoUSDTkaishishengchanma: 'USDT를 잠그고 생산을 시작하시겠습니까?',
    tishi: '힌트',
    queding: '확신하는',
    quxiao: '취소',
    kaishishengchan: '생산을 시작하세요!',
    yiquxiao: '취소',
    },
    subscription:{
    rengouliebiao:'구독 목록',
    jijiangdaolai:'곧 출시 예정',
    jinxingzhong:'진행 중',
    quanbu:'모두',
    keshiyonghuobi:"사용 가능한 통화",
    zongshuliang:'총 수량',
    meiyouxianzhi:'제한 없음',
    shengyushuliang:'남은 수량',
    kaishishijian:'시작 시간',
    zhuangtai:'상태',
    jilu:'기록',
    zanwujilu:"아직 기록이 없습니다",
    leixing:'유형',
    shuliang:'수량',
    goumaijine:'구매 금액',
    shijian:'시간',
    goumai:'구입하다',
    duichu:'兑出',
    jiangli:'상',
    tibi:'코인 출금',
    huidaomulu:'목차로 돌아가기',
    dangqianchiyou:'현재 개최 중',
    keyong:'사용 가능',
    keduihuan:'상환 가능',
    shishihangqing:'실시간 견적',
    yue:'균형',
    biaoti:'제목',
    fashoushuliang:'판매 수량',
    jindu:"일정",
    goumaishuliang:'구매 수량',
    ge:'개인',
    woyiyuedu:'나는 읽었다',
    daibigoumaixieyi:'토큰 구매 계약',
    qingshurugoumaijine:'구매금액을 입력해주세요',
    shifoyaogoumai:'사고 싶나요?',
    tishi:'힌트',
    queding:'확신하는',
    quxiao:'취소',
    yjs:'끝났다',
    ywc:'완전한',
    sgjg:'구독 가격',
    jssj:"종료 시간",
    zqsl:"당첨 수량",
    zqje:"당첨금액",
    tian:"하늘",
    shi:'시간',
    fen:'가리키다',
    miao:'두번째',
    shengou:'신청',
    xmxq:'프로젝트 세부정보',
    },
    }