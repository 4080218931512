<template>
  <footer>
    <!-- <div class="foot1">
        <div class="foot1_left">
            <div class="fl1">  {{i18n('bangzhuzhongxin')}} </div>
            <div class="fl2">  {{i18n('dianhuazixun')}} <i>  {{i18n('dianhuahegongzuoshijian')}} </i></div>
            <div class="fl3"><span>  {{i18n('liaotianzixun')}} </span> <span>  {{i18n('keketuozixun')}} </span> <span>  {{i18n('zaixianbangzhu')}} <i>  {{i18n('h24kaifang')}} </i></span></div>
            <div class="fl4">
                <span>  {{i18n('lixianbangzhu')}} </span>
                <div>
                    <p>  {{i18n('gongsidizhi')}} </p>
                    <p>  {{i18n('gongzuoshijian')}} </p>
                </div>
            </div>

            <div class="fl5">
                <p>(주)코인원 | 대표이사 차명훈 | 사업자등록번호 261-81-07437</p>
                <p>서울특별시 용산구 한강대로 69 (한강로2가, 용산푸르지오써밋) 4층, 코인원 (우 04378)</p>
            </div>

            <div class="fl6">
                <span>  {{i18n('shangshizixun')}} </span> <span>  {{i18n('hezuozixun')}} </span>
            </div>
        </div>
        <div class="foot1_right">
            <ul>
                <li>
                    <p>  {{i18n('gongsi')}} </p>
                    <a href="https://help.coin-one.one/detail.html?id=578&fid=26&fcolumn=%E6%96%B0%E6%89%8B%E5%BF%85%E8%AF%BB&gfid=17&gfcolumn=%E6%96%B0%E6%89%8B%E6%8C%87%E5%8D%97" target="_blank">  {{i18n('lianxiwomwn')}} </a>
                   
                </li>
                <li>
                    <p>  {{i18n('zhidao')}} </p>
                    <a href="https://help.coin-one.one/listClass.html?id=6&column=%E6%96%B0%E6%89%8B%E6%8C%87%E5%8D%97" target="_blank"> {{i18n('yonghuzhichi')}} </a>
                    <a href="https://help.coin-one.one/listClass.html?id=6&column=%E6%96%B0%E6%89%8B%E6%8C%87%E5%8D%97" target="_blank"> {{i18n('zhanghuzhinan')}} </a>
                    <a href="">  {{i18n('ruanjianxiazai')}} </a>
                </li>
                <li>
                    <p>  {{i18n('zhengce')}} </p>
                    <a href="https://help.coin-one.one/detail.html?id=568&fid=26&fcolumn=%E6%96%B0%E6%89%8B%E5%BF%85%E8%AF%BB&gfid=17&gfcolumn=%E6%96%B0%E6%89%8B%E6%8C%87%E5%8D%97" target="_blank">  {{i18n('fuwuyuyinsitiaokuan')}} </a>
                </li>
            </ul>
        </div>
    </div> -->
    <div class="foot2">
        <p>Copyright ©  BCFX, Inc. All rights reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footerLayout",
  methods: {
      handleImageError() {
        // 为了增加鲁棒性，这里使用this.$el.src而不是直接修改属性
        // 适用于Vue的编译器语法，确保在Vue的环境中正确处理DOM元素
        this.$el.src = '../../assets/error.png';
      },
  },
  computed: {
    i18n() {
      return function(name) {
        return this.$t("footer")[name]
      }
	},
  }
}
</script>

<style scoped lang="scss">
  footer{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        padding: 148px 0px 48px 0;
        font-size: 14px;
    }
    footer i{
        font-style: normal;
    }

  .foot1_left,
    .foot1_right{
        width: 50%;
        float: left;
        padding-bottom: 30px;
    }
    .foot1_left>div{
        margin-bottom: 12px
    }
    .fl1{
        color: #484d55;
        font-size: 14px;
        font-weight: 700;
    }
    .fl2{
        color: #79818f;
        font-size: 12px;
        font-weight: 700;
    }
    .fl2 i{
        margin: 0 0 0 8px;
        text-align: left;
        color: #aeb3bb;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
    }
    .fl3 span{
        font-weight: 700;
        color: #79818f;
        font-size: 12px;
        margin-right:10px;
        position: relative;
    }
    .fl3 span:after{
        content:"";
        width: 1px;
        height: 10px;
        background-color: #aeb3bb;
        position: absolute;
        right: -7px;
        top: 4px;
    }
    .fl3 span:last-child:after{
        display: none;
    }
    .fl3 i{
        color: #aeb3bb;
        font-size: 12px;
    }
    .fl4{
        display: flex;
        align-items: baseline;
        flex-direction: row;
        padding-top: 10px;
    }
    .fl4 span{
        color: #79818f;
        font-size: 12px;
        font-weight: 700;
    }
    .fl4 p{
            margin: 0 0 0 8px;
    text-align: left;
    color: #aeb3bb;
    font-size: 12px
    }
    .fl5{
            margin: 16px 0 0;
        font-size: 12px;
        color: #aeb3bb;
    }
    .fl6{
        font-weight: 700;
        color: #aeb3bb;
    }
    .fl6 span{
        font-weight: 700;
        color: #79818f;
        font-size: 12px;
        margin-right:10px;
        position: relative;
    }
    .fl6 span:after{
        content:"";
        width: 1px;
        height: 10px;
        background-color: #aeb3bb;
        position: absolute;
        right: -7px;
        top: 4px;
    }
    .fl6 span:last-child:after{
        display: none;
    }
    .foot1_right{
        display: flex;
    }
    .foot1_right ul{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .foot1_right li{
        min-width: 165px;
        padding: 0 35px 0 0;
    }
    .foot1_right li p{
        font-size: 14px;
        color: #484d55;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .foot1_right li a{
        display: block;
        font-size: 13px;
        word-break: break-word;
        color: #79818f;
        margin-bottom: 8px;
    }
    .foot2{
        clear: both;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-top: 1px solid #e4e5e8;
        padding-top: 12px;
        color: #aeb3bb;
    font-size: 12px;
    }

</style>
