<template>
    <div class="invite">
        <div class="showhead pos_relative">
            <!-- <img class="zuoxia" src="@/assets/images/invite/zuoxia.png" alt="">
            <img class="zuoshang" src="@/assets/images/invite/zuoshang.png" alt="">
            <img class="youshang" src="@/assets/images/invite/youshang.png" alt=""> -->
            <div class="flex_row_center centerpart">
                <!-- <img class="wenzi" src="./images/invite/wenzi.png" alt=""> -->
                <div class="flex_col_center">
                    <div class="btitle"> {{ i18n('yaoqinghaoyoudejiangli') }} </div>
                    <!-- <div class="backmoney flex_row_center">
                        <div> {{ i18n('yongjiukede') }} </div>
                        <span class="backnum"> {{ inviteRate }} </span>
                        <div> {{ i18n('jiaoyifanyong') }} </div>
                    </div> -->
                </div>
                <img class="chahua" src="@/assets/images/invite/chahua.png" alt="">
            </div>
        </div>
        <div class="showstep">
            <div class="flex_row_between allstep">
                <div class="step ">
                    <img class="stepicon" src="@/assets/images/invite/yaoqing.png" alt="">
                    <div>
                        <div class="stitle"> {{ i18n('fasongyaoqinggeihaoyou') }} </div>
                        <div class="ssubtitle"> {{ i18n('tongguofenxianglianjieyaoqinghaoyouzhuce') }} </div>
                    </div>
                </div>
                <div class="step ">
                    <img class="stepicon" src="@/assets/images/invite/zhuce.png" alt="">
                    <div>
                        <div class="stitle"> {{ i18n('haoyouzhuce') }} </div>
                        <div class="ssubtitle"> {{ i18n('haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi') }} </div>
                    </div>
                </div>
                <div class="step ">
                    <img class="stepicon" src="@/assets/images/invite/huojiang.png" alt="">
                    <div>
                        <div class="stitle"> {{ i18n('huodexiangyingbilijiangli') }} </div>
                        <div class="ssubtitle"> {{ i18n('qingsonghuodejiaoyishouxufeijianglifuli') }} </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="invitepart">
            <div class="ititle"> {{ i18n('wodezhuanshufenxiangfangshi') }} </div>
            <div class="flex_row_left">
                <div>
                    <div> {{ i18n('yaoqinglianjie') }} </div>
                    <div class="copypart flex_row_between">
                        <div class="link flex1 text-overflow_1"> {{ httpurl ? httpurl : i18n('jiazaizhong') }} </div>
                        <div class="copy" @click="copyaddress(1)"> {{ i18n('fuzhilianjie') }} </div>
                    </div>
                </div>
                <div style="margin-left: 40px;">
                    <div> {{ i18n('yaoqingma') }} </div>
                    <div class="copypart flex_row_between" style="width: 330px;">
                        <div class="link flex1 text-overflow_1"> {{ invitecode ? invitecode : i18n('jiazaizhong') }} </div>
                        <div class="copy" @click="copyaddress(2)"> {{ i18n('fuzhiyaoqingma') }} </div>
                    </div>
                </div>
            </div>
            <div class="ititle"> {{ i18n('wodeyaoqing') }} </div>
            <div class="flex_row_left">
                <div>
                    <div class="numtitle"> {{ i18n('yaoqingrenshu') }} </div>
                    <div class="num"> {{friends ? friends : '0'}} </div>
                </div>
                <div style="margin-left: 135px;">
                    <div class="numtitle"> {{ i18n('huodejiangli') }}(USDT)</div>
                    <div class="num"> {{commission ? commission : '0'}} </div>
                </div>
            </div>
        </div>
        <div class="rules">
            <div class="rtitle"> {{ i18n('huodongxize') }} </div>
            <div class="rulespart">
                <div> {{ i18n('tips1') }} </div>
                <div> {{ i18n('tips2') }} <i class="back_num"> {{ inviteRate }} </i> {{ i18n('tips201') }} </div>
                <div> {{ i18n('tips3') }} </div>
                <div> {{ i18n('tips4') }} </div>
                <div> {{ i18n('tips5') }}{{ i18n('tips6') }}  </div>
                <div> </div>
                <div class="tip"> {{ i18n('tips7') }} </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClipboardJS from 'clipboard';

export default {
    data() {
        return {
            httpurl: undefined,
            invitecode: undefined,
            inviteRate: undefined,
            friends: undefined,
            commission: undefined,
        };
    },
    mounted() {
        
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getInfo();
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getInfo();
        };
    },
    computed: {
        i18n() {
            return function(name) {
                return this.$t("invite")[name];
            }
		},
    },
    methods: {
        copyaddress(i) {
            var copytext = '';
            if (i === 1) {
                copytext = this.httpurl;
            } else {
                copytext = this.invitecode;
            }

            const clipboard = new ClipboardJS('.copy', {
                text: () => copytext
            });
            
            clipboard.on('success', (e) => {
                e.clearSelection();
            });

            clipboard.on('error', (e) => {
                console.error('Action failed', e.action);
            });
        },
        getInfo() {
            this.myInvitCode();
            this.myInvitee();
        },
        async myInvitCode() {
            let data = {}
            this.$api.myInvitCode(data).then((res)=>{
                if(res.data.code == 200){
                    this.httpurl = res.data.data.inviteUrl;
                    this.invitecode = res.data.data.inviteCode;
                    this.inviteRate = res.data.data.inviteRate;
                }
            });
        },
        async myInvitee() {
            let data = {}
            this.$api.myInvitCode(data).then((res)=>{
                if(res.data.code == 200){
                    this.friends = res.data.data.friends;
                    this.commission = res.data.data.commission;
                }
            });
        },
    },
}
</script>

<style scoped>
.invite {
    padding-bottom: 36px;
}

.showhead {
    width: 100%;
    min-width: 1200px;
    height: 300px;
    background: #FAFAFA;
}

.zuoxia {
    width: 8.7%;
    height: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 398px;
}

.zuoshang {
    width: 13%;
    height: auto;
    position: absolute;
    left: 13%;
    top: 60px;
}

.youshang {
    width: 19.8%;
    height: auto;
    position: absolute;
    right: 0;
    top: 60px;
}

.centerpart {
    min-width: 1200px;
    height: 300px;
}

.wenzi {
    width: 317px;
    height: auto;
    z-index: 9;
}

.chahua {
    width: 320px;
    height: auto;
    margin-left: 288px;
    z-index: 9;
}
.step{
    text-align: center;
}
.showstep {
    width: 100%;
    min-width: 1200px;
    height: 250px;
    background: white;
}

.allstep {
    width: 1200px;
    height: 250px;
    margin: 0 auto;
}

.stepicon {
    width: 158px;
    height: 158px;
    margin-right: 16px;
}

.stitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 35px;
}

.ssubtitle {
    font-size: 14px;
    font-weight: 500;
    color: #555555;
    line-height: 35px;
}

.invitepart {
    width: 1200px;
    height: 315px;
    border: 1px solid #EEEEEE;
    margin: 40px auto;
    padding: 0 50px;
    box-sizing: border-box;
}

.ititle {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 80px;
}

.copypart {
    height: 40px;
    border: 1px solid #E5E5E5;
    border-radius: 2px;
    line-height: 40px;
    overflow: hidden;
    margin-top: 12px;
    margin-bottom: 10px;
}

.link {
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    padding-left: 16px;
}

.copy {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #2794f8 ;
    line-height: 40px;
    padding: 0 20px;
    cursor: pointer;
}

.numtitle {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
}

.num {
    font-size: 20px;
    font-family: Arial;
    font-weight: 400;
    color: #2794f8;
    line-height: 55px;
}

.rules {
    width: 1200px;
    height: 287px;
    border: 1px solid #E6ECF2;
    margin: 0 auto;
}

.rtitle {
    width: 100%;
    height: 56px;
    background: #F2F6FA;
    line-height: 56px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    text-indent: 50px;
}

.rulespart {
    padding: 26px 50px;
    font-size: 14px;
    font-weight: 500;
    color: #999999;
    line-height: 24px;
}

.tip {
    margin-top: 18px;
}

.btitle {
    font-size: 45px;
    font-family: PingFang SC;
    line-height: 50px;
    margin-bottom: 20px;
}

.backmoney {
    width: 313px;
    height: 52px;
    background-image: url(@/assets/images/invite/banner-text.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    font-size: 19px;
    font-family: PingFang SC;
    color: #FFFFFF;
    line-height: 52px;
    text-align: center;
}

.backnum {
    font-size: 30px;
    font-family: Arial;
    color: #F7B75D;
    line-height: 22px;
    margin: 0 12px;
}

.text-overflow_1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex_row_around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_row_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>
