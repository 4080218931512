import Vue from 'vue'
import VueI18n from 'vue-i18n';
import en from './locales/en.js'
import zhCN from './locales/zh-CN.js'
import  ko from './locales/ko.js'
import jp from './locales/jp.js'
import tc from './locales/tc.js'
import  ar from './locales/ar.js'
import de from './locales/de.js'
import  es from './locales/es.js'
import fa from './locales/fa.js'
import fr from './locales/fr.js'
import  hi from './locales/hi.js'
import pl from './locales/pl.js'
import  pt from './locales/pt.js'
import ru from './locales/ru.js'
import tr from './locales/tr.js'
import ur from './locales/ur.js'
import enLocale from 'element-ui/lib/locale/lang/en.js'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN.js'
import Cookies from 'js-cookie'

const messages = {
    en:{
        ...en,
        ...enLocale
    },
    zh:{
        ...zhCN,
        ...zhLocale
    },
    ko:{
        ...ko
    },
    tc:{
        ...tc
    },
    jp:{
        ...jp
    },
    ar: {
        ...ar
    },
    de: {
        ...de
    },
    es:{
        ...es
    },
    fa:{
        ...fa
    },
    fr:{
        ...fr
    },
    hi: {
        ...hi
    },
    pl: {
        ...pl
    },
    pt:{
        ...pt
    },
    ru:{
        ...ru
    },
    tr:{
        ...tr
    },
    ur:{
        ...ur
    }
    // 其他语言...
};

Vue.use(VueI18n);

// 创建i18n实例
// let lang = localStorage.getItem('lang');
// let locale = lang =='en_US'?'en':'zh';

// 给一个函数用来获取cookie里存储的语言
export function getLanguage() {
    const chooseLanguage = localStorage.getItem('lang');
    if (chooseLanguage) return chooseLanguage

    // 如果没有找到存储语言 已浏览器国际语言为准
    const language = (navigator.language || navigator.browserLanguage).toLowerCase()
    //定义对应的语言键 展开对应的键值对应表
    const locales = Object.keys(messages)
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
        return locale
        }
    }
    return 'zh'
};

const i18nInstance = new VueI18n({
    locale: getLanguage(), // 设置初始语言
    messages, // 从i18n导入的消息对象
    silentFallbackWarn: true
});

export default i18nInstance