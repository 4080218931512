<template>
    <div>
        <!-- <div class="assets">
            <div class="head-part">
                <div class="head-top">
                    <div class="flex_col_center" style="align-items: flex-start;">
                        <div class="title"> {{ i18n('zongzichan') }} (USDT)</div>
                        <div class="title-desc"> {{ userWallet.marginBalance + userWallet.LegalCurrency | filter_moneyFormat(8) }} </div>
                    </div>
                    <div class="flex_row_right flex1">
                        <div class="btn" @click="rechargeClick"> {{ i18n('chongbi') }} </div>
                        <div class="btn" @click="withdrawClick"> {{ i18n('tibi') }} </div>
                        <div class="btn" @click="showTrans"> {{ i18n('huazhuan') }} </div>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div class="flex_row_between">
                    <div class="scard flex_col_center">
                        <div class="flex_row_between width100">
                            <div class="color_666 fontSize20 flex1"> {{ i18n('fabizhanghu') }} (USDT)</div>
                            <span class="flex_row_left color_green bill" @click="BasicsAccountRecordClick">
                                <i class="el-icon-document"></i>
                                {{ i18n('jiayiliushui') }}
                            </span>
                        </div>
                        <div class="hyzh fontSize25"> {{ userWallet.LegalCurrency | filter_moneyFormat(8) }} </div>
                    </div>
                </div>
                
                <div class="flex_row_between" style="margin-top: 20px;">
                    <div class="scard flex_col_center">
                        <div class="flex_row_between width100">
                            <div class="color_666 fontSize20 flex1"> {{ i18n('heyuezhanghu') }} (USDT)</div>
                            <span class="flex_row_left color_green bill" @click="ContractAccountRecordClick">
                                <i class="el-icon-document"></i>
                                {{ i18n('jiayiliushui') }}
                            </span>
                        </div>
                        <div class=" usdtzh fontSize25"> {{ userWallet.marginBalance | filter_moneyFormat(8) }} </div>
                    </div>
                </div>
                
            </div>

        </div> -->
        <div class="treasure" >
            <div class="head-part">
                <div style="display: flex; justify-content: center;">
                    <div class="head-top">
                        <div class="title font_bold" style="align-items: flex-start;"> {{ i18n('zczl') }} </div>
                        <img style="margin-top: 40px; margin-right: 32px;" :src="require('@/assets/images/assets_bak.png')" alt="">
                    </div>
                </div>
            </div> 
            <div style="width: 75%;margin:0 auto;padding:20px 0">
                <el-tabs v-model="activeName" >
                    <el-tab-pane :label="i18n('zczl')" name="first">
                        <div>
                            <div class="first_box" >
                                <div style="display:flex;justify-content: space-between;">
                                    <div>
                                        <div style="color:#777777">{{ i18n('zzczh') }}</div>
                                        <div  style="font-size:30px;font-weight:600;margin-top:15px">≈{{total | formatNumber}}</div>
                                    </div>
                                    <div>
                                        <div style="display: flex;">
                                            <div class="btn" @click="rechargeClick"> {{ i18n('chongbi') }} </div>
                                            <div class="btn" style="background:#0E68FB;color:white" @click="withdrawClick"> {{ i18n('tibi') }} </div>
                                            <div class="btn" @click="showTrans"> {{ i18n('huazhuan') }} </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display:flex;justify-content: space-between;margin-top:70px">
                                    <div>
                                        <div>
                                            <div style="color:#777777">{{ i18n('ztzc') }}<span style="color:#B9B9B9;font-size:14px"> (USDT)</span></div>
                                            <div  style="font-size:26px;font-weight:600;margin-top:15px">{{syBalance.transitAssets | formatNumber}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div style="color:#777777">{{ i18n('djzc') }}<span style="color:#B9B9B9;font-size:14px"> (USDT)</span></div>
                                            <div  style="font-size:26px;font-weight:600;margin-top:15px">{{syBalance.miningFunds | formatNumber}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div style="color:#777777">{{ i18n('zyzc') }}<span style="color:#B9B9B9;font-size:14px"> (USDT)</span></div>
                                            <div  style="font-size:26px;font-weight:600;margin-top:15px">{{syBalance.pledgedAssets | formatNumber}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div style="color:#777777">{{ i18n('cwzc') }}<span style="color:#B9B9B9;font-size:14px"> (USDT)</span></div>
                                            <div  style="font-size:26px;font-weight:600;margin-top:15px">{{parseFloat(deposit_hold)+parseFloat(frozen_deposi)| formatNumber}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="i18n('jyzh')" name="second">
                        <el-table
                            :data="fucWalletList"
                            stripe
                            style="width: 100%">
                            <el-table-column
                            prop="coinCodeNo"
                            :label="i18n('bizhong')"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="balance"
                            :label="i18n('keyong')"
                           >
                            </el-table-column>
                            <el-table-column
                            :label="i18n('dongjie')"
                            prop="balance"
                            >
                            <template slot-scope="scope">
                               <span v-if="scope.row.coinCodeNo=='USDT'">{{parseFloat(syBalance.pledgedAssets)+parseFloat(syBalance.miningFunds)| formatNumber}}</span> 
                               <span v-else>0</span> 
                            </template>
                           
                            </el-table-column>
                            <el-table-column
                            prop="profit"
                            :label="i18n('lirun')">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane :label="i18n('hyzh')" name="third">
                        <el-table
                            :data="fucWalletListUsdt"
                            stripe
                            style="width: 100%">
                            <el-table-column
                            prop="coinCodeNo"
                            :label="i18n('bizhong')"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="balance"
                            :label="i18n('keyong')"
                           >
                           <template >{{canuseAddDeposit | formatNumber}}</template>
                            </el-table-column>
                            <el-table-column
                            :label="i18n('dongjie')">
                                <template >{{parseFloat(deposit_hold)+parseFloat(frozen_deposi) | formatNumber}}</template>
                            </el-table-column>
                            <el-table-column
                            :label="i18n('lirun')">
                            <template >0</template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <!-- 划转 -->
        <el-dialog :title="i18n('dialogzichanhuazhuan')" class="dialogtransfer assetsdialog" :visible.sync="dialogtransfer" width="450px">
            <!-- 从到 -->
            <div class="flex_row_around color_aaa">
                <div class="flex1"> {{ i18n('cong') }} </div>
                <div class="transfericon"></div>
                <div class="flex1"> {{ i18n('dao') }} </div>
            </div>
            <div class="flex_row_around">
                <div class="from flex_row_left flex1">
                    <div class="flex1" style="color: #000;"> {{ transferfromvalue }} </div>
                </div>
                <img class="transfericon" :src="require('@/assets/images/transfer_b.png')" alt="" @click="transfericonClick">
                <div class="to flex_row_left flex1">
                    <div class="flex1" style="color: #000;"> {{ transfertovalue }} </div>
                </div>
            </div>
            <!-- 数量 -->
            <div class="color_aaa"> {{ i18n('shuliang') }} </div>
            <div class="flex_row_left trsnum">
                <el-input-number v-model="transferNum" size="mini" 
                    :precision="8" :step="1" :min="1" :max="transdirection === 0 ? this.userWallet.LegalCurrency : this.userWallet.marginAvailable"
                    style="margin-left: 20px; background-color: #fff; width: 60%; margin-right: 20px;" 
                    :placeholder="i18n('qingshuruhuazhuanshuliang')"></el-input-number>
                <div class="currency-show" style="color: #000;">USDT</div>
                <div class="color_green tarnsall" style="margin-left: 10px; cursor: pointer;" @click="transferAllclick"> {{i18n('quanbu')}} </div>
            </div>
            <div class="fontSize12 color_aaa tipss"> {{i18n('keyong')}} {{ (transdirection === 0 ? this.userWallet.LegalCurrency : this.userWallet.marginAvailable) | filter_moneyFormat(8) }} </div>
            <div class="transferbtn" @click="transferbtnclick"> {{i18n('huazhuan')}} </div>
        </el-dialog>
    </div>
</template>

<script>
import { moneyFormat } from '@/utils/filters.js'
import { teadeWSReq } from '@/utils/packedTrade'
import { baseMethods } from '@/utils/methods.js'
export default {
    data() {
        return {
            userWallet: this.$store.state.userWallet,
            dialogtransfer: false,
            transferfromvalue: this.$t('assets')['fabizhanghu'],
            transfertovalue: this.$t('assets')['heyuezhanghu'],
            transferNum: undefined,
            transdirection: 0,
            walletZt:'',
            walletDj:'',
            total:'',
            fucWalletList:[{'coinCodeNo':'USDT','balance':'0','profit':'0'}],
            fucWalletListUsdt:[{'coinCodeNo':'USDT','balance':'0','profit':'0'}],
            activeName: 'first',
            syBalance:{},
        };
    },
    mounted() {
        this.$root.$on('TS2CTransferCapitalRes', (msg) => {
            if (this.dialogtransfer) {
                if (msg.Resultcode == 0) {
                    this.$message({
                        message: this.$t('assets')['zijinhuazhuanchenggong'],
                        type: 'success'
                    });
                    this.$store.dispatch('getMyLegalCurrencyAccountInfo');
                    this.transferNum = undefined;
                    this.dialogtransfer = false;
                } else {
                    this.$message({
                        message: this.$t('assets')['zijinhuazhuanshibai'],
                        type: 'success'
                    });
                }
            }
        });
        this.$root.$on('tradelogin', (message) => {
            if (message.loginStatus) {
                this.getAllMoneyFun()
            } 
        });

        //判断一下套接字的连接状态
        if (this.$store.state.loginStatus) {
            this.getAllMoneyFun()
        };
       
    },
    beforeDestroy() {
        this.$root.$off('TS2CTransferCapitalRes');
    },
    filters: {
        filter_moneyFormat: function (x, n) {
            return moneyFormat(x, n);
        },
        formatNumber(value, decimals) {
            if (!value) return '0.00000000';
            decimals = decimals || 8;
            return parseFloat(value).toFixed(decimals);
        }
    },
    methods: {
        rechargeClick() {
            this.$router.push("/rechargeLayout");
        },
        withdrawClick() {
            this.$router.push("/withdrawLayout");
        },
        showTrans() {
            this.dialogtransfer = true;
        },
        getAllMoneyFun(){
            this.$api.getAllMoney({}).then((res)=>{
                if (res.data.code == 200) {
                    
                    this.syBalance = res.data.data;
                    this.fucWalletList[0].balance = res.data.data.legalBalance
                    this.getFClist()
                }
            })
        },
        getFClist(){
                    this.walletZt = 0;
				    this.walletDj = 0
            this.$api.getUserFucCoinAccounts({}).then((res)=>{
                if (res.data.code == 200) {
					for(let i=0;i< res.data.data.length;i++){
						this.walletZt += parseFloat(res.data.data[i].prevValue)
						this.walletDj += parseFloat(res.data.data[i].currentValue)
                        this.fucWalletList.push(res.data.data[i]);
					}
					this.total = baseMethods.accAdd(baseMethods.accAdd(baseMethods.accAdd(this.syBalance.legalBalance, this.syBalance.miningFunds),baseMethods.accAdd(this.syBalance.pledgedAssets,this.wallet)),this.walletDj); 
                }
            })
        },
        transfericonClick() {
            var temp = this.transfertovalue;
            this.transfertovalue = this.transferfromvalue;
            this.transferfromvalue = temp;
            if (this.transdirection === 0) {
                this.transdirection = 1;
            } else {
                this.transdirection = 0;
            }
            this.transferNum = undefined;
        },
        transferAllclick() {
            if (this.transdirection === 0) {
                if (this.userWallet.LegalCurrency > 0) {
                    this.transferNum = this.userWallet.LegalCurrency;
                } else {
                    this.transferNum = undefined;
                }
            } else {
                if (this.userWallet.marginAvailable > 0){
                    this.transferNum = this.userWallet.marginAvailable;
                } else {
                    this.transferNum = undefined;
                }
            }
        },
        transferbtnclick() {
            if (this.transferNum && this.transferNum > 0) {
                teadeWSReq.exchangeCurrency(this.transdirection, this.transferNum);
            } else {
                this.$message({
                    message: this.$t('assets')['qingshuruhuazhuanshuliang'],
                    type: 'warning'
                });
            }
        },
        BasicsAccountRecordClick() {
            this.$router.push("/BasicsAccountRecordLayout");
        },
        ContractAccountRecordClick() {
            this.$router.push("/contractAccountRecordLayout");
        },
        selectFromChange() {
            this.transferNum = undefined;
        },
    },
  
    computed: {
        i18n() {
            return function(name) {
                // index找到对应页面的obj name是onj的key
                return this.$t("assets")[name];
            }
		},
        deposit_hold() {
            return this.$store.state.userWallet.marginPosition
        },
        frozen_deposi() {
            return this.$store.state.userWallet.marginFrozen
        },
        wallet() {
            return this.$store.state.userWallet.marginBalance;
        },
        canuseAddDeposit(){
            return this.$store.state.userWallet.marginAvailable;
        }
    },
}
</script>


<style scoped>
@import "../css/assetsLayout.css";
.assetsdialog .el-dialog  {
    background: #fff !important;
}
.head-part {
    height: 240px;
    width: 100%;
    min-width: 1200px;
    background-color: #f3f3f3;
}

.head-top {
    width: 1200px;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
}

.title {
    font-size: 36px;
    color: rgb(0, 0, 0);
    line-height: 100px;
    font-weight: 500;
}
.assetsdialog .el-dialog__title {
    color: #000 !important;
    font-weight: 900;
}

.assetsdialog .el-input__inner {
    border: none;
    background: white !important;
    color: #333  !important;
}
.fontSize20 {
    font-size: 20px;
}

.fontSize25 {
    font-size: 25px;
}

.color_green {
    color: #08BD98;
}

.flex_row_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.btn{
    padding: 7px 20px 7px 20px;
    border: 1px solid #0E68FB;
    margin-left: 20px;
    border-radius: 5px;
    color: #0E68FB;
    font-size: 14px;
    margin-top: 10px;
    cursor: pointer;
}
.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex_row_around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex_row_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .assetsdialog .el-input-number__decrease{
    border: unset;
    color: unset;
    background-color: #eee;
}

::v-deep .assetsdialog .el-input-number__increase{
    border: unset;
    color: unset;
    background-color: #eee;
}

::v-deep .assetsdialog .el-input-number__decrease.is-disabled{
    color: #ccc;
}

::v-deep .assetsdialog .el-input-number__increase.is-disabled{
    color: #ccc;
}

::v-deep .assetsdialog .el-input-number.is-disabled .el-input-number__decrease{
    color: #ccc;
}

::v-deep .assetsdialog .el-input-number.is-disabled .el-input-number__increase{
    color: #ccc;
}
.first_box{
    background-color: #f3f3f3;
    margin-top:20px;
    padding:40px;
    font-size: 16px;
}
</style>