export default {
    header: {
        hello: '你好，世界！',
        changeLanguage: '切换语言',
        Login: '登 录',
        Register: '注 册',
        Logout: '退出登录',
        shouye: '首页',
        bibijiaoyisuo: '币币交易',
        heyuejiaoyisuo: '合约交易',
        licaichanpin: '矿池',
        rengou: '申购',
        ruanjianxiazai: '软件下载',
        zhongwen: '中文',
        yingwen: 'En',
        zichan: '资产',
        dingdan: '订单',
        heyuedingdan: '合约订单',
        bibidingdan: '币币订单',
        anquanzhongxin: '安全中心',
        fanyongyaoqing: '返佣邀请',
        tuichudenglu: '退出登录',
        gupiaojiaoyi: '股票交易',
        waihuijiaoyi: '外汇交易',
        qiquanjiaoyi:'期权交易',
        gupiaodingdan: '股票订单',
        waihuidingdan: '外汇订单',
        qiquandingdan: '期权订单',
        jiaoyidingdan:'交易订单',
        daolizhongxin:'代理中心',
        huodong:'活动'
    },
    home: {
        huanyinglaidao: '欢迎来到',
        pingtaimingcheng: '这里',
        mengxiangqihang: '梦想起航',
        yijiankaiqishuzihualvcheng: '一键开启数字化旅程',
        liaojiegengduo: '了解更多',
        chongzhi: '充值',
        jiaoyi: '交易',
        remenbang: '热门榜',
        zhangfubang: '涨幅榜',
        diefubang: '跌幅榜',
        chengjiaoliang: '成交量',
        zanwushuju: '暂无数据',
        jiayidui: '交易对',
        zuixinjiage: '最新价格',
        zhangdie24h: '24小时涨跌',
        caozuo: '操作',
        jiaoyi: '交易',
        gengduo: '更多',
        suishisuidikaiqijiaoyi: '随时随地 开启交易',
        wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: '无论是App还是Web,都可以快速开启您的交易',
        saomaxiazaiApp: '扫码下载APP',
        iosheAndroid: 'ios和Android',
        ninkexinrenqieanquandejiamibijiaoyisuo: '您可信任且安全的加密货币交易所',
        tishi1: '储备金证明',
        xiangqing1: '我们承诺,至少以1:1的比例持有所有用户资产',
        tishi2: '最优的交易费率',
        xiangqing2: '优惠的交易费率,富有竞争力的VIP权益,享受最优品质的服务',
        tishi3: '可信赖的安全保障',
        xiangqing3: '我们复杂的安全措施和SAFU基金保护您的数字资产免受所有风险',
        tishi4: '24*7小时客服支持',
        xiangqing4: '24*7小时全天候、全日制运营模式,第一时间为您解答相关咨询',
        tishi5: '稳定安全',
        xiangqing5: '全球超前交易所，为您的资产提供银行级安全保护',
        tishi6: '常见问题',
        xiangqing6: '查看常见问题，来了解特定功能的详细说明',
    },
    footer: {
        bangzhuzhongxin: '帮助中心',
        dianhuazixun: '电话咨询',
        dianhuahegongzuoshijian: '1670-9756(平日10:00~19:00/周末及节假日休息)',
        liaotianzixun: '1:1 聊天咨询',
        keketuozixun: '可可托克咨询',
        zaixianbangzhu: '在线帮助',
        h24kaifang: '(24小时开放)',
        lixianbangzhu: '离线帮助',
        gongsidizhi: '首尔特别市龙山区汉江大道69号龙山Purgio峰会1楼',
        gongzuoshijian: '(平日10:00~18:00 / 周末及节假日休息)',
        shangshizixun: '上市咨询',
        hezuozixun: '合作咨询',
        gongsi: '公司',
        lianxiwomwn: '联系我们',
        zhidao: '指导',
        yonghuzhichi: '用户支持',
        zhanghuzhinan: '账户指南-充/提币',
        ruanjianxiazai: '软件下载',
        zhengce: '政策',
        fuwuyuyinsitiaokuan: '服务与隐私条款',
    },
    login: {
        yonghudenglu: '用户登录',
        huanyingdenglu: '欢迎登录',
        xiangmumingcheng: ' BCFX',
        guanfangwangzhan: '官方网站',
        youxiang: '邮箱',
        qingshuruyouxiang: '请输入您的邮箱',
        mima: '密码',
        qingshurumima: '请输入密码',
        wangjimima: '忘记密码?',
        denglu: '登录',
        haimeiyouzhanghao: '还没有账号？去',
        zhuce: '注册',
        dengluchenggong: '登录成功',
        denglushibai: '登录失败',
        qingshuruyonghuminghemima: '请输入用户名和密码',
        mimachangdubunengxiaoyuliuwei: '密码长度不能小于6位',
        youxiangrenzheng: '邮箱验证',
        youxiangdizhi: '邮箱地址',
        qingshuruyouxiangdizhi: '请输入邮箱地址',
        tuxingyanzhengma: '图形验证码',
        qingshurutuxingyanzhengma: '请输入图形验证码',
        yanzhengma: '验证码',
        qingshuruyouxiangyanzhengma: '请输入邮箱验证码',
        huoquyouxiangyanzhengma: '获取邮箱验证码',
        queding: '确定',
        huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '获取图形验证码失败，请重新请求',
        qingxianwanchengyouxiangrenzheng: '请先完成邮箱认证',
        huoquyouxiangyanzhengmashibai: '获取邮箱验证码失败,',
        houchongxinfasong: 'S后重新发送',
        youxiangyanzhengchenggong: '邮箱验证成功',
        yanzhengmacuowu: '验证码错误',
        xiugaidenglumima: '修改登录密码',
        xinmima: '新密码',
        quedingxinmima: '确定新密码',
        qingshuruxinmima: '请输入新密码',
        qingzaicishuruxinmima: '请再次输入新密码',
        liangcimimashurubuyizhi: '两次密码输入不一致',
        mimaxiugaichenggong: '密码修改成功',
        xiugaimimashibai: '修改密码失败',
    },
    trade: {
        hangqingheyue: '合约',
        hangqingzuixinjia: '最新价',
        hangqingzhangdiefu: '涨跌幅',
        hangqingzuigao24h: '24H最高',
        hangqingzuidi24h: '24H最低',
        hangqingchengjiaoliang24h: '24H成交量',
        hangqingchengjiabishu: '成交笔数',
        zhanghuquanyi: '账户权益',
        zhanghukeyongshouyi: '可用权益',
        hangqingweituoliebiao: '委托列表',
        hangqingjiage: '价格',
        hangqingshuliang: '数量',
        hangqingzuixinchengjiao: '最新成交',
        hangqingjiage1: '价格',
        hangqingshuliang1: '数量',
        hangqingshijian: '时间',
        dingdanchiyoucangwei: '持有仓位',
        dingdandangqianweituo: '当前委托',
        dingdanchengjiaojilu: '成交记录',
        dingdantiaojiandan: '条件单',
        chicangheyue: '合约',
        chicangkaicangjunjia: '开仓均价',
        chicangfudongyingkui: '浮动盈亏(USDT)',
        chicanggangganbeishu: '杠杆倍数',
        chicangchicangliang: '持仓量(张)',
        chicangduokong: '多/空',
        chicangduo: '多',
        chicangkong: '空',
        chicangzhiyingjiage: '止盈价格',
        chicangzhisunjiage: '止损价格',
        chicangqiangpingyugujiage: '强平预估价格',
        chicangcaozuo: '操作',
        chicangzhiyingzhisun: '止盈止损',
        chicangpingcang: '平仓',
        chicangshouxufei: '手续费',
        weituoheyue: '合约',
        weituoshuliang: '委托数量',
        weituojiage: '委托价格',
        weituofangxiang: '方向',
        weituogangganbeishu: '杠杆倍数',
        weituochengjiaoshuliang: '成交数量',
        weituoshouxufei: '手续费',
        weituochengjiaojunjia: '成交均价',
        weituoshouyi: '收益',
        weituozhuangtai: '状态',
        weituochengjiaoshijian: '成交时间',
        weituocaozuo: '操作',
        weituochedan: '撤单',
        weituotijiaoshibai: '提交失败',
        weituozhilingtijiao: '指令提交',
        weituoyitijiao: '已提交',
        weituobufenchengjiao: '部分成交',
        weituobufenchengjiaoyichedan: '部分成交已撤单',
        weituowanquanchengjiao: '完全成交',
        weituowanquanchedan: '完全撤单',
        weituochedanzhong: '撤单中',
        weituokaiduo: '开多',
        weituopingkong: '平空',
        weituokaikong: '开空',
        weituopingduo: '平多',
        chengjiaoheyue: '合约',
        chengjiaofangxiang: '方向',
        chengjiaoshuliang: '成交数量',
        chengjiaojiage: '成交价格',
        chengjiaoshijian: '成交时间',
        chengjiaopingcangyingkui: '平仓盈亏',
        chengjiaoshouxufei: '成交手续费',
        chengjiaokaiduo: '开多',
        chengjiaopingkong: '平空',
        chengjiaokaikong: '开空',
        chengjiaopingduo: '平多',
        tiaojianzengjiatiaojian: '增加条件',
        tiaojianzantingquanbu: '暂停全部',
        tiaojianqidongquanbu: '启动全部',
        tiaojianshanchuquanbu: '删除全部',
        tiaojianheyue: '合约',
        tiaojianweituoshuliang: '委托数量',
        tiaojianfangxiang: '方向',
        tiaojiantiaojianleixing: '条件类型',
        tiaojianjiageleixing: '价格类型',
        tiaojianbijiaofuhao: '比较符号',
        tiaojiantiaojian: '条件',
        tiaojianzhuangtai: '状态',
        tiaojianchuangjianshijian: '创建时间',
        tiaojiancaozuo: '操作',
        tiaojianxiugai: '修改',
        tiaojianqidong: '启动',
        tiaojianzanting: '暂停',
        tiaojianshanchu: '删除',
        tiaojianlijixiadan: '立即下单',
        tiaojiankaiduo: '开多',
        tiaojianpingkong: '平空',
        tiaojiankaikong: '开空',
        tiaojianpingduo: '平多',
        tiaojianjiagetiaojian: '价格条件',
        tiaojianshijiantiaojian: '时间条件',
        tiaojianzuixinjia: '最新价',
        tiaojianByijia: '买一价',
        tiaojianSyijia: '卖一价',
        tiaojianzanting: '暂停',
        tiaojianyunxing: '运行',
        tiaojianyiwancheng: '已完成',
        tiaojianshibai: '失败',
        tiaojianyishanchu: '已删除',
        tiaojianshoudongchufawancheng: '手动触发完成',
        tiaojianshoudongchufashibai: '手动触发失败',
        xiadankaicang: '开仓',
        xiadanpingcang: '平仓',
        xiadanweituoleixing: '委托类型',
        xiadanqingxuanze: '请选择',
        xiadanshijia: '市价',
        xiadanxianjia: '限价',
        xiadanjiage: '价格',
        xiadanqingshurujiage: '请输入价格',
        xiadanshuliang: '数量',
        xiadanqingshurushuliang: '请输入数量',
        xiadanzhang: '张',
        xiadankekaishuliang: '可开数量',
        xiadanqingxianxuanzechicangdan: '请先选择持仓单',
        xiadankepingshuliang: '可平数量',
        xiadansuoxubaozhengjin: '所需保证金:',
        xiadanshijiazhiyingzhisunyushe: '市价止盈止损预设',
        xiadanyujiqiangpingjiage: '预计强平价格：',
        xiadanqiangpingjiagetishi: '此预计强平价根据总资产动态计算的。此价格仅供参考，不作为最终强平使用。',
        xiadanzuoduo: '做多',
        xiadanzuokong: '做空',
        xiadanpingcang: '平仓',
        zhanghuheyuezichan: '合约资产',
        zhanghuchongbi: '充币',
        zhanghutibi: '提币',
        zhanghuhuazhuan: '划转',
        zhanghuzhanghuquanyi: '账户权益',
        zhanghufudongyingkui: '浮动盈亏',
        zhanghucangweibaozhengjin: '仓位保证金',
        zhanghudongjiebaozhengjin: '冻结保证金',
        zhanghukeyongquanyi: '可用权益',
        zhanghubaozhengjinshiyonglv: '保证金使用率',
        hangqingheyuexinxi: '合约信息',
        hangqingheyuemingcheng: '合约名称',
        hangqingjijiahuobi: '计价货币',
        hangqingheyuedaxiao: '合约大小',
        hangqingzuixiaojiagebodong: '最小价格波动',
        dialogleverRateganggantiaozheng: '杠杆调整',
        dialogleverRatequeding: '确定',
        dialogtransferzichanhuazhuan: '资产划转',
        dialogtransferbizhong: '币种',
        dialogtransfercong: '从',
        dialogtransferdao: '到',
        dialogtransfershuliang: '数量',
        dialogtransferqingshuruhuazhuanshuliang: '请输入划转数量',
        dialogtransferquanbu: '全部',
        dialogtransferkeyong: '可用',
        dialogtransferhuazhuan: '划转',
        dialogtransferjichuzhanghu: '基础账户',
        dialogtransferheyuezhanghu: '合约账户',
        dialogfitlosszhiyingzhisun: '止盈止损',
        dialogfitlossheyu: '合约',
        dialogfitlossduokong: '多/空',
        dialogfitlosschichangjunjia: '持仓均价',
        dialogfitlossshuliang: '数量',
        dialogfitlosszuixinjia: '最新价',
        dialogfitlosszhiyingdianshu: '止盈点数',
        dialogfitlosszhisundianshu: '止损点数',
        dialogfitlossdianshu: '点数',
        dialogfitlosszhiyingjiage: '止盈价格',
        dialogfitlosszhisunjiage: '止损价格',
        dialogfitlossjiage: '价格',
        dialogfitlossyuqishouyi: '预期收益',
        dialogfitlossyuqikuisun: '预期亏损',
        dialogfitlosstishi1: '提示：当前合约最小波动为',
        dialogfitlosstishi2: ',取消止盈止损请将价格设置为0',
        dialogfitlossqueding: '确定',
        dialogfitlossduo: '多',
        dialogfitlosskong: '空',
        dialogconditionzengjiayuntiaojiandan: '增加云条件单',
        dialogconditiontiaojianleixing: '条件类型:',
        dialogconditionjiagetiaojiandan: '价格条件单',
        dialogconditionshijiantiaojiandan: '时间条件单',
        dialogconditionjiageleixing: '价格类型:',
        dialogconditionzuixinjia: '最新价',
        dialogconditionByijia: '买一价',
        dialogconditionSyijia: '卖一价',
        dialogconditiontiaojianshezhi: '条件设置:',
        dialogconditionjiageshezhi: '价格设置:',
        dialogconditionqingshurujiage: '请输入价格',
        dialogconditionshijianshezhi: '时间设置:',
        dialogconditionjiaoyileixing: '交易类型:',
        dialogconditionkaiduo: '开多',
        dialogconditionpingkong: '平空',
        dialogconditionkaikong: '开空',
        dialogconditionpingduo: '平多',
        dialogconditiondingdanshuliang: '订单数量:',
        dialogconditionqingshurudingdanshuliang: '请输入订单数量',
        dialogconditiondingdanshixiao: '订单时效:',
        dialogconditionyongjiuyouxia: '永久有效',
        dialogconditiontijiao: '提交',
        dialogAutoProfitLosszhiyingzhisun: '止盈止损',
        dialogAutoProfitLossmingcheng: '名称',
        dialogAutoProfitLosszhiyingdian: '止盈点',
        dialogAutoProfitLosszhisundian: '止损点',
        dialogAutoProfitLosschongzhi: '重置',
        dialogAutoProfitLoss: '提交',
        dialogCloseOrderpingcang: '平仓',
        dialogCloseOrderweituoleixing: '委托类型',
        dialogCloseOrderqingxuanze: '请选择',
        dialogCloseOrdershijia: '市价',
        dialogCloseOrderxianjia: '限价',
        dialogCloseOrderjiage: '价格',
        dialogCloseOrderqingshurujiage: '请输入价格',
        dialogCloseOrdershuliang: '数量',
        dialogCloseOrderqingshurushuliang: '请输入数量',
        dialogCloseOrderzhang: '张',
        dialogCloseOrderkepingshuliang: '可平数量',
        dialogCloseOrderqueding: '确定',
        jsMessageResgaiheyuegangganxiugaichenggong: '该合约杠杆修改成功',
        jsMessageResgaiheuyegangganxiugaishibai: '该合约杠杆修改失败',
        jsMessageReskaicangchenggong: '开仓成功',
        jsMessageReskaicangshibai: '开仓失败：',
        jsMessageReskaicang1: '禁止开仓',
        jsMessageReskaicang2: '合约不存在',
        jsMessageReskaicang3: '开仓数量超过上限',
        jsMessageReskaicang4: '价格为0',
        jsMessageReskaicang5: '开仓保证金不足',
        jsMessageRespingcangchenggong: '平仓成功',
        jsMessageRespingcangshibai: '平仓失败：',
        jsMessageRespingcang1: '合约不存在',
        jsMessageRespingcang2: '持仓单不存在',
        jsMessageRespingcang3: '可平量不足',
        jsMessageReszijinhuazhuanchenggong: '资金划转成功',
        jsMessageReszijinhuazhuanshibai: '资金划转失败',
        jsMessageReschicangdanzhiyingzhisunshezhichenggong: '持仓单止盈止损设置成功',
        jsMessageReschicangdanzhiyingzhisunshezhishibai: '持仓单止盈止损设置失败：',
        jsMessageResweituodanchedanchenggong: '委托单撤单成功',
        jsMessageResweituodanchedanshibai: '委托单撤单失败',
        jsMessageRestiaojiandantianjiachenggong: '条件单添加成功',
        jsMessageResheyuebucunzai: '合约不存在',
        jsMessageResjiageshezhicuowu: '价格设置错误',
        jsMessageResshijianshezhicuowu: '时间设置错误',
        jsMessageResneibucuowu: '内部错误',
        jsMessageRestiaojiandantianjiashibai: '条件单添加失败：',
        jsMessageReszhiyingzhisunshezhichenggong: '止盈止损设置成功',
        jsconfirmquedingchedanma: '确定撤单吗？',
        jsconfirmtishi: '提示',
        jsconfirmqueding: '确定',
        jsconfirmquxiao: '取消',
        jsMessageqingxiandenglu: '请先登录！',
        jsMessagegaiheyueyouchicangbukexiugaiganggan: '该合约有持仓，不可修改杠杆',
        jsMessagegaiheyueyouweituobukexiugaiganggan: '该合约有委托，不可修改杠杆',
        jsMessageqingshuruzhengquedeshuliang: '请输入正确的数量！',
        jsMessageqingshuruzhengquedejiage: '请输入正确的价格！',
        jsMessageqingxianxuanzechicang: '请先选择持仓！',
        jsMessageqingshuruhuazhuanshuliang: '请输入划转数量！',
        jsMessageqingxianxuanzeheyue: '请先选择合约！',
        jsMessageqingshurutiaojiandanshuliang: '请输入条件单数量！',
        jsMessageqingshurutiaojiandandejiage: '请输入条件单的价格！',
        jsMessageqingshurutiaojiandanzhixingshijian: '请输入条件单执行时间！',
        emptytext: '暂无数据',
        jsMessageReszhiyingzhisundianshushezhicuowu: '止盈止损点数设置错误',
        jsMessageReschicangdanbucunzai: '持仓单不存在',
        zxkcje:'最小开仓金额'
    },
    commisson:{
        dlzx:'代理中心',
        sjzl:'数据总览',
        quanbu:"全部",
        jinri:'今日',
        zuori:'昨日',
        ri:'日',
        wdfy:'我的返佣',
        wdsjfy:'我的三级返佣',
        dengji:'等级',
        yqrs:'邀请人数',
        tdrs:'团队人数',
        yqxq:'邀请详情',
        yhm:"用户名",
        cjsj:'创建时间',
        fyxq:'返佣详情',
        zyj:'总佣金',
        dryj:'单日佣金',
        fyje:'返佣金额',

    },
    assets: {
        zongzichan: '总资产',
        chongbi: '充币',
        tibi: '提币',
        huazhuan: '划转',
        fabizhanghu: '基础账户',
        heyuezhanghu: '合约账户',
        jiayiliushui: '交易流水',
        dialogzichanhuazhuan: '资产划转',
        cong: '从',
        dao: '到',
        shuliang: '数量',
        qingshuruhuazhuanshuliang: '请输入划转数量',
        quanbu: '全部',
        keyong: '可用',
        huanzhuan: '划转',
        zijinhuazhuanchenggong: '资金划转成功',
        zijinhuazhuanshibai: '资金划转失败',
        bibizhanghu: '币币账户',
        qiquanzhanghu:'期权账户',
        zczl:'资产总览',
        zzczh:'总资产折和',
        ztzc:"在途资产",
        djzc:'冻结资产',
        zyzc:'质押资产',
        cwzc:'仓位资产',
        jyzh:'交易账户',
        bizhong:'币种',
        dongjie:'冻结',
        lirun:"利润",
        hyzh:"合约账户",
    },
    basicsAccountRecord: {
        fanhui: '返回',
        fabizhanghuliushui: '交易账户流水',
        fabijiaoyiliushuileixing: '交易流水类型',
        qingxuanze: '请选择',
        chaxunriqi: '查询日期',
        liushuileixing: '流水类型',
        shijian: '时间',
        biandongjine: '变动金额',
        yue: '余额',
        quanbu: '全部',
        Sellbi: '卖币',
        Buybi: '买币',
        tibi: '提币',
        chongbi: '充币',
        fabizhuanheyue: '基础转合约',
        heyuezhuanfabi: '合约转基础',
        zhuanzhang: '转账',
        fabihuazhuandaoheyue: '基础划转到合约',
        heyuehuazhuandaofabi: '合约划转到基础',
        goumaijijin: '购买矿池',
        jijinshuhui: '矿池赎回',
        jijinshouyi: '矿池赎回收益',
        fabizhuanqihuo: '基础转期货',
        qihuozhuanfabi: '期货转基础',
        fabizhuanganggu: '基础转港股',
        gangguzhuanfabi: '港股转基础',
        fabizhuanbibi: '基础转币币',
        bibizhuanfabi: '币币转基础',
        goumaiSGCB: '购买ICO',
        chushouSGCB: '出售ICO',
        huoqushibai: '获取失败',
        zanwushuju: '暂无数据',
    },
    contractAccountRecord: {
        fanhui: '返回',
        heyuezhanghuliushui: '合约账户流水',
        heyuejiaoyiliushuileixing: '合约交易流水类型',
        qingxuanze: '请选择',
        chaxunriqi: '查询日期',
        liushuileixing: '流水类型',
        shijian: '时间',
        biandongjine: '变动金额',
        yue: '余额',
        quanbu: '全部',
        kaicangshouxufei: '开仓手续费',
        pingcangshouxufei: '平仓手续费',
        pingcangyingkui: '平仓盈亏',
        fabizhuanheyue: '基础转合约',
        heyuezhuanfabi: '合约转基础',
        zhuanzhang: '转账',
        kaicangyongjin: '开仓佣金',
        pingcangyongjin: '平仓佣金',
        huoqushibai: '获取失败',
        zanwushuju: '暂无数据',
    },
    recharge: {
        zanwushuju: '暂无数据',
        shijian: '时间',
        leixing: '类型',
        shuliang: '数量',
        zhuangtai: '状态',
        caozuo: '操作',
        chakanxiangqing: '查看详情',
        huoqushibai: '获取失败',
        dialogtitle: '详情',
        chongbi: '充币',
        fanhui: '返回',
        lianmingcheng: '链名称',
        fuzhilianjie: '复制地址',
        tishixinxi1: '• 请勿向上述地址充值任何非 TRC_USDT或ERC_USDT 资产,否则资产将不可找回',
        tishixinxi2: '• 您充值至上述地址后,需要整个网络节点的确认,1次网络确认后到账,2次网络确认后可提币',
        tishixinxi3: '• 最小充值金额:50USDT,小于最小金额的充值将不会上账且无法退回',
        tishixinxi4: '• 您的充值地址不会经常改变,可以重复充值;如有更改,我们会尽量通过网站公告或邮件通知您',
        tishixinxi5: '• 请务必确认电脑及浏览器安全,防止信息被篡改或泄露',
        putongchongbi: '普通充币',
        jiaoyichenggong: '交易成功',
        jiaoyishibai: '交易失败',
        OMNIweihuzhong: 'OMNI链维护中,请选择TRC20',
    },
    withdraw: {
        fanhui: '返回',
        zanwushuju: '暂无数据',
        tibi: '提币',
        huoqushibai: '获取失败',
        OMNIweihuzhong: 'OMNI链维护中,请选择TRC20',
        shijian: '时间',
        leixing: '类型',
        shuliang: '数量',
        zhuangtai: '状态',
        caozuo: '操作',
        chakanxiangqing: '查看详情',
        putongtibi: '普通提币',
        dialogtitle: '详情 ',
        dialogtitlewithdraw: '提币',
        houchongxinfasong: 'S后重新发送',
        huoquyouxiangyanzhengma: '获取邮箱验证码',
        lianmingcheng: '链名称',
        tibidizhi: '提币地址',
        qingshurutibidizhi: '请输入提币地址',
        tibishuliang: '提币数量',
        shouxufei: '手续费',
        qingshurutibishuliang: '请输入提币数量',
        quanbu: '全部',
        keyong: '可用',
        daozhangshuliang: '到账数量',
        zuixiaotibishuliang: '最小提币数量为:',
        tishixinxi: '为保障资金安全,当您账户安全性原则变更,密码修改,我们会对提币进行人工审核,请耐心等待工作人员电话或电邮联系。请务必确认电脑及浏览器安全,防止数据被篡改或泄露。',
        tuxingyanzhengma: '图形验证码',
        qingshurutuxingyanzhengma: '请输入图形验证码',
        youxiangyanzhengma: '邮箱验证码',
        queding: '确定',
        tijiaozhong: '提交中',
        yijujue: '已拒绝',
        yitongguo: '已通过',
        huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '获取图形验证码失败，请重新请求',
        huoquyouxiangyanzhengmashibai: '获取邮箱验证码失败,',
        qingxianwanchengyouxiangrenzheng: '请先完成邮箱认证',
        huoquyouxiangshibaiqingchongxinqingqiu: '获取邮箱失败,请重新请求',
        qingshuruyouxiangyanzhengma: '请输入邮箱验证码',
        youxiangyanzhengmacuowu: '邮箱验证码错误,',
        qxsmrz:'请先实名认证',
        tishi:'提示',
        queding:'确定'
    },
    contractTransactionRecord: {
        fanhui: '返回',
        zanwushuju: '暂无数据',
        heyuedingdan: '合约订单',
        qingxuanze: '请选择',
        quanbu: '全部',
        kaiduo: '开多',
        pingkong: '平空',
        kaikong: '开空',
        pingduo: '平多',
        xianjiaweituo: '限价委托',
        shijiaweituo: '市价委托',
        suoyouzhuangtai: '所有状态',
        tijiaoshibai: '提交失败',
        zhunbeitijiao: '准备提交',
        yitijiao: '已提交',
        bufentijiao: '部分成交',
        bufentijiaoyichedan: '部分成交已撤单',
        quanbuchengjiao: '全部成交',
        yichedan: '已撤单',
        huoqushibai: '获取失败',
        heyuedaima: '合约代码',
        ganggan: '杠杆',
        xiadanshijian: '下单时间',
        chengjiaoshijian: '成交时间',
        fangxiang: '方向',
        baojialeixing: '报价类型',
        weituoliang: '委托量(张)',
        weituojia: '委托价(USDT)',
        chengjiaoliang: '成交量(张)',
        chengjiaojunjia: '成交均价(USDT)',
        yingkui: '盈亏(USDT)',
        shouxufei: '手续费(USDT)',
        zhuangtai: '状态',
        gupiaodingdan: '股票订单',
        waihuidingdan: '外汇订单',
        gupiaodaima: '股票代码',
        waihuidaima: '外汇代码',
    },
    kLine: {
        zhibiao: '指标',
        shezhi: '设置',
        quanping: '全屏',
        tuichuquanping: '退出全屏',
        jishuzhibiao: '技术指标',
        futuzhibiao: '副图指标',
        zhutuzhibiao: '主图指标',
        huifumoren: '恢复默认',
        lazhutuleixing: '蜡烛图类型',
        qingxuanze: '请选择',
        quanshixin: '全实心',
        quankongxin: '全空心',
        zhangkongxin: '涨空心',
        diekongxin: '跌空心',
        mianjitu: '面积图',
        jiagezhouleixing: '价格轴类型',
        xianxingzhou: '线行轴',
        baifenbizhou: '百分比轴',
        duishuzhou: '对数轴',
        zuigaojiaxianshi: '最高价显示',
        zuidijiaxianshi: '最低价显示',
        zuixinjiaxianshi: '最新价显示',
        zhibiaozuixinzhixianshi: '指标最新值显示',
        daozhizuobiao: "倒置坐标",
        wanggexianxianshi: '网格线显示',
        hfcg:'恢复成功'
    },
    SecurityCenter: {
        fanhui: '返回',
        anquanzhongxin: '安全中心',
        qingshurunicheng: '请输入昵称',
        nichengxiugaishibai: '昵称修改失败',
        huoquyonghuxinxishibai: '获取用户信息失败',
        xiugaidenglumima: '修改登录密码',
        huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '获取图形验证码失败，请重新请求',
        qingshurujiumima: '请输入旧密码',
        qingshuruxinmima: '请输入新密码',
        qingzaicishuruxinmima: '请再次输入新密码',
        qingshurutuxingyanzhengma: '请输入图形验证码',
        qingshuruyanzhengma: '请输入验证码',
        huoquzhuceshoujihaoshibai: '获取注册手机号失败',
        qingxianwanchengshoujirenzheng: '请先完成手机认证',
        huoqushoujiyanzhengmashibai: '获取手机验证码失败',
        huoqushoujiyanzhengma: '获取手机验证码',
        houchongxinfasong: 'S后重新发送',
        mimacuowu: '密码错误',
        liangcixinmimashurubuyizhiqingjiancha: '两次新密码输入不一致,请检查',
        xinmimaheyuanmimabunengyiyang: '新密码和原密码不能一样',
        qingshurushoujiyanzhengma: '请输入手机验证码',
        yanzhengmacuowu: '验证码错误',
        denglumimaxiugaichenggong: '登录密码修改成功',
        denglumimaxiugaishibai: '登录密码修改失败',
        xiugaizijinmima: '修改资金密码',
        qingshuruzijinmima: '请输入资金密码',
        qingzaicishuruzijinmima: '请再次输入资金密码',
        liangcizijinmimabuyizhiqingjiancha: '两次资金密码不一致,请检查',
        shezhizijinmimachenggong: '设置资金密码成功',
        shezhizijinmimashibai: '设置资金密码失败',
        xiugaizijinmimachenggong: '修改资金密码成功',
        xiugaizijinmimashibai: '修改资金密码失败',
        youxiangrenzheng: '邮箱认证',
        huoquyouxiangyanzhengma: '获取邮箱验证码',
        qingshuruyouxiangdizhi: '请输入邮箱地址',
        huoquyouxiangyanzhengmashibai: '获取邮箱验证码失败',
        qingshuruyouxiangyanzhengma: '请输入邮箱验证码',
        youxiangyanzhengchenggong: '邮箱验证成功',
        weirenzheng: '未认证',
        queding: '确定',
        anquanshezhiguanli: '安全设置管理',
        denglumima: '登陆密码',
        tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: '通过设置登录密码，您将可以使用账号密码直接登录',
        xiugai: '修改',
        zijinmima: '资金密码',
        yongyubaohuzijinanquan: '用于保护资金安全',
        shezhi: '设置',
        yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: '用于登录、提币、找回密码、修改安全设置时进行安全验证',
        yirenzheng: '已认证',
        renzheng: '认证',
        shimingrenzheng: '实名认证',
        yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: '用于买币、卖币、提币、充币、划转时进行安全认证',
        daishenhe: '待审核',
        yibohui: '已驳回',
        yuandenglumima: '原登录密码',
        xinmima: '新密码',
        quedingxinmima: '确定新密码',
        tuxingyanzhengma: '图形验证码',
        yanzhengma: '验证码',
        zijinmima: '资金密码',
        querenzijinmima: '确认资金密码',
        youxiangdizhi: '邮箱地址',
        qingxianwanchengyouxiangrenzheng: '请先完成邮箱认证',
        xiugaitouxiangchenggong: '修改头像成功',
        xiugaitouxiangshibai: '修改头像失败',
        shangchuantouxiangzhinengshijpggeshi: '上传头像图片只能是 JPG 格式!',
        shangchuantouxiangtupiandaxiaobunengchaoguo2M: '上传头像图片大小不能超过 2MB!',
    },
    verification: {
        fanhui: '返回',
        shimingrenzheng: '实名认证',
        qingshuruxingming: '请输入姓名',
        qingshuruzhengjianhaoma: '请输入证件号码',
        huoquyonghuxinxishibai: '获取用户信息失败',
        wenjiangeshicuowu: '文件格式错误',
        wenjianbunengdayu5M: '文件不能大于5M',
        qingtianxiexingming: '请填写姓名',
        qingtianxiezhengjianhaoma: '请填写证件号码',
        qingshangchuanzhengjianzhengmian: '请上传证件正面',
        qingshangchuanzhengjianfanmian: '请上传证件反面',
        tijiaochenggong: '提交成功',
        kaiqishenfenrenzheng: '开启身份认证',
        bohuiyuanyin: '驳回原因',
        qingtianxiejiashizhizhaogongminkahuzhao: '请填写 驾驶执照/公民卡/护照 信息',
        xingming: '姓名',
        zhengjianhaoma: '证件号码',
        qingshangchuanjiashizhizhaogongminkahuzhao: '请上传 驾驶执照/公民卡/护照 信息',
        shangchuanzhengjianzhengmian: '上传证件正面',
        huotuozhuafangru: '或拖拽放入',
        shangchuanzhengjianfanmian: '上传证件反面',
        tijiao: '提交',
    },
    invite: {
        yaoqinghaoyoudejiangli: '邀请好友得奖励',
        yongjiukede: '永久可得',
        jiaoyifanyong: '交易返佣',
        fasongyaoqinggeihaoyou: '发送邀请给好友',
        tongguofenxianglianjieyaoqinghaoyouzhuce: '通过分享链接邀请好友注册 BCFX',
        haoyouzhuce: '好友注册',
        haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: '好友接受邀请完成注册并进行交易',
        huodexiangyingbilijiangli: '获得相应比例奖励',
        qingsonghuodejiaoyishouxufeijianglifuli: '轻松获得交易手续费奖励福利',
        wodezhuanshufenxiangfangshi: '我的专属分享方式',
        yaoqinglianjie: '邀请链接',
        jiazaizhong: '加载中...',
        fuzhilianjie: '复制链接',
        yaoqingma: '邀请码',
        fuzhiyaoqingma: '复制邀请码',
        wodeyaoqing: '我的邀请',
        yaoqingrenshu: '邀请人数',
        huodejiangli: '获得奖励',
        huodongxize: '活动细则',
        tips1: '平台用户之间的关系是三个层次，如:ABCD用戶组成三个级别的用户，而A是最高级别！',
        tips2: '各级之间的利益关系',
        tips201: '。',
        tips3: '现金奖励：客户A可获得客户B合约交易手续费的40%+客户C合约交易手续费的20%+客户D合约交易手续费10%',
        tips4: '例如： 客户A有10个一级客户B，10个二级客户C，10个三级客户D，如果每个客户合约交易100000 USDT ',
        tips5: '那么客户A可以得到的回报 ',
        tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 USDT的现金奖励，实时到账可提现',
        tips7: '活动如有调整,以 BCFX平台更新为准,最终解释权归 BCFX所有。',
    },
    register: {
        huoqutuxingyanzhengmashibaiqingchongxinqingqiu: '获取图形验证码失败,请重新请求',
        qingshuruyouxiang: '请输入邮箱',
        qingshurutuxingyanzhengma: '请输入图形验证码',
        qingshuruyanzhengma: '请输入验证码',
        qingshurunindemima: '请输入您的密码',
        qingzaicishurunindemima: '请再次输入您的密码',
        qingshuruzhifumima: '请输入支付密码',
        xuantian: '选填',
        bitian: '必填',
        yonghuzhuce: '用户注册',
        huanyingdenglu: '欢迎登录',
        guanfangwangzhan: '官方网站',
        xiangmumingcheng: ' BCFX',
        youxiang: '邮箱',
        tuxingyanzhengma: '图形验证码',
        yanzhengma: '验证码',
        huoquyanzhengma: '获取验证码',
        shezhimima: '设置密码',
        quedingmima: '确定密码',
        zhifumima: '支付密码',
        yaoqingma: '邀请码',
        zhuce: '注册',
        yiyouzhanghao: '已有账号?去',
        denglu: '登录',
        qingshuruyouxiangdizhi: '请输入邮箱地址',
        huoqushibai: '获取失败',
        houchongxinfasong: 'S后重新发送',
        liangcishurudemimabuyizhi: '两次输入的密码不一致',
        zhucechenggong: '注册成功',
        zhuceshibai: '注册失败',
    },
    treasure:{
        jichubizhong: '基础币种',
        shouyibizhong: '收益币种',
        wakuangtianshu: '挖矿天数',
        shouyitianshu: '收益天数',
        suodingtianshu: '锁定天数',
        yichanshengshouyi: '已产生收益',
        yuqishouyi: '预期收益',
        kaishishijian: '开始时间',
        jieshushijian: '结束时间',
        qingshuruninyaosuodingdebizhongshuliang: '請輸入您要鎖定的币种 USDT 數量',
        huoqushibai: '获取失败',
        meiyitian: '每 1 天',
        dakai: '打开',
        yuanyuzhoukuangchi: '元宇宙矿池',
        jilu: '记录',
        kuangchixiangqing: '矿池详情',
        suo: '锁',
        yitianchanchu: '1 天产出',
        zuixiaosuodingshijian: '最小锁定时间',
        nindeUSDT: '您的 USDT',
        tian: '天',
        quanbu: '全部',
        suoUSDTbingkaishishengchan: '锁 USDT 并开始生产',
        ninquedingyaosuoUSDTkaishishengchanma: '您确定要锁USDT开始生产吗?',
        tishi: '提示',
        queding: '确定',
        quxiao: '取消',
        kaishishengchan: '开始生产!',
        yiquxiao: '已取消',
    },
    subscription:{  
        rengouliebiao:'申购列表',
        jijiangdaolai:'即将到来',
        jinxingzhong:'进行中',
        quanbu:'全部',
        keshiyonghuobi:"可使用货币",
        zongshuliang:'总数量',
        meiyouxianzhi:'没有限制',
        shengyushuliang:'剩余数量',
        kaishishijian:'开始时间',
        zhuangtai:'状态',
        jilu:'记录',
        zanwujilu:"暂无记录",
        leixing:'类型',
        shuliang:'数量',
        goumaijine:'购买金额',
        shijian:'时间',
        goumai:'购买',
        duichu:'兑出',
        jiangli:'奖励',
        tibi:'提币',
        huidaomulu:'回到目录',
        dangqianchiyou:'当前持有',
        keyong:'可用',
        keduihuan:'可兑换',
        shishihangqing:'实时行情',
        yue:'余额',
        biaoti:'标题',
        fashoushuliang:'发售数量',
        jindu:"进度",
        goumaishuliang:'购买数量',
        ge:'个',
        woyiyuedu:'我已阅读',
        daibigoumaixieyi:'代币购买协议',
        qingshurugoumaijine:'请输入购买金额',
        shifoyaogoumai:'是否要购买',
        tishi:'提示',
        queding:'确定',
        quxiao:'取消',   
        yjs:'已结束',
        ywc:'已完成',
        sgjg:'申购价格',
        jssj:"结束时间",
        zqsl:"中签数量",
        zqje:"中签金额",
        tian:"天",
        shi:'时',
        fen:'分',
        miao:'秒',
        shengou:'申购',
        xmxq:'项目详情',
    },
}