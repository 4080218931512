<template>
    <el-row :gutter="20" style="width: 100%; margin: auto; border: none">
        <el-col :span="3">
            <img :src="require('@/assets/logo.png')" alt="Vue logo" @error="handleImageError" class="logo-image" />
            <!-- <span class="logo-text">{{ $t('header.hello') }}</span> -->
        </el-col>

        <el-col :span="12">
            <div class="flex1 flex_row_left" style="margin-top:22px; font-size: 14px">
                <el-link :underline="false" @click="homeclick('')" :class="headerType ==''?'head-link-select':''">
                    {{ $t("header.shouye") }}
                </el-link>
                <el-link :underline="false" class="head-link" :class="headerType =='hy'?'head-link-select':''" @click="homeclick('hy')">
                    {{ $t("header.heyuejiaoyisuo") }}
                </el-link>
                <el-link :underline="false" class="head-link" :class="headerType =='lc'?'head-link-select':''" @click="homeclick('lc')">
                    {{ $t("header.licaichanpin") }}
                </el-link>
                <el-link :underline="false" class="head-link" :class="headerType =='rg'?'head-link-select':''" @click="homeclick('rg')">
                    {{ $t("header.rengou") }}
                </el-link>
                <div style="margin-left:35px;color:#333333">
                    <el-popover
                        placement="bottom-start"
                        width="100"
                        trigger="hover"
                       >
                       <div>
                        <div  style="cursor: pointer;" :class="headerType =='inva'?'head-link-select':''" @click="homeclick('inva')">{{ $t("header.fanyongyaoqing") }}</div>
                        <div :class="headerType =='comm'?'head-link-select':''" @click="homeclick('comm')" style="margin-top:10px;cursor: pointer;"> {{ $t("header.daolizhongxin") }}</div>
                       </div>
                    <div  slot="reference" style="cursor: pointer;"> {{ $t("header.huodong") }}</div>
                    </el-popover>
                </div>
                <!-- <el-link :underline="false" class="head-link">
                    {{ $t("header.ruanjianxiazai") }}
                </el-link> -->
            </div>
        </el-col>

        <el-col :span="4">
            <div class="flex1 flex_row_right" style="margin-top: 22px; font-size: 14px">
                <el-dropdown trigger="hover" @command="handleSetLanguage">
                    <span class="el-dropdown-link" style="cursor: pointer;">
                        {{ $t("header.changeLanguage") }}
                    </span>
                    <el-dropdown-menu class="custom-dropdown" slot="dropdown">
                        <el-dropdown-item :disabled="language === 'zh'" command="zh">
                           中文简体
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'en'" command="en">
                            English
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'tc'" command="tc">
                            中文繁体
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'tr'" command="tr">
                            Türkiye
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'de'" command="de">
                            Deutsch
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'fr'" command="fr">
                            Français
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'es'" command="es">
                            Español
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'ru'" command="ru">
                            Русский
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'pt'" command="pt">
                            Português
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'pl'" command="pl">
                            Polski
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'hi'" command="hi">
                            हिंदी
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'fa'" command="fa">
                            فارسی
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'ar'" command="ar">
                            اللغة العربية
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'ur'" command="ur">
                            اردو
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'ko'" command="ko">
                            한국어
                        </el-dropdown-item>
                        <el-dropdown-item :disabled="language === 'jp'" command="jp">
                            日本語
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-col>

        <el-col :span="5">
            <div v-if="isLoggedIn" class="flex1 flex_row_left" style="margin-top: 22px; font-size: 14px;">
                <el-link :underline="false" @click="homeclick('assets')" :class="headerType =='assets'?'head-link-select':''">
                    {{ $t("header.zichan") }}
                </el-link>
                <el-dropdown trigger="hover" @command="handleOrder"
                    style="margin-left: 20px; font-size: 14px; cursor: pointer">
                    <span> {{ $t("header.dingdan") }} </span>
                    <el-dropdown-menu class="custom-dropdown" slot="dropdown">
                        <el-dropdown-item command="0">
                            {{ $t("header.heyuedingdan") }}
                        </el-dropdown-item>
                        <el-dropdown-item command="1">
                            {{ $t("header.jiaoyidingdan") }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown trigger="hover" @command="handleUserCenter"
                    style="margin-left: 20px; font-size: 14px; cursor: pointer">
                    <span> <i class="el-icon-user" style="font-weight: 400"> </i> </span>
                    <el-dropdown-menu class="custom-dropdown" slot="dropdown">
                        <el-dropdown-item command="0" disabled>
                            {{ userName }}
                        </el-dropdown-item>
                        <el-dropdown-item command="1" divided><i class="el-icon-circle-check"></i>
                            {{ $t("header.anquanzhongxin") }}
                        </el-dropdown-item>
                        <!-- <el-dropdown-item command="2"><i class="el-icon-share"></i> {{ $t("header.fanyongyaoqing") }}
                        </el-dropdown-item> -->
                        <el-dropdown-item command="3"><i class="el-icon-back"></i> {{ $t("header.tuichudenglu") }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <!-- <span style="margin-left: 20px; cursor: pointer">
                    <i class="el-icon-bell" style="color: #606266; font-size: 14px"> </i>
                </span> -->

                <!-- <span class="user-name"> {{ userName }} </span>
                <el-button class="buttonDiv" size="small" style="height: 30px; width: 100px;" icon="el-icon-user" @click="logout"> {{ $t('header.Logout') }} </el-button> -->
            </div>

            <div v-else class="user-profile flex1 flex_row_left">
                <el-button class="buttonDiv" size="small" style="height: 30px; min-width: 100px;" icon="el-icon-user"
                    @click="btnloginclick">
                    {{ $t("header.Login") }}
                </el-button>
                <el-button class="buttonDiv" size="small" style="height: 30px; min-width: 100px;" icon="el-icon-edit"
                    @click="btnregisterclick">
                    {{ $t("header.Register") }}
                </el-button>
            </div>
        </el-col>
    </el-row>
</template>

<script>
// import ThemePicker from './ThemePicker.vue';
export default {
    data() {
        return {
            language: localStorage.getItem("lang"), //存储本地
            // userName: '欢迎，' + sessionStorage.getItem('username') + '！',
            userName: sessionStorage.getItem('username'),
            headerType:''
        };
    },
    mounted() {
    },
    computed: {
        isLoggedIn() {
            return this.$store.state.loginStatus;
        },
        // userName() {
        //     return this.$store.state.username;
        // },
    },
    name: "HeaderLayout",
    // components: {
    //   ThemePicker
    // },
    methods: {
        logout() {
            // 实现注销逻辑，比如清除token，重置状态等
            // this.$store.state.loginStatus = false;
            sessionStorage.clear()
            this.$store.dispatch("tradeWS/close");
            //关闭币币websocket
            this.$store.dispatch('tradeSpotWS/close');
            this.$store.dispatch("logout");
            
            if (this.$route.path !== "/") { 
                this.$router.push({name:'homeLayout'}, () => {}); // 注销后跳转到首页
            }
        },
        handleImageError() {
            // 为了增加鲁棒性，这里使用this.$el.src而不是直接修改属性
            // 适用于Vue的编译器语法，确保在Vue的环境中正确处理DOM元素
            this.$el.src = "../../assets/error.png";
        },
        handleSetLanguage(lang) {
            // 这个在上面有解释 实在不知道就输出看一看 就了解了
            // console.log(lang)
            this.$i18n.locale = lang;
            this.language = lang;
            localStorage.setItem("lang", lang);
            // 强制刷新页面
            // window.location.reload();
        },
        btnloginclick() {
            this.$router.push({name: 'LoginLayout'}, () => {});
        },
        btnregisterclick() { 
            this.$router.push({name: 'registerLayout'}, () => {});
        },
        
        homeclick(val) {
            this.headerType = val
            if (!val) {
                this.$router.push({name: 'homeLayout'}, () => {});
            } else if (val == 'hy') {
                this.$router.push({name:'contractTradeLayout'}, () => {});
            } else if(val == 'lc'){
                this.$router.push({name:'treasure'}, () => {});
            } else if(val == 'rg'){
                this.$router.push({name:'subscription'}, () => {});
            }else if(val == 'assets'){
                this.$router.push({name:'assetsLayout'}, () => {});
            }else if(val == 'inva'){
                this.$router.push({name:'inviteLayout'}, () => {});
            }else if(val == 'comm'){
                this.$router.push({name:'commisson'}, () => {});
            }
        },
        assetsClick() {
            
        },
        handleOrder(command) { 
            if (command === "0") {
                this.$router.push({name:'contractTransactionRecordLayout'}, () => {});
            }else if(command === "1"){
                this.$router.push({name:'basicsAccountRecordLayout'}, () => {});
            }
        },
        handleUserCenter(command) {
            if (command === "1") {
                this.$router.push({name:'SecurityCenterLayout'}, () => {});
            } else if (command === "2") {
                this.$router.push({name:'inviteLayout'}, () => {});
            } else if (command === "3") {
                this.logout();
            }
        },
    },
};
</script>

<style scoped>
.flex_row_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex_row_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex1 {
    flex: 1;
}
.logo-image {
    width: 48px;
    height: 48px;
    margin-left: 5px;
    margin-top: 5px;
}

.logo-text {
    align-items: center;
}

.head-link {
    margin-left: 35px;
    color: #333333;
    font-size: 15px;
}
.head-link-select{
    color: #2794f8 !important;
}
.auth-links {
    margin-left: 10px;
    margin-top: 10px;
}

.user-profile {
    margin-top: 10px;
}

.user-name {
    font-size: 14px;
}

/*鼠标点击后移开，恢复本身样式*/
.buttonDiv,
.buttonDiv:focus:not(.buttonDiv:hover) {
    margin-right: 12px;
    border: 1px solid #2794f8;
    border-radius: 4px;
    color: #2794f8;
    background: white;
}

/*鼠标悬浮，没有按下；鼠标按下后抬起，没有移开*/
.buttonDiv:focus,
.buttonDiv:hover {
    background: #409eff;
    border: 1px solid #2794f8 !important;
    color: #ffffff;
}

/*鼠标按下，没有抬起*/
.buttonDiv:active {
    background: #2794f8;
    color: white;
}

.flex_col_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.el-link.el-link--default{
    color: #333333;
    font-size: 15px;
}
.el-dropdown{
    color: #333333;
}
</style>

