export default {
    header: {
    hello: 'हैलो वर्ल्ड!',
    changeLanguage: 'भाषा बदलें',
    Login: 'लॉग इन करें',
    Register: 'पंजीकरण करवाना',
    Logout: 'लॉग आउट',
    shouye: 'मुखपृष्ठ',
    bibijiaoyisuo: 'क्रिप्टोकरेंसी ट्रेडिंग',
    heyuejiaoyisuo: 'अनुबंध व्यापार',
    licaichanpin: 'खनन पूल',
    rengou: 'सदस्यता',
    ruanjianxiazai: 'सॉफ्टवेयर डाउनलोड',
    zhongwen: 'चीनी',
    yingwen: 'में',
    zichan: 'संपत्ति',
    dingdan: 'आदेश',
    heyuedingdan: 'अनुबंध आदेश',
    bibidingdan: 'सिक्का आदेश',
    anquanzhongxin: 'सुरक्षा केंद्र',
    fanyongyaoqing: 'आयोग का निमंत्रण',
    tuichudenglu: 'लॉग आउट',
    gupiaojiaoyi: 'स्टॉक ट्रेडिंग',
    waihuijiaoyi: 'विदेशी मुद्रा व्यापार',
    qiquanjiaoyi:'विकल्प ट्रेडिंग',
    gupiaodingdan: 'स्टॉक ऑर्डर',
    waihuidingdan: 'विदेशी मुद्रा आदेश',
    qiquandingdan: 'विकल्प क्रम',
    jiaoyidingdan:'ट्रेडिंग ऑर्डर',
    daolizhongxin:'एजेंसी केंद्र',
    huodong:'गतिविधि',
    },
    home: {
    huanyinglaidao: 'आपका स्वागत है',
    pingtaimingcheng: 'यहाँ',
    mengxiangqihang: 'सपने नौकायन करते हैं',
    yijiankaiqishuzihualvcheng: 'एक क्लिक से अपनी डिजिटल यात्रा शुरू करें',
    liaojiegengduo: 'और अधिक जानें',
    chongzhi: 'लबालब भरना',
    jiaoyi: 'व्यापार',
    remenbang: 'लोकप्रिय सूची',
    zhangfubang: 'लाभ पाने वाली सूची',
    diefubang: 'हारने वालों की सूची',
    chengjiaoliang: 'आयतन',
    zanwushuju: 'अभी तक कोई डेटा नहीं',
    jiayidui: 'ट्रेडिंग जोड़ी',
    zuixinjiage: 'नवीनतम कीमत',
    zhangdie24h: 'चौबीस घंटे उत्थान और पतन',
    caozuo: 'प्रचालन',
    jiaoyi: 'व्यापार',
    gengduo: 'अधिक',
    suishisuidikaiqijiaoyi: 'कभी भी, कहीं भी ट्रेडिंग शुरू करें',
    wulunshiApphaishiwebdoukeyikuaisukaiqinindejiaoyi: 'चाहे वह ऐप हो या वेब, आप तुरंत अपना लेनदेन शुरू कर सकते हैं',
    saomaxiazaiApp: 'ऐप डाउनलोड करने के लिए क्यूआर कोड को स्कैन करें',
    iosheAndroid: 'आईओएस और एंड्रॉइड',
    ninkexinrenqieanquandejiamibijiaoyisuo: 'आपका विश्वसनीय और सुरक्षित क्रिप्टोकरेंसी एक्सचेंज',
    tishi1: 'आरक्षित प्रमाणपत्र',
    xiangqing1: 'हम सभी उपयोगकर्ता संपत्तियों को कम से कम 1:1 के अनुपात में रखने का वादा करते हैं',
    tishi2: 'सर्वोत्तम लेनदेन दरें',
    xiangqing2: 'तरजीही लेनदेन दरें, प्रतिस्पर्धी वीआईपी अधिकार, और सर्वोत्तम गुणवत्ता सेवाओं का आनंद लें',
    tishi3: 'सुरक्षा जिस पर आप भरोसा कर सकते हैं',
    xiangqing3: 'हमारे परिष्कृत सुरक्षा उपाय और SAFU फंड आपकी डिजिटल संपत्तियों को सभी जोखिमों से बचाते हैं',
    tishi4: '24*7 घंटे ग्राहक सेवा सहायता',
    xiangqing4: '24*7 घंटे, पूर्णकालिक संचालन मोड, जितनी जल्दी हो सके अपनी प्रासंगिक पूछताछ का उत्तर दें',
    tishi5: 'स्थिर और सुरक्षित',
    xiangqing5: 'दुनिया का अग्रणी एक्सचेंज आपकी संपत्तियों के लिए बैंक-स्तरीय सुरक्षा सुरक्षा प्रदान करता है',
    tishi6: 'अक्सर पूछे जाने वाले प्रश्न',
    xiangqing6: 'विशिष्ट सुविधाओं के विस्तृत विवरण के लिए FAQ देखें',
    },
    footer: {
    bangzhuzhongxin: 'सहायता केंद्र',
    dianhuazixun: 'टेलीफोन परामर्श',
    dianhuahegongzuoshijian: '1670-9756 (सप्ताह के दिनों में 10:00~19:00 बजे/सप्ताहांत और छुट्टियों पर बंद)',
    liaotianzixun: '1:1 चैट परामर्श',
    keketuozixun: 'कोकोटोक परामर्श',
    zaixianbangzhu: 'ऑनलाइन सहायता',
    h24kaifang: '(24 घंटे खुला है)',
    lixianbangzhu: 'ऑफ़लाइन सहायता',
    gongsidizhi: '1एफ, योंगसन पुर्जियो समिट, 69 हैंगंग-गिल, योंगसन-गु, सियोल',
    gongzuoshijian: '(सप्ताह के दिनों में 10:00 ~ 18:00 बजे / सप्ताहांत और छुट्टियों पर बंद)',
    shangshizixun: 'लिस्टिंग परामर्श',
    hezuozixun: 'सहयोग परामर्श',
    gongsi: 'कंपनी',
    lianxiwomwn: 'हमसे संपर्क करें',
    zhidao: 'मार्गदर्शक',
    yonghuzhichi: 'उपयोगकर्ता सपोर्ट',
    zhanghuzhinan: 'खाता गाइड-जमा/निकासी',
    ruanjianxiazai: 'सॉफ्टवेयर डाउनलोड',
    zhengce: 'नीति',
    fuwuyuyinsitiaokuan: 'सेवा की शर्तें और गोपनीयता',
    },
    login: {
    yonghudenglu: 'उपयोगकर्ता लॉगिन',
    huanyingdenglu: 'लॉग इन करने के लिए आपका स्वागत है',
    xiangmumingcheng: 'बीसीएफएक्स',
    guanfangwangzhan: 'आधिकारिक वेबसाइट',
    youxiang: 'मेल',
    qingshuruyouxiang: 'कृपया अपना ईमेल दर्ज करें',
    mima: 'पासवर्ड',
    qingshurumima: 'कृपया पासवर्ड दर्ज करें',
    wangjimima: 'पासवर्ड भूल गए?',
    denglu: 'लॉग इन करें',
    haimeiyouzhanghao: 'अब तक कोई खाता नहीं है? जाना',
    zhuce: 'पंजीकरण करवाना',
    dengluchenggong: 'लॉग इन सफल',
    denglushibai: 'लॉगिन विफल',
    qingshuruyonghuminghemima: 'कृपया उपयोगकर्ता नाम और पासवर्ड दर्ज करें',
    mimachangdubunengxiaoyuliuwei: 'पासवर्ड की लंबाई 6 अक्षर से कम नहीं हो सकती',
    youxiangrenzheng: 'ईमेल सत्यापन',
    youxiangdizhi: 'मेल पता',
    qingshuruyouxiangdizhi: 'कृपया ईमेल पता दर्ज करें',
    tuxingyanzhengma: 'ग्राफ़िक सत्यापन कोड',
    qingshurutuxingyanzhengma: 'कृपया ग्राफ़िक सत्यापन कोड दर्ज करें',
    yanzhengma: 'सत्यापन कोड',
    qingshuruyouxiangyanzhengma: 'कृपया ईमेल सत्यापन कोड दर्ज करें',
    huoquyouxiangyanzhengma: 'ईमेल सत्यापन कोड प्राप्त करें',
    queding: 'ज़रूर',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'ग्राफ़िक सत्यापन कोड प्राप्त करने में विफल, कृपया पुनः अनुरोध करें',
    qingxianwanchengyouxiangrenzheng: 'कृपया पहले ईमेल सत्यापन पूरा करें',
    huoquyouxiangyanzhengmashibai: 'ईमेल सत्यापन कोड प्राप्त करने में विफल,',
    houchongxinfasong: 'एस के बाद दोबारा भेजें',
    youxiangyanzhengchenggong: 'ईमेल सत्यापन सफल',
    yanzhengmacuowu: 'सत्यापन कोड त्रुटि',
    xiugaidenglumima: 'लॉगिन पासवर्ड बदलें',
    xinmima: 'नया पासवर्ड',
    quedingxinmima: 'नए पासवर्ड की पुष्टि करें',
    qingshuruxinmima: 'कृपया नया पासवर्ड दर्ज करें',
    qingzaicishuruxinmima: 'कृपया दोबारा नया पासवर्ड दर्ज करें',
    liangcimimashurubuyizhi: 'दो पासवर्ड इनपुट असंगत हैं',
    mimaxiugaichenggong: 'पासवर्ड सफलतापूर्वक बदला गया',
    xiugaimimashibai: 'पासवर्ड बदलने में विफल',
    },
    trade: {
    hangqingheyue: 'अनुबंध',
    hangqingzuixinjia: 'नवीनतम कीमत',
    hangqingzhangdiefu: 'बढ़ाना या घटाना',
    hangqingzuigao24h: '24H उच्चतम',
    hangqingzuidi24h: '24 घंटे न्यूनतम',
    hangqingchengjiaoliang24h: '24H ट्रेडिंग वॉल्यूम',
    hangqingchengjiabishu: 'लेन-देन की संख्या',
    zhanghuquanyi: 'खाता अधिकार',
    zhanghukeyongshouyi: 'उपलब्ध लाभ',
    hangqingweituoliebiao: 'आदेश सूची',
    hangqingjiage: 'कीमत',
    hangqingshuliang: 'मात्रा',
    hangqingzuixinchengjiao: 'नवीनतम लेनदेन',
    hangqingjiage1: 'कीमत',
    hangqingshuliang1: 'मात्रा',
    hangqingshijian: 'समय',
    dingdanchiyoucangwei: 'एक पद धारण करें',
    dingdandangqianweituo: 'अभी का ऑर्डर',
    dingdanchengjiaojilu: 'लेन-देन रिकार्ड',
    dingdantiaojiandan: 'सशर्त आदेश',
    chicangheyue: 'अनुबंध',
    chicangkaicangjunjia: 'औसत शुरुआती कीमत',
    chicangfudongyingkui: 'अस्थायी लाभ और हानि (यूएसडीटी)',
    chicanggangganbeishu: 'एकाधिक उत्तोलन',
    chicangchicangliang: 'पद',
    chicangduokong: 'लंबा छोटा',
    chicangduo: 'अनेक',
    chicangkong: 'व्यर्थ',
    chicangzhiyingjiage: 'लाभ मूल्य लें',
    chicangzhisunjiage: 'स्टॉप लॉस कीमत',
    chicangqiangpingyugujiage: 'अनुमानित परिसमापन मूल्य',
    chicangcaozuo: 'प्रचालन',
    chicangzhiyingzhisun: 'लाभ लें और हानि रोकें',
    chicangpingcang: 'बंद स्थिति',
    chicangshouxufei: 'संचालन शुल्क',
    weituoheyue: 'अनुबंध',
    weituoshuliang: 'ऑर्डर मात्रा',
    weituojiage: 'कमीशन मूल्य',
    weituofangxiang: 'दिशा',
    weituogangganbeishu: 'एकाधिक उत्तोलन',
    weituochengjiaoshuliang: 'लेन-देन की मात्रा',
    weituoshouxufei: 'संचालन शुल्क',
    weituochengjiaojunjia: 'औसत लेनदेन मूल्य',
    weituoshouyi: 'आय',
    weituozhuangtai: 'राज्य',
    weituochengjiaoshijian: 'लेन-देन का समय',
    weituocaozuo: 'प्रचालन',
    weituochedan: 'आदेश रद्द',
    weituotijiaoshibai: 'सबमिशन विफल',
    weituozhilingtijiao: 'निर्देश प्रस्तुत करना',
    weituoyitijiao: 'प्रस्तुत किया गया',
    weituobufenchengjiao: 'आंशिक सौदा',
    weituobufenchengjiaoyichedan: 'कुछ ऑर्डर रद्द कर दिए गए हैं',
    weituowanquanchengjiao: 'पूरा सौदा',
    weituowanquanchedan: 'ऑर्डर पूरी तरह रद्द करें',
    weituochedanzhong: 'ऑर्डर रद्द किया जा रहा है',
    weituokaiduo: 'लंबा खुला',
    weituopingkong: 'सपाट आकाश',
    weituokaikong: 'लघु खोलें',
    weituopingduo: 'पिंडुओ',
    chengjiaoheyue: 'अनुबंध',
    chengjiaofangxiang: 'दिशा',
    chengjiaoshuliang: 'लेन-देन की मात्रा',
    chengjiaojiage: 'लेनदेन मूल्य',
    chengjiaoshijian: 'लेन-देन का समय',
    chengjiaopingcangyingkui: 'स्थिति समापन लाभ और हानि',
    chengjiaoshouxufei: 'लेनदेन शुल्क',
    chengjiaokaiduo: 'लंबा खुला',
    chengjiaopingkong: 'सपाट आकाश',
    chengjiaokaikong: 'लघु खोलें',
    chengjiaopingduo: 'पिंडुओ',
    tiaojianzengjiatiaojian: 'शर्तें जोड़ें',
    tiaojianzantingquanbu: 'सब रोकें',
    tiaojianqidongquanbu: 'सभी प्रारंभ करें',
    tiaojianshanchuquanbu: 'सभी हटा दो',
    tiaojianheyue: 'अनुबंध',
    tiaojianweituoshuliang: 'ऑर्डर मात्रा',
    tiaojianfangxiang: 'दिशा',
    tiaojiantiaojianleixing: 'शर्त प्रकार',
    tiaojianjiageleixing: 'मूल्य प्रकार',
    tiaojianbijiaofuhao: 'तुलना प्रतीक',
    tiaojiantiaojian: 'स्थिति',
    tiaojianzhuangtai: 'राज्य',
    tiaojianchuangjianshijian: 'रचना समय',
    tiaojiancaozuo: 'प्रचालन',
    tiaojianxiugai: 'दोहराना',
    tiaojianqidong: 'चालू होना',
    tiaojianzanting: 'विराम',
    tiaojianshanchu: 'मिटाना',
    tiaojianlijixiadan: 'अब ऑर्डर दें',
    tiaojiankaiduo: 'लंबा खुला',
    tiaojianpingkong: 'सपाट आकाश',
    tiaojiankaikong: 'लघु खोलें',
    tiaojianpingduo: 'पिंडुओ',
    tiaojianjiagetiaojian: 'मूल्य शर्तें',
    tiaojianshijiantiaojian: 'समय की स्थिति',
    tiaojianzuixinjia: 'नवीनतम कीमत',
    tiaojianByijia: 'एक कीमत पर खरीदें',
    tiaojianSyijia: 'एक कीमत पर बेचें',
    tiaojianzanting: 'विराम',
    tiaojianyunxing: 'दौड़ना',
    tiaojianyiwancheng: 'पुरा होना।',
    tiaojianshibai: 'असफल',
    tiaojianyishanchu: 'हटाए गए',
    tiaojianshoudongchufawancheng: 'मैनुअल ट्रिगर पूर्ण',
    tiaojianshoudongchufashibai: 'मैन्युअल ट्रिगर विफल रहा',
    xiadankaicang: 'एक स्थिति खोलें',
    xiadanpingcang: 'बंद स्थिति',
    xiadanweituoleixing: 'प्रतिनिधि प्रकार',
    xiadanqingxuanze: 'कृपया चयन कीजिए',
    xiadanshijia: 'बाजार कीमत',
    xiadanxianjia: 'मूल्य सीमा',
    xiadanjiage: 'कीमत',
    xiadanqingshurujiage: 'कृपया कीमत दर्ज करें',
    xiadanshuliang: 'मात्रा',
    xiadanqingshurushuliang: 'कृपया मात्रा दर्ज करें',
    xiadanzhang: 'खुला',
    xiadankekaishuliang: 'उपलब्ध मात्रा',
    xiadanqingxianxuanzechicangdan: 'कृपया पहले पद क्रम का चयन करें',
    xiadankepingshuliang: 'वह मात्रा जिसे समतल किया जा सके',
    xiadansuoxubaozhengjin: 'आवश्यक मार्जिन:',
    xiadanshijiazhiyingzhisunyushe: 'बाजार मूल्य लाभ और स्टॉप लॉस प्रीसेट लें',
    xiadanyujiqiangpingjiage: 'अनुमानित परिसमापन मूल्य:',
    xiadanqiangpingjiagetishi: 'यह अपेक्षित परिसमापन मूल्य कुल संपत्ति के आधार पर गतिशील रूप से गणना की जाती है। यह कीमत केवल संदर्भ के लिए है और अंतिम परिसमापन के रूप में इसका उपयोग नहीं किया जाएगा।',
    xiadanzuoduo: 'लंबे तक जाओ',
    xiadanzuokong: 'छोटा',
    xiadanpingcang: 'बंद स्थिति',
    zhanghuheyuezichan: 'अनुबंध संपत्ति',
    zhanghuchongbi: 'सिक्के जमा करें',
    zhanghutibi: 'सिक्के निकालो',
    zhanghuhuazhuan: 'स्थानांतरण',
    zhanghuzhanghuquanyi: 'खाता अधिकार',
    zhanghufudongyingkui: 'अस्थायी लाभ और हानि',
    zhanghucangweibaozhengjin: 'स्थिति मार्जिन',
    zhanghudongjiebaozhengjin: 'मार्जिन फ्रीज करें',
    zhanghukeyongquanyi: 'उपलब्ध लाभ',
    zhanghubaozhengjinshiyonglv: 'मार्जिन उपयोग दर',
    hangqingheyuexinxi: 'अनुबंध की जानकारी',
    hangqingheyuemingcheng: 'अनुबंध का नाम',
    hangqingjijiahuobi: 'कोटेशन मुद्रा',
    hangqingheyuedaxiao: 'अनुबंध का आकार',
    hangqingzuixiaojiagebodong: 'न्यूनतम मूल्य में उतार-चढ़ाव',
    dialogleverRateganggantiaozheng: 'उत्तोलन समायोजन',
    dialogleverRatequeding: 'ज़रूर',
    dialogtransferzichanhuazhuan: 'संपत्ति हस्तांतरण',
    dialogtransferbizhong: 'मुद्रा',
    dialogtransfercong: 'से',
    dialogtransferdao: 'आना',
    dialogtransfershuliang: 'मात्रा',
    dialogtransferqingshuruhuazhuanshuliang: 'कृपया स्थानांतरण राशि दर्ज करें',
    dialogtransferquanbu: 'सभी',
    dialogtransferkeyong: 'उपलब्ध',
    dialogtransferhuazhuan: 'स्थानांतरण',
    dialogtransferjichuzhanghu: 'मूल खाता',
    dialogtransferheyuezhanghu: 'अनुबंध खाता',
    dialogfitlosszhiyingzhisun: 'लाभ लें और हानि रोकें',
    dialogfitlossheyu: 'अनुबंध',
    dialogfitlossduokong: 'लंबा छोटा',
    dialogfitlosschichangjunjia: 'औसत स्थिति मूल्य',
    dialogfitlossshuliang: 'मात्रा',
    dialogfitlosszuixinjia: 'नवीनतम कीमत',
    dialogfitlosszhiyingdianshu: 'लाभ अंक ले लो',
    dialogfitlosszhisundianshu: 'हानि अंक रोकें',
    dialogfitlossdianshu: 'अंक',
    dialogfitlosszhiyingjiage: 'लाभ मूल्य लें',
    dialogfitlosszhisunjiage: 'स्टॉप लॉस कीमत',
    dialogfitlossjiage: 'कीमत',
    dialogfitlossyuqishouyi: 'अपेक्षित वापसी',
    dialogfitlossyuqikuisun: 'अपेक्षित हानि',
    dialogfitlosstishi1: 'युक्ति: वर्तमान अनुबंध का न्यूनतम उतार-चढ़ाव है',
    dialogfitlosstishi2: ', लाभ लेने और हानि रोकने के लिए कृपया मूल्य को 0 पर सेट करें।',
    dialogfitlossqueding: 'ज़रूर',
    dialogfitlossduo: 'अनेक',
    dialogfitlosskong: 'व्यर्थ',
    dialogconditionzengjiayuntiaojiandan: 'क्लाउड कंडीशन शीट जोड़ें',
    dialogconditiontiaojianleixing: 'शर्त प्रकार:',
    dialogconditionjiagetiaojiandan: 'मूल्य शर्तें पत्रक',
    dialogconditionshijiantiaojiandan: 'समय स्थिति पत्रक',
    dialogconditionjiageleixing: 'मूल्य प्रकार:',
    dialogconditionzuixinjia: 'नवीनतम कीमत',
    dialogconditionByijia: 'एक कीमत पर खरीदें',
    dialogconditionSyijia: 'एक कीमत पर बेचें',
    dialogconditiontiaojianshezhi: 'शर्त सेटिंग:',
    dialogconditionjiageshezhi: 'मूल्य सेटिंग:',
    dialogconditionqingshurujiage: 'कृपया कीमत दर्ज करें',
    dialogconditionshijianshezhi: 'समय निर्धारण:',
    dialogconditionjiaoyileixing: 'लेन-देन प्रकार:',
    dialogconditionkaiduo: 'लंबा खुला',
    dialogconditionpingkong: 'सपाट आकाश',
    dialogconditionkaikong: 'लघु खोलें',
    dialogconditionpingduo: 'पिंडुओ',
    dialogconditiondingdanshuliang: 'ऑर्डर मात्रा:',
    dialogconditionqingshurudingdanshuliang: 'कृपया ऑर्डर मात्रा दर्ज करें',
    dialogconditiondingdanshixiao: 'ऑर्डर का समय:',
    dialogconditionyongjiuyouxia: 'स्थायी रूप से मान्य',
    dialogconditiontijiao: 'जमा करना',
    dialogAutoProfitLosszhiyingzhisun: 'लाभ लें और हानि रोकें',
    dialogAutoProfitLossmingcheng: 'नाम',
    dialogAutoProfitLosszhiyingdian: 'लाभ बिंदु ले लो',
    dialogAutoProfitLosszhisundian: 'स्टॉप लॉस पॉइंट',
    dialogAutoProfitLosschongzhi: 'रीसेट करें',
    dialogAutoProfitLoss: 'जमा करना',
    dialogCloseOrderpingcang: 'बंद स्थिति',
    dialogCloseOrderweituoleixing: 'प्रतिनिधि प्रकार',
    dialogCloseOrderqingxuanze: 'कृपया चयन कीजिए',
    dialogCloseOrdershijia: 'बाजार कीमत',
    dialogCloseOrderxianjia: 'मूल्य सीमा',
    dialogCloseOrderjiage: 'कीमत',
    dialogCloseOrderqingshurujiage: 'कृपया कीमत दर्ज करें',
    dialogCloseOrdershuliang: 'मात्रा',
    dialogCloseOrderqingshurushuliang: 'कृपया मात्रा दर्ज करें',
    dialogCloseOrderzhang: 'खुला',
    dialogCloseOrderkepingshuliang: 'वह मात्रा जिसे समतल किया जा सके',
    dialogCloseOrderqueding: 'ज़रूर',
    jsMessageResgaiheyuegangganxiugaichenggong: 'अनुबंध उत्तोलन को सफलतापूर्वक संशोधित किया गया था',
    jsMessageResgaiheuyegangganxiugaishibai: 'अनुबंध उत्तोलन संशोधन विफल रहा',
    jsMessageReskaicangchenggong: 'पद सफलतापूर्वक खोला गया',
    jsMessageReskaicangshibai: 'पद खोलना विफल:',
    jsMessageReskaicang1: 'कोई पद खोलना प्रतिबंधित है',
    jsMessageReskaicang2: 'अनुबंध मौजूद नहीं है',
    jsMessageReskaicang3: 'रिक्त पदों की संख्या ऊपरी सीमा से अधिक है',
    jsMessageReskaicang4: 'कीमत 0 है',
    jsMessageReskaicang5: 'पोजीशन खोलने के लिए अपर्याप्त मार्जिन',
    jsMessageRespingcangchenggong: 'स्थिति सफलतापूर्वक बंद हो गई',
    jsMessageRespingcangshibai: 'स्थिति बंद करने में विफल:',
    jsMessageRespingcang1: 'अनुबंध मौजूद नहीं है',
    jsMessageRespingcang2: 'पद रसीद मौजूद नहीं है',
    jsMessageRespingcang3: 'अपर्याप्त राशि',
    jsMessageReszijinhuazhuanchenggong: 'फंड ट्रांसफर सफल',
    jsMessageReszijinhuazhuanshibai: 'निधि स्थानांतरण विफल रहा',
    jsMessageReschicangdanzhiyingzhisunshezhichenggong: 'पोजीशन ऑर्डर स्टॉप प्रॉफिट और स्टॉप लॉस सेट सफलतापूर्वक',
    jsMessageReschicangdanzhiyingzhisunshezhishibai: 'पोजीशन ऑर्डर के लिए स्टॉप-प्रॉफिट और स्टॉप-लॉस सेटिंग्स विफल रहीं:',
    jsMessageResweituodanchedanchenggong: 'ऑर्डर सफलतापूर्वक रद्द कर दिया गया',
    jsMessageResweituodanchedanshibai: 'ऑर्डर रद्द करना विफल रहा',
    jsMessageRestiaojiandantianjiachenggong: 'सशर्त आदेश सफलतापूर्वक जोड़ा गया',
    jsMessageResheyuebucunzai: 'अनुबंध मौजूद नहीं है',
    jsMessageResjiageshezhicuowu: 'मूल्य निर्धारण त्रुटि',
    jsMessageResshijianshezhicuowu: 'समय निर्धारण त्रुटि',
    jsMessageResneibucuowu: 'आंतरिक त्रुटि',
    jsMessageRestiaojiandantianjiashibai: 'सशर्त आदेश जोड़ने में विफल:',
    jsMessageReszhiyingzhisunshezhichenggong: 'लाभ लें और स्टॉप लॉस सेट सफलतापूर्वक',
    jsconfirmquedingchedanma: 'क्या आप वाकई ऑर्डर रद्द करना चाहते हैं?',
    jsconfirmtishi: 'संकेत देना',
    jsconfirmqueding: 'ज़रूर',
    jsconfirmquxiao: 'रद्द करना',
    jsMessageqingxiandenglu: 'कृपया पहले लौग इन करें!',
    jsMessagegaiheyueyouchicangbukexiugaiganggan: 'इस अनुबंध में खुली स्थिति है और उत्तोलन को संशोधित नहीं किया जा सकता है।',
    jsMessagegaiheyueyouweituobukexiugaiganggan: 'इस अनुबंध में एक अधिदेश है और उत्तोलन को संशोधित नहीं किया जा सकता है।',
    jsMessageqingshuruzhengquedeshuliang: 'कृपया सही मात्रा दर्ज करें!',
    jsMessageqingshuruzhengquedejiage: 'कृपया सही कीमत दर्ज करें!',
    jsMessageqingxianxuanzechicang: 'कृपया पहले एक पद चुनें!',
    jsMessageqingshuruhuazhuanshuliang: 'कृपया स्थानांतरण राशि दर्ज करें!',
    jsMessageqingxianxuanzeheyue: 'कृपया पहले एक अनुबंध चुनें!',
    jsMessageqingshurutiaojiandanshuliang: 'कृपया सशर्त आदेश मात्रा दर्ज करें!',
    jsMessageqingshurutiaojiandandejiage: 'कृपया सशर्त आदेश का मूल्य दर्ज करें!',
    jsMessageqingshurutiaojiandanzhixingshijian: 'कृपया सशर्त आदेश निष्पादन समय दर्ज करें!',
    emptytext: 'अभी तक कोई डेटा नहीं',
    jsMessageReszhiyingzhisundianshushezhicuowu: 'टेक प्रॉफिट और स्टॉप लॉस पिप्स गलत तरीके से सेट किए गए हैं',
    jsMessageReschicangdanbucunzai: 'पद रसीद मौजूद नहीं है',
    zxkcje:'न्यूनतम उद्घाटन राशि',
    },
    commisson:{
    dlzx:'एजेंसी केंद्र',
    sjzl:'डेटा सिंहावलोकन',
    quanbu:"सभी",
    jinri:'आज',
    zuori:'कल',
    ri:'दिन',
    wdfy:'मेरी छूट',
    wdsjfy:'मेरी तीसरे स्तर की छूट',
    dengji:'श्रेणी',
    yqrs:'आमंत्रित व्यक्तियों की संख्या',
    tdrs:'टीम का आकार',
    yqxq:'निमंत्रण विवरण',
    yhm:"उपयोक्तानाम",
    cjsj:'रचना समय',
    fyxq:'छूट विवरण',
    zyj:'कुल कमीशन',
    dryj:'एक दिन का कमीशन',
    fyje:'छूट राशि',
    
    },
    assets: {
    zongzichan: 'कुल संपत्ति',
    chongbi: 'सिक्के जमा करें',
    tibi: 'सिक्के निकालो',
    huazhuan: 'स्थानांतरण',
    fabizhanghu: 'मूल खाता',
    heyuezhanghu: 'अनुबंध खाता',
    jiayiliushui: 'लेनदेन प्रवाह',
    dialogzichanhuazhuan: 'संपत्ति हस्तांतरण',
    cong: 'से',
    dao: 'आना',
    shuliang: 'मात्रा',
    qingshuruhuazhuanshuliang: 'कृपया स्थानांतरण राशि दर्ज करें',
    quanbu: 'सभी',
    keyong: 'उपलब्ध',
    huanzhuan: 'स्थानांतरण',
    zijinhuazhuanchenggong: 'फंड ट्रांसफर सफल',
    zijinhuazhuanshibai: 'निधि स्थानांतरण विफल रहा',
    bibizhanghu: 'सिक्का खाता',
    qiquanzhanghu:'विकल्प खाता',
    zczl:'संपत्ति सिंहावलोकन',
    zzczh:'कुल संपत्ति में छूट',
    ztzc:"पारगमन में संपत्ति",
    djzc:'संपत्तियां फ्रीज करें',
    zyzc:'गिरवी रखी संपत्ति',
    cwzc:'स्थिति संपत्ति',
    jyzh:'ट्रेडिंग खाते',
    bizhong:'मुद्रा',
    dongjie:'जमाना',
    lirun:"लाभ",
    hyzh:"अनुबंध खाता",
    },
    basicsAccountRecord: {
    fanhui: 'वापस करना',
    fabizhanghuliushui: 'ट्रेडिंग खाता प्रवाह',
    fabijiaoyiliushuileixing: 'लेन-देन प्रवाह प्रकार',
    qingxuanze: 'कृपया चयन कीजिए',
    chaxunriqi: 'क्वेरी दिनांक',
    liushuileixing: 'पाइपलाइन प्रकार',
    shijian: 'समय',
    biandongjine: 'राशि बदलें',
    yue: 'संतुलन',
    quanbu: 'सभी',
    Sellbi: 'सिक्के बेचो',
    Buybi: 'सिक्के खरीदें',
    tibi: 'सिक्के निकालो',
    chongbi: 'सिक्के जमा करें',
    fabizhuanheyue: 'मूल स्थानांतरण अनुबंध',
    heyuezhuanfabi: 'आधार पर अनुबंध',
    zhuanzhang: 'स्थानांतरण',
    fabihuazhuandaoheyue: 'अनुबंध में मूल स्थानांतरण',
    heyuehuazhuandaofabi: 'आधार पर अनुबंध स्थानांतरण',
    goumaijijin: 'एक खनन पूल खरीदें',
    jijinshuhui: 'खनन पूल मोचन',
    jijinshouyi: 'खनन पूल मोचन आय',
    fabizhuanqihuo: 'वायदा में बुनियादी रूपांतरण',
    qihuozhuanfabi: 'वायदा हस्तांतरण आधार',
    fabizhuanganggu: 'हांगकांग स्टॉक में बुनियादी स्थानांतरण',
    gangguzhuanfabi: 'हांगकांग स्टॉक ट्रांसफर की मूल बातें',
    fabizhuanbibi: 'मूल स्थानांतरण मुद्रा',
    bibizhuanfabi: 'मुद्रा से मुद्रा हस्तांतरण की मूल बातें',
    goumaiSGCB: 'आईसीओ खरीदें',
    chushouSGCB: 'ICO बेचना',
    huoqushibai: 'प्राप्त करने में विफल',
    zanwushuju: 'अभी तक कोई डेटा नहीं',
    },
    contractAccountRecord: {
    fanhui: 'वापस करना',
    heyuezhanghuliushui: 'अनुबंध खाता प्रवाह',
    heyuejiaoyiliushuileixing: 'अनुबंध लेनदेन प्रवाह प्रकार',
    qingxuanze: 'कृपया चयन कीजिए',
    chaxunriqi: 'क्वेरी दिनांक',
    liushuileixing: 'पाइपलाइन प्रकार',
    shijian: 'समय',
    biandongjine: 'राशि बदलें',
    yue: 'संतुलन',
    quanbu: 'सभी',
    kaicangshouxufei: 'उद्घाटन शुल्क',
    pingcangshouxufei: 'समापन शुल्क',
    pingcangyingkui: 'स्थिति समापन लाभ और हानि',
    fabizhuanheyue: 'मूल स्थानांतरण अनुबंध',
    heyuezhuanfabi: 'आधार पर अनुबंध',
    zhuanzhang: 'स्थानांतरण',
    kaicangyongjin: 'उद्घाटन आयोग',
    pingcangyongjin: 'समापन आयोग',
    huoqushibai: 'प्राप्त करने में विफल',
    zanwushuju: 'अभी तक कोई डेटा नहीं',
    },
    recharge: {
    zanwushuju: 'अभी तक कोई डेटा नहीं',
    shijian: 'समय',
    leixing: 'प्रकार',
    shuliang: 'मात्रा',
    zhuangtai: 'राज्य',
    caozuo: 'प्रचालन',
    chakanxiangqing: 'विवरण जांचें',
    huoqushibai: 'प्राप्त करने में विफल',
    dialogtitle: 'विवरण',
    chongbi: 'सिक्के जमा करें',
    fanhui: 'वापस करना',
    lianmingcheng: 'चेन का नाम',
    fuzhilianjie: 'पता कॉपी करें',
    tishixinxi1: 'कृपया उपरोक्त पते पर कोई भी गैर-TRC_USDT या ERC_USDT संपत्ति जमा न करें, अन्यथा संपत्ति वापस नहीं ली जाएगी',
    tishixinxi2: '• उपरोक्त पते पर रिचार्ज करने के बाद, पूरे नेटवर्क नोड की पुष्टि 1 नेटवर्क पुष्टि के बाद प्राप्त होगी, और 2 नेटवर्क पुष्टि के बाद मुद्रा निकाली जा सकती है।',
    tishixinxi3: '• न्यूनतम जमा राशि: 50USDT, न्यूनतम राशि से कम जमा जमा नहीं की जाएगी और वापस नहीं की जाएगी',
    tishixinxi4: '• आपका रिचार्ज पता बार-बार नहीं बदलेगा और बार-बार रिचार्ज किया जा सकता है, यदि कोई बदलाव होता है, तो हम आपको वेबसाइट घोषणाओं या ईमेल के माध्यम से सूचित करने की पूरी कोशिश करेंगे',
    tishixinxi5: '• कृपया सुनिश्चित करें कि जानकारी के साथ छेड़छाड़ या लीक होने से रोकने के लिए आपका कंप्यूटर और ब्राउज़र सुरक्षित हैं।',
    putongchongbi: 'साधारण जमा',
    jiaoyichenggong: 'लेनदेन सफल',
    jiaoyishibai: 'लेन - देन विफल',
    OMNIweihuzhong: 'OMNI श्रृंखला रखरखाव के अधीन है, कृपया TRC20 चुनें',
    },
    withdraw: {
    fanhui: 'वापस करना',
    zanwushuju: 'अभी तक कोई डेटा नहीं',
    tibi: 'सिक्के निकालो',
    huoqushibai: 'प्राप्त करने में विफल',
    OMNIweihuzhong: 'OMNI श्रृंखला रखरखाव के अधीन है, कृपया TRC20 चुनें',
    shijian: 'समय',
    leixing: 'प्रकार',
    shuliang: 'मात्रा',
    zhuangtai: 'राज्य',
    caozuo: 'प्रचालन',
    chakanxiangqing: 'विवरण जांचें',
    putongtibi: 'साधारण निकासी',
    dialogtitle: 'विवरण',
    dialogtitlewithdraw: 'सिक्के निकालो',
    houchongxinfasong: 'एस के बाद दोबारा भेजें',
    huoquyouxiangyanzhengma: 'ईमेल सत्यापन कोड प्राप्त करें',
    lianmingcheng: 'चेन का नाम',
    tibidizhi: 'निकासी का पता',
    qingshurutibidizhi: 'कृपया निकासी पता दर्ज करें',
    tibishuliang: 'निकाले गए सिक्कों की संख्या',
    shouxufei: 'संचालन शुल्क',
    qingshurutibishuliang: 'कृपया निकासी राशि दर्ज करें',
    quanbu: 'सभी',
    keyong: 'उपलब्ध',
    daozhangshuliang: 'आगमन मात्रा',
    zuixiaotibishuliang: 'न्यूनतम निकासी राशि है:',
    tishixinxi: 'धनराशि की सुरक्षा सुनिश्चित करने के लिए, जब आपकी खाता सुरक्षा नीति बदलती है या आपका पासवर्ड संशोधित होता है, तो हम निकासी की मैन्युअल समीक्षा करेंगे। कृपया धैर्य रखें और कर्मचारियों द्वारा फोन या ईमेल द्वारा आपसे संपर्क करने की प्रतीक्षा करें। कृपया सुनिश्चित करें कि डेटा के साथ छेड़छाड़ या लीक होने से रोकने के लिए आपका कंप्यूटर और ब्राउज़र सुरक्षित हैं।',
    tuxingyanzhengma: 'ग्राफ़िक सत्यापन कोड',
    qingshurutuxingyanzhengma: 'कृपया ग्राफ़िक सत्यापन कोड दर्ज करें',
    youxiangyanzhengma: 'ईमेल सत्यापन कोड',
    queding: 'ज़रूर',
    tijiaozhong: 'भेजने से',
    yijujue: 'अस्वीकार कर दिया',
    yitongguo: 'उत्तीर्ण',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'ग्राफ़िक सत्यापन कोड प्राप्त करने में विफल, कृपया पुनः अनुरोध करें',
    huoquyouxiangyanzhengmashibai: 'ईमेल सत्यापन कोड प्राप्त करने में विफल,',
    qingxianwanchengyouxiangrenzheng: 'कृपया पहले ईमेल सत्यापन पूरा करें',
    huoquyouxiangshibaiqingchongxinqingqiu: 'ईमेल पता प्राप्त करने में विफल, कृपया पुनः अनुरोध करें',
    qingshuruyouxiangyanzhengma: 'कृपया ईमेल सत्यापन कोड दर्ज करें',
    youxiangyanzhengmacuowu: 'ईमेल सत्यापन कोड ग़लत है,',
    qxsmrz:'कृपया पहले अपना वास्तविक नाम सत्यापित करें',
    tishi:'संकेत देना',
    queding:'ज़रूर',
    },
    contractTransactionRecord: {
    fanhui: 'वापस करना',
    zanwushuju: 'अभी तक कोई डेटा नहीं',
    heyuedingdan: 'अनुबंध आदेश',
    qingxuanze: 'कृपया चयन कीजिए',
    quanbu: 'सभी',
    kaiduo: 'लंबा खुला',
    pingkong: 'सपाट आकाश',
    kaikong: 'लघु खोलें',
    pingduo: 'पिंडुओ',
    xianjiaweituo: 'सीमा आदेश',
    shijiaweituo: 'बाज़ार व्यवस्था',
    suoyouzhuangtai: 'सभी स्थिति',
    tijiaoshibai: 'सबमिशन विफल',
    zhunbeitijiao: 'प्रस्तुत करने के लिए तैयार',
    yitijiao: 'प्रस्तुत किया गया',
    bufentijiao: 'आंशिक सौदा',
    bufentijiaoyichedan: 'कुछ ऑर्डर रद्द कर दिए गए हैं',
    quanbuchengjiao: 'सभी लेन - देन',
    yichedan: 'आदेश रद्द कर दिया गया',
    huoqushibai: 'प्राप्त करने में विफल',
    heyuedaima: 'अनुबंध कोड',
    ganggan: 'उत्तोलक',
    xiadanshijian: 'ऑर्डर का समय',
    chengjiaoshijian: '成交时间',
    fangxiang: 'दिशा',
    baojialeixing: 'उद्धरण प्रकार',
    weituoliang: 'कमीशन की राशि (टुकड़े)',
    weituojia: '委托价(USDT)',
    chengjiaoliang: 'ट्रेडिंग वॉल्यूम (टिकट)',
    chengjiaojunjia: 'औसत लेनदेन मूल्य (यूएसडीटी)',
    yingkui: 'लाभ और हानि (यूएसडीटी)',
    shouxufei: 'हैंडलिंग शुल्क (यूएसडीटी)',
    zhuangtai: 'राज्य',
    gupiaodingdan: 'स्टॉक ऑर्डर',
    waihuidingdan: 'विदेशी मुद्रा आदेश',
    gupiaodaima: 'स्टॉक कोड',
    waihuidaima: 'विदेशी मुद्रा कोड',
    },
    kLine: {
    zhibiao: 'अनुक्रमणिका',
    shezhi: 'स्थापित करना',
    quanping: 'पूर्ण स्क्रीन',
    tuichuquanping: 'पूर्ण स्क्रीन से बाहर निकलें',
    jishuzhibiao: 'तकनीकी संकेतक',
    futuzhibiao: 'उपचित्र सूचक',
    zhutuzhibiao: 'मुख्य चार्ट संकेतक',
    huifumoren: 'डिफ़ॉल्ट बहाल',
    lazhutuleixing: 'कैंडलस्टिक के प्रकार',
    qingxuanze: 'कृपया चयन कीजिए',
    quanshixin: 'सब ठोस',
    quankongxin: 'पूरी तरह से खोखला',
    zhangkongxin: 'खोखला हो रहा है',
    diekongxin: 'खोखला हो जाना',
    mianjitu: 'क्षेत्र चार्ट',
    jiagezhouleixing: 'मूल्य अक्ष प्रकार',
    xianxingzhou: 'रेखा अक्ष',
    baifenbizhou: 'प्रतिशत अक्ष',
    duishuzhou: 'लघुगणकीय अक्ष',
    zuigaojiaxianshi: 'उच्चतम मूल्य प्रदर्शन',
    zuidijiaxianshi: 'सबसे कम कीमत का प्रदर्शन',
    zuixinjiaxianshi: 'नवीनतम मूल्य प्रदर्शन',
    zhibiaozuixinzhixianshi: 'सूचक का नवीनतम मान प्रदर्शित होता है',
    daozhizuobiao: "उल्टे निर्देशांक",
    wanggexianxianshi: 'ग्रिड लाइन डिस्प्ले',
    hfcg:'पुनर्प्राप्ति सफल',
    },
    SecurityCenter: {
    fanhui: 'वापस करना',
    anquanzhongxin: 'सुरक्षा केंद्र',
    qingshurunicheng: 'कृपया एक निकनाम दर्ज करें',
    nichengxiugaishibai: 'उपनाम संशोधन विफल रहा',
    huoquyonghuxinxishibai: 'उपयोगकर्ता जानकारी प्राप्त करने में विफल',
    xiugaidenglumima: 'लॉगिन पासवर्ड बदलें',
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'ग्राफ़िक सत्यापन कोड प्राप्त करने में विफल, कृपया पुनः अनुरोध करें',
    qingshurujiumima: 'कृपया पुराना पासवर्ड दर्ज करें',
    qingshuruxinmima: 'कृपया नया पासवर्ड दर्ज करें',
    qingzaicishuruxinmima: 'कृपया दोबारा नया पासवर्ड दर्ज करें',
    qingshurutuxingyanzhengma: 'कृपया ग्राफ़िक सत्यापन कोड दर्ज करें',
    qingshuruyanzhengma: 'कृपया सत्यापन कोड दर्ज करें',
    huoquzhuceshoujihaoshibai: 'पंजीकृत मोबाइल नंबर प्राप्त करने में विफल',
    qingxianwanchengshoujirenzheng: 'कृपया पहले मोबाइल फ़ोन प्रमाणीकरण पूरा करें',
    huoqushoujiyanzhengmashibai: 'मोबाइल फ़ोन सत्यापन कोड प्राप्त करने में विफल',
    huoqushoujiyanzhengma: 'मोबाइल फ़ोन सत्यापन कोड प्राप्त करें',
    houchongxinfasong: 'एस के बाद दोबारा भेजें',
    mimacuowu: 'ग़लत पासवर्ड',
    liangcixinmimashurubuyizhiqingjiancha: 'दर्ज किए गए दो नए पासवर्ड असंगत हैं, कृपया जांचें',
    xinmimaheyuanmimabunengyiyang: 'नया पासवर्ड मूल पासवर्ड के समान नहीं हो सकता',
    qingshurushoujiyanzhengma: 'कृपया मोबाइल फ़ोन सत्यापन कोड दर्ज करें',
    yanzhengmacuowu: 'सत्यापन कोड त्रुटि',
    denglumimaxiugaichenggong: 'लॉगिन पासवर्ड सफलतापूर्वक बदला गया',
    denglumimaxiugaishibai: 'लॉगिन पासवर्ड संशोधन विफल रहा',
    xiugaizijinmima: 'फंड पासवर्ड बदलें',
    qingshuruzijinmima: 'कृपया फंड पासवर्ड दर्ज करें',
    qingzaicishuruzijinmima: 'कृपया फंड पासवर्ड दोबारा दर्ज करें',
    liangcizijinmimabuyizhiqingjiancha: 'दोनों फंड पासवर्ड असंगत हैं, कृपया जांचें',
    shezhizijinmimachenggong: 'फंड पासवर्ड सफलतापूर्वक सेट करें',
    shezhizijinmimashibai: 'फंड पासवर्ड सेट करने में विफल',
    xiugaizijinmimachenggong: 'फंड पासवर्ड सफलतापूर्वक संशोधित',
    xiugaizijinmimashibai: 'फंड पासवर्ड बदलने में विफल',
    youxiangrenzheng: 'ईमेल प्रमाणीकरण',
    huoquyouxiangyanzhengma: 'ईमेल सत्यापन कोड प्राप्त करें',
    qingshuruyouxiangdizhi: 'कृपया ईमेल पता दर्ज करें',
    huoquyouxiangyanzhengmashibai: 'ईमेल सत्यापन कोड प्राप्त करने में विफल',
    qingshuruyouxiangyanzhengma: 'कृपया ईमेल सत्यापन कोड दर्ज करें',
    youxiangyanzhengchenggong: 'ईमेल सत्यापन सफल',
    weirenzheng: 'प्रमाणित नहीं',
    queding: 'ज़रूर',
    anquanshezhiguanli: 'सुरक्षा सेटिंग्स प्रबंधन',
    denglumima: 'लॉगिन पासवर्ड',
    tongguoshezhidenglumimaninkeyishiyongzhanghaomimazhijiedenglu: 'लॉगिन पासवर्ड सेट करके आप सीधे अपने अकाउंट पासवर्ड का उपयोग करके लॉग इन कर पाएंगे',
    xiugai: 'दोहराना',
    zijinmima: 'फंड पासवर्ड',
    yongyubaohuzijinanquan: 'धन की सुरक्षा की रक्षा के लिए उपयोग किया जाता है',
    shezhi: 'स्थापित करना',
    yongyudenglutibizhaohuimimaxiugaianquanshezhishijinxinganquanyanzheng: 'लॉग इन करते समय, सिक्के निकालते समय, पासवर्ड पुनर्प्राप्त करते समय और सुरक्षा सेटिंग्स को संशोधित करते समय सुरक्षा सत्यापन के लिए उपयोग किया जाता है।',
    yirenzheng: 'प्रमाणित',
    renzheng: 'प्रमाणन',
    shimingrenzheng: 'वास्तविक नाम प्रमाणीकरण',
    yongyumaibimaibitibichongbihuazhuanshijinxinganquanrenzheng: 'सिक्के खरीदते, बेचते, निकालते, जमा करते और स्थानांतरित करते समय सुरक्षा प्रमाणीकरण के लिए उपयोग किया जाता है।',
    daishenhe: 'समीक्षा लंबित है',
    yibohui: 'ख़ारिज',
    yuandenglumima: 'मूल लॉगिन पासवर्ड',
    xinmima: 'नया पासवर्ड',
    quedingxinmima: 'नए पासवर्ड की पुष्टि करें',
    tuxingyanzhengma: 'ग्राफ़िक सत्यापन कोड',
    yanzhengma: 'सत्यापन कोड',
    zijinmima: 'फंड पासवर्ड',
    querenzijinmima: 'फंड पासवर्ड की पुष्टि करें',
    youxiangdizhi: 'मेल पता',
    qingxianwanchengyouxiangrenzheng: 'कृपया पहले ईमेल सत्यापन पूरा करें',
    xiugaitouxiangchenggong: 'अवतार सफलतापूर्वक संशोधित',
    xiugaitouxiangshibai: 'अवतार को संशोधित करने में विफल',
    shangchuantouxiangzhinengshijpggeshi: 'अवतार चित्र केवल JPG प्रारूप में ही अपलोड किए जा सकते हैं!',
    shangchuantouxiangtupiandaxiaobunengchaoguo2M: 'अपलोड की गई अवतार छवि का आकार 2 एमबी से अधिक नहीं हो सकता!',
    },
    verification: {
    fanhui: 'वापस करना',
    shimingrenzheng: 'वास्तविक नाम प्रमाणीकरण',
    qingshuruxingming: 'कृपया नाम दर्ज करें',
    qingshuruzhengjianhaoma: 'कृपया अपना आईडी नंबर दर्ज करें',
    huoquyonghuxinxishibai: 'उपयोगकर्ता जानकारी प्राप्त करने में विफल',
    wenjiangeshicuowu: 'फ़ाइल स्वरूप त्रुटि',
    wenjianbunengdayu5M: 'फ़ाइल 5M से बड़ी नहीं हो सकती',
    qingtianxiexingming: 'कृपया आपका नाम दर्ज करें',
    qingtianxiezhengjianhaoma: 'कृपया आईडी नंबर भरें',
    qingshangchuanzhengjianzhengmian: 'कृपया अपनी आईडी का अगला भाग अपलोड करें',
    qingshangchuanzhengjianfanmian: 'कृपया अपनी आईडी का पिछला भाग अपलोड करें',
    tijiaochenggong: 'सबमिशन सफल',
    kaiqishenfenrenzheng: 'पहचान प्रमाणीकरण चालू करें',
    bohuiyuanyin: 'खारिज करने का कारण',
    qingtianxiejiashizhizhaogongminkahuzhao: 'कृपया अपना ड्राइविंग लाइसेंस/नागरिक कार्ड/पासपोर्ट जानकारी भरें',
    xingming: 'नाम',
    zhengjianhaoma: 'आईडी नंबर',
    qingshangchuanjiashizhizhaogongminkahuzhao: 'कृपया ड्राइवर का लाइसेंस/नागरिक कार्ड/पासपोर्ट जानकारी अपलोड करें',
    shangchuanzhengjianzhengmian: 'आईडी का अगला भाग अपलोड करें',
    huotuozhuafangru: 'या खींचें और छोड़ें',
    shangchuanzhengjianfanmian: 'प्रमाणपत्र का पिछला भाग अपलोड करें',
    tijiao: 'जमा करना',
    },
    invite: {
    yaoqinghaoyoudejiangli: 'मित्रों को आमंत्रित करें और पुरस्कार प्राप्त करें',
    yongjiukede: 'स्थायी रूप से उपलब्ध',
    jiaoyifanyong: 'व्यापार में छूट',
    fasongyaoqinggeihaoyou: 'मित्रों को निमंत्रण भेजें',
    tongguofenxianglianjieyaoqinghaoyouzhuce: 'लिंक साझा करके दोस्तों को बीसीएफएक्स पंजीकृत करने के लिए आमंत्रित करें',
    haoyouzhuce: 'मित्र पंजीकरण',
    haoyoujieshouyaoingwanchengzhucebingjinxingjiaoyi: 'मित्र निमंत्रण स्वीकार करता है, पंजीकरण पूरा करता है और लेनदेन करता है',
    huodexiangyingbilijiangli: 'संगत आनुपातिक पुरस्कार प्राप्त करें',
    qingsonghuodejiaoyishouxufeijianglifuli: 'लेनदेन शुल्क बोनस लाभ आसानी से प्राप्त करें',
    wodezhuanshufenxiangfangshi: 'साझा करने का मेरा विशेष तरीका',
    yaoqinglianjie: 'आमंत्रण लिंक',
    jiazaizhong: 'लोड हो रहा है...',
    fuzhilianjie: 'लिंक की प्रतिलिपि करें',
    yaoqingma: 'आमंत्रण कोड',
    fuzhiyaoqingma: 'आमंत्रण कोड कॉपी करें',
    wodeyaoqing: 'मेरा निमंत्रण',
    yaoqingrenshu: 'आमंत्रित व्यक्तियों की संख्या',
    huodejiangli: 'पुरस्कृत हो जाओ',
    huodongxize: 'घटना विवरण',
    tips1: 'प्लेटफ़ॉर्म उपयोगकर्ताओं के बीच संबंध तीन स्तरों का है, जैसे: एबीसीडी उपयोगकर्ता उपयोगकर्ताओं के तीन स्तर बनाते हैं, और ए उच्चतम स्तर है!',
    tips2: 'स्तरों के बीच रुचि संबंध',
    tips201: '。',
    tips3: 'नकद इनाम: ग्राहक ए को ग्राहक बी के अनुबंध लेनदेन शुल्क का 40% + ग्राहक सी के अनुबंध लेनदेन शुल्क का 20% + ग्राहक डी के अनुबंध लेनदेन शुल्क का 10% प्राप्त हो सकता है।',
    tips4: 'उदाहरण के लिए: ग्राहक A के पास 10 प्रथम स्तर के ग्राहक B, 10 दूसरे स्तर के ग्राहक C और 10 तीसरे स्तर के ग्राहक D हैं। यदि प्रत्येक ग्राहक अनुबंध 100,000 USDT का व्यापार करता है',
    tips5: 'फिर ग्राहक A को जो रिटर्न मिल सकता है',
    tips6: '100000*1.2%*40%*10+100000*1.2%*20%*10+100000*1.2%*10%*10=8400 यूएसडीटी नकद इनाम, जिसे वास्तविक समय में निकाला जा सकता है',
    tips7: 'यदि गतिविधि समायोजित की जाती है, तो यह बीसीएफएक्स प्लेटफ़ॉर्म अपडेट के अधीन होगी, और व्याख्या का अंतिम अधिकार बीसीएफएक्स का है।',
    },
    register: {
    huoqutuxingyanzhengmashibaiqingchongxinqingqiu: 'ग्राफ़िक सत्यापन कोड प्राप्त करने में विफल, कृपया पुनः अनुरोध करें',
    qingshuruyouxiang: 'कृपया अपना ईमेल दर्ज करें',
    qingshurutuxingyanzhengma: 'कृपया ग्राफ़िक सत्यापन कोड दर्ज करें',
    qingshuruyanzhengma: 'कृपया सत्यापन कोड दर्ज करें',
    qingshurunindemima: 'अपना पासवर्ड दर्ज करें',
    qingzaicishurunindemima: 'कृपया अपना पासवर्ड दोबारा दर्ज करें',
    qingshuruzhifumima: 'कृपया भुगतान पासवर्ड दर्ज करें',
    xuantian: 'वैकल्पिक',
    bitian: 'आवश्यक',
    yonghuzhuce: 'उपयोगकर्ता पंजीकरण',
    huanyingdenglu: 'लॉग इन करने के लिए आपका स्वागत है',
    guanfangwangzhan: 'आधिकारिक वेबसाइट',
    xiangmumingcheng: 'बीसीएफएक्स',
    youxiang: 'मेल',
    tuxingyanzhengma: 'ग्राफ़िक सत्यापन कोड',
    yanzhengma: 'सत्यापन कोड',
    huoquyanzhengma: 'सत्यापन कोड',
    shezhimima: 'सांकेतिक शब्द लगना',
    quedingmima: 'पासवर्ड की पुष्टि कीजिये',
    zhifumima: 'भुगतान पासवर्ड',
    yaoqingma: 'आमंत्रण कोड',
    zhuce: 'पंजीकरण करवाना',
    yiyouzhanghao: 'क्या आपके पास पहले से ही एक खाता है?',
    denglu: 'लॉग इन करें',
    qingshuruyouxiangdizhi: 'कृपया ईमेल पता दर्ज करें',
    huoqushibai: 'प्राप्त करने में विफल',
    houchongxinfasong: 'एस के बाद दोबारा भेजें',
    liangcishurudemimabuyizhi: 'दो बार दर्ज किए गए पासवर्ड असंगत हैं',
    zhucechenggong: 'सफल पंजीकरण',
    zhuceshibai: 'पंजीकरण विफल रहा',
    },
    treasure:{
    jichubizhong: 'आधार मुद्रा',
    shouyibizhong: 'राजस्व मुद्रा',
    wakuangtianshu: 'खनन के दिन',
    shouyitianshu: 'कमाई के दिन',
    suodingtianshu: 'लॉक दिन',
    yichanshengshouyi: 'राजस्व उत्पन्न हुआ',
    yuqishouyi: 'अपेक्षित वापसी',
    kaishishijian: 'समय शुरू',
    jieshushijian: 'अंत समय',
    qingshuruninyaosuodingdebizhongshuliang: 'कृपया उस मुद्रा में यूएसडीटी की राशि दर्ज करें जिसे आप लॉक करना चाहते हैं',
    huoqushibai: 'प्राप्त करने में विफल',
    meiyitian: 'हर 1 दिन',
    dakai: 'खुला',
    yuanyuzhoukuangchi: 'मेटावर्स माइनिंग पूल',
    jilu: 'अभिलेख',
    kuangchixiangqing: 'खनन पूल विवरण',
    suo: 'ताला',
    yitianchanchu: '1 दिन का आउटपुट',
    zuixiaosuodingshijian: 'न्यूनतम लॉक समय',
    nindeUSDT: 'आपका यूएसडीटी',
    tian: 'आकाश',
    quanbu: 'सभी',
    suoUSDTbingkaishishengchan: 'यूएसडीटी को लॉक करें और उत्पादन शुरू करें',
    ninquedingyaosuoUSDTkaishishengchanma: 'क्या आप वाकई यूएसडीटी को लॉक करना और उत्पादन शुरू करना चाहते हैं?',
    tishi: 'संकेत देना',
    queding: 'ज़रूर',
    quxiao: 'रद्द करना',
    kaishishengchan: 'उत्पादन प्रारंभ करें!',
    yiquxiao: 'रद्द किया गया',
    },
    subscription:{
    rengouliebiao:'सदस्यता सूची',
    jijiangdaolai:'जल्द आ रहा है',
    jinxingzhong:'प्रगति पर है',
    quanbu:'सभी',
    keshiyonghuobi:"उपलब्ध मुद्राएँ",
    zongshuliang:'कुल मात्रा',
    meiyouxianzhi:'कोई सीमा नहीं',
    shengyushuliang:'शेष मात्रा',
    kaishishijian:'समय शुरू',
    zhuangtai:'राज्य',
    jilu:'अभिलेख',
    zanwujilu:"अभी तक कोई रिकॉर्ड नहीं",
    leixing:'प्रकार',
    shuliang:'मात्रा',
    goumaijine:'खरीद राशि',
    shijian:'समय',
    goumai:'खरीदना',
    duichu:'नजाने कहां से',
    jiangli:'पुरस्कार',
    tibi:'सिक्के निकालो',
    huidaomulu:'विषय - वस्तु की तालिका पर लौटें',
    dangqianchiyou:'वर्तमान में आयोजित',
    keyong:'उपलब्ध',
    keduihuan:'प्रतिदेय',
    shishihangqing:'वास्तविक समय के उद्धरण',
    yue:'संतुलन',
    biaoti:'शीर्षक',
    fashoushuliang:'बिक्री हेतु मात्रा',
    jindu:"अनुसूची",
    goumaishuliang:'खरीद की मात्रा',
    ge:'व्यक्तिगत',
    woyiyuedu:'मैंने पढ़ा है',
    daibigoumaixieyi:'सांकेतिक खरीद समझौता',
    qingshurugoumaijine:'कृपया खरीद राशि दर्ज करें',
    shifoyaogoumai:'क्या आप खरीदना चाहते हैं',
    tishi:'संकेत देना',
    queding:'ज़रूर',
    quxiao:'रद्द करना',
    yjs:'समाप्त',
    ywc:'पुरा होना।',
    sgjg:'सदस्यता कीमत',
    jssj:"अंत समय",
    zqsl:"जीतने की मात्रा",
    zqje:"जीत की रकम",
    tian:"आकाश",
    shi:'घंटा',
    fen:'बिंदु',
    miao:'दूसरा',
    shengou:'सदस्यता',
    xmxq:'परियोजना विवरण',
    },
    }